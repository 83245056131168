import { useCallback } from 'react';
import { useAdminData } from '@utils/Data/Admin';
import { log } from 'cf-common/src/logger';

export const useShareasale = () => {
  const { adminData, adminDataLoading } = useAdminData();

  const adminID = adminData?.me.id;

  /**
   * Чтобы все равильно работало, дождитесь loading
   */
  const sendShareasaleEvent = useCallback(() => {
    if (!adminID) {
      log.error({
        msg: 'Shareasale adminID is empty',
      });
    }
    const image = document.createElement('img');
    image.src = `https://www.shareasale.com/sale.cfm?tracking=${adminID}&amount=0.00&merchantID=155248&transtype=lead`;
  }, [adminID]);

  return { shareasaleLoading: adminDataLoading, sendShareasaleEvent };
};
