import { Period } from '@globals';
import { useBotPromoCodeQuery } from '@utils/Data/PromoCode/useBotPromoCodeQuery';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import noop from 'lodash-es/noop';
import { useCallback } from 'react';

const getOrderQuantity = (period: Period) => {
  if (period === Period.monthly) return 1;
  return Period.semiannual ? 6 : 12;
};

export function useAdmitAd(botId: string) {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { data: botPromoCodeData } = useBotPromoCodeQuery(botId);
  const [admitAdSended, setAdmitAdSended] = useServerStorage<boolean>(
    ServerStorageItemKeys.admitAdSended,
  );

  const promocode =
    botPromoCodeData?.bot.assignedPromoCode?.promoCode.promoCode;

  return useCallback(
    ({
      price,
      priceCurrency,
      period,
    }: {
      price: number;
      priceCurrency: string;
      period: Period;
    }) => {
      if (admitAdSended) return;
      try {
        const ADMITAD = (window as any).ADMITAD || {};
        ADMITAD.Invoice = ADMITAD.Invoice || {};
        ADMITAD.Invoice.category = '1';
        const orderedItem = [];

        orderedItem.push({
          Product: {
            productID: isWhatsappEnabled ? 'WA' : 'FB', // внутренний код товара (не более 100 символов, соответствует ID из товарного фида)
            category: '1', // код тарифа целевого действия — скопируйте из таблицы на странице «Настройка интеграции через Tracking code» (Программа → Интеграция → «Далее» → вкладка «Кастомная интеграция» → «Tracking код» → «Далее»)
            price, // цена товара (если на него есть скидка, то цена с учетом скидки)
            priceCurrency,
          },
          orderQuantity: getOrderQuantity(period), // количество товара
          additionalType: 'sale', // всегда sale
        });

        ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];

        ADMITAD.Invoice.referencesOrder.push({
          orderNumber: botId,
          discountCode: promocode || '',
          orderedItem,
        });

        ADMITAD.Tracking.processPositions();

        setAdmitAdSended(true);
      } catch {
        noop();
      }
    },
    [promocode, isWhatsappEnabled, botId, admitAdSended, setAdmitAdSended],
  );
}
