import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { ShopifyChooseBotPage } from './ShopifyChooseBotPage';
import { ReactComponent as LightningIcon } from './images/lightning.svg';
import * as css from './ShopifyChooseBotEmptyState.css';

export const ShopifyChooseBotError = () => {
  const { t } = useSafeTranslation();

  return (
    <ShopifyChooseBotPage disabled>
      <Flex alignItems="center" justifyContent="center" className={css.wrapper}>
        <Flex
          alignItems="center"
          flexDirection="column"
          className={css.container}
        >
          <LightningIcon />
          <Spacer factor={4} />
          <Type
            as="h1"
            weight="medium"
            color="baseTertiary"
            align="center"
            className={css.text}
          >
            {t('ShopifyOnboarding.chooseBot.error')}
          </Type>
        </Flex>
      </Flex>
    </ShopifyChooseBotPage>
  );
};
