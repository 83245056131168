import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { TierCardPrice } from './TierCardPrice';
import { TierType } from '@globals';
import { TierCardBadge } from './TierCardBadge';
import { TierCardAction } from './TierCardAction';
import { TierCardOptions } from './TierCardOptions';
import { TierCardLabel } from './TierCardLabel';
import { TierCardProps } from './types';
import * as css from './TierCard.css';

export const TierCard: React.FC<TierCardProps> = (props) => {
  const {
    selectedTier,
    nextTier,
    tier,
    botIsPaused,
    showYearPrices,
    checkAvailableFreeMonth,
    discount,
  } = props;
  const { t } = useSafeTranslation();

  const isCurrentTierSelected = tier.type === selectedTier?.type;
  const isCurrentTierNext = tier.type === nextTier?.type;
  const isNextTierExists = !!nextTier;
  const isTrialTier = tier.type === TierType.trial;

  const moreExpensiveThanCurrent =
    tier.tier_price >
    ((nextTier ? nextTier?.tier_price : selectedTier?.tier_price) ?? 0);
  // TODO: think about?
  const cardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isCurrentTierNext || isCurrentTierSelected) {
      cardRef.current?.scrollIntoView({ inline: 'start', block: 'nearest' });
    }
  });

  return (
    <Flex
      flexDirection="column"
      ref={cardRef}
      className={cn(css.root, {
        [css.paused]: isCurrentTierSelected && botIsPaused,
        [css.nextTier]:
          isCurrentTierSelected && !isCurrentTierNext && isNextTierExists,
        [css.currentPlan]: isCurrentTierNext || isCurrentTierSelected,
      })}
      data-testid="plans-page__tier-card"
    >
      <TierCardLabel
        {...props}
        isCurrentTierSelected={isCurrentTierSelected}
        isCurrentTierNext={isCurrentTierNext}
        isStartsAfterTrial={selectedTier?.type === TierType.trial}
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        className={css.container}
      >
        <Spacer factor={3} />
        <Type
          size="18px"
          weight="semibold"
          align="center"
          data-testid="plans-page__tier-card__tier-name"
        >
          {getTierNameByType(tier.type)}
        </Type>
        <Spacer factor={2} />
        <TierCardBadge
          type={tier.type}
          discount={discount}
          isCurrentTierSelected={isCurrentTierSelected}
          checkAvailableFreeMonth={checkAvailableFreeMonth}
        />
        <Spacer factor={3} />
        <TierCardPrice
          currency={props.currency}
          price={props.tier.tier_price}
        />
        <Type size="15px" align="center">
          {showYearPrices && !isTrialTier ? (
            t('pages.Pricing.businessCard.ifPaidAnnually')
          ) : (
            <Spacer factor={5} />
          )}
        </Type>
        <Type
          size="15px"
          align="center"
          data-testid="plans-page__tier-card__tier-dialogs-count"
        >
          {t(
            isTrialTier
              ? 'pages.Pricing.businessCard.firstNDialogs'
              : 'pages.Pricing.businessCard.monthlyPerNDialogs',
            { count: tier.tier_dialogs },
          )}
        </Type>
        <Spacer factor={5} />
        <TierCardAction
          {...props}
          moreExpensiveThanCurrent={moreExpensiveThanCurrent}
          isCurrentTierSelected={isCurrentTierSelected}
          isCurrentTierNext={isCurrentTierNext}
        />
        <Spacer factor={6} />
        <TierCardOptions {...props} isTrialTier={isTrialTier} />
        <Spacer factor={4} />
      </Flex>
    </Flex>
  );
};
