import React from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { IconNames } from '@ui/Icon/icons';
import * as css from './ActiveCardButton.css';

interface ActiveCardButtonProps {
  title: string;
  icon?: IconNames;
  className?: string;
}

export const ActiveCardButton: React.FC<ActiveCardButtonProps> = ({
  title,
  icon,
  className,
}) => (
  <ButtonUnstyled className={cn(css.activeTierButton, className)} disabled>
    {icon && (
      <Icon className={css.activeTierButtonIcon} icon="check" color="blue" />
    )}
    <Type size="15px" color="blue" weight="medium">
      {title}
    </Type>
  </ButtonUnstyled>
);
