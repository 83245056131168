import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import { PaymentDialog } from '@components/Payments';
import {
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { useShowChurnSurveyAdviceDialog } from '@components/CancelProDialog/ChurnSurveyAdvice/showChurnSurveyAdviceDialog';
import { PaymentFailureDialog } from '@components/DialogsPricing/dialogs';
import { sendEvent } from '@utils/Analytics';
import {
  Tier,
  Pricing,
  usePaymentInfo,
  useSwitchPricingTierMutation,
} from '@utils/Data/Pricing';
import { useUpdatePaymentDialog } from '@components/DialogsPricing/hooks';
import { BotStatus, BOT_STATUS_DATA_QUERY, getBotStatus } from '@utils/Bot';
import {
  BotStatusDataQuery,
  BotStatusDataQueryVariables,
} from '@utils/Bot/@types/BotStatusDataQuery';
import { Modal } from '@services/index';
import { useDeepLinkCleanup } from '../../DeepLinks/helpers';
import { DeepLinksMode, DeepLinksQueryParam } from '../../DeepLinks/types';
import { PLANS_PAGE_ADMIN_QUERY } from '../queries';
import { PlansPageAdminQuery } from '../@types/PlansPageAdminQuery';
import { Period, TierType } from '@globals';
import { isSomeEnum } from '@utils/isSomeEnum';
import {
  getPageToUpgrade,
  getPlansPageUrl,
  useIsFirstSessionTab,
} from '@utils/Routing';
import { getBotFeatures } from '@utils/Data/Bot';
import { submitPaymentEvent } from 'cf-common/src/conversionTracking';
import { getSwitchTierCallback } from './getSwitchTierCallback';
import { useAdmitAd } from './admitAd';
import { useShareasale } from './shareasale';

interface PaymentFlowConfig {
  botId: string;
  tiers: Tier[];
  pricing?: Pricing;
  currentTier?: Tier;
  adminName?: string;
}

export const usePaymentFlow = ({
  botId,
  pricing,
  currentTier,
  tiers,
}: PaymentFlowConfig) => {
  const history = useHistory();
  const { paymentInfo } = usePaymentInfo();
  const { show: showChurnSurvey, loading: cancelProLoading } =
    useShowChurnSurveyAdviceDialog();
  const showPaymentUpdateDialog = useUpdatePaymentDialog();
  const [nextTierToUpdate, setNextTierToUpdate] =
    useState<{ tier: TierType; subscriptionPeriod: Period }>();

  const { data } = useQuery<PlansPageAdminQuery>(PLANS_PAGE_ADMIN_QUERY);
  const sendAdminAdEvent = useAdmitAd(botId);
  const { sendShareasaleEvent } = useShareasale();

  const { switchPricingTierMutation, handleMutationError, switchingTier } =
    useSwitchPricingTierMutation({
      botId,
      onSwitchingCompleted(data) {
        const tier = data.switchPricingTier;
        const isFirstPayment =
          !tier.previous_tier &&
          (!currentTier || currentTier.type === TierType.trial);
        if (isFirstPayment) {
          sendAdminAdEvent({
            price: tier.invoice_total!,
            priceCurrency: tier.currency,
            period: tier.current_subscription_period || Period.monthly,
          });
          sendShareasaleEvent();
        }

        submitPaymentEvent(
          {
            currency: tier.currency,
            tierName: tier.next_tier || tier.current_tier!,
            value: tier.invoice_total!,
          },
          botId,
        );
      },
    });

  const { data: botStatusData, refetch: refetchBotStatusData } = useQuery<
    BotStatusDataQuery,
    BotStatusDataQueryVariables
  >(BOT_STATUS_DATA_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const bot = botStatusData?.bot;
  const name = data?.me.name?.split(' ')[0];

  const location = useLocation();
  const { deepLinkCleanup } = useDeepLinkCleanup();

  const isFirstSessionTab = useIsFirstSessionTab();

  const switchTier = useCallback(
    getSwitchTierCallback({
      botId,
      currentTier,
      switchPricingTierMutation,
      handleMutationError,
      showPaymentUpdateDialog,
      pricing,
      isFirstSessionTab,
      tiers,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tiers,
      currentTier,
      showPaymentUpdateDialog,
      switchPricingTierMutation,
      botId,
      pricing,
      name,
      history,
    ],
  );

  // Payment flow "entry points":
  // 1. Connect page entry point
  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.plans,
      [ConnectPageField.tierId]: currentTier?.type,
      [ConnectPageField.subscriptionPeiod]:
        pricing?.current_subscription_period ?? Period.monthly,
    },
    onClose: deepLinkCleanup,
    onPageConnected: async ({ close }) => {
      const { cpTierId, cpOrigin, cpSubscriptionPeriod } =
        getConnectPageUrlParams();
      close?.();
      // after redirect from Facebook
      if (cpOrigin === ConnectPageOrigin.plans && cpTierId) {
        sendEvent({
          category: 'pricing',
          action: 'open upgrade plan dialog after redirect from Facebook',
          label: 'new dialogs pricing',
          propertyBag: {
            botId,
            plan: currentTier?.type,
            nextPlan: cpTierId,
          },
        });
        if (cpSubscriptionPeriod) {
          try {
            /**
             * HACK: after connecting the page, we focus on the
             * pricing plan of the page. so dialogs_pricing_enabled can update
             */
            const botFeatures = await getBotFeatures(botId, 'network-only');

            if (botFeatures.dialogs_pricing_enabled) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              switchTierOrUpdatePaymentDependsOnBotStatus(
                cpTierId as TierType,
                cpSubscriptionPeriod,
              );
            } else {
              history.push(getPageToUpgrade(botId, false));
            }
          } catch (error) {
            log.warn({
              error,
              msg: 'Bot features request error after connecting fb page',
            });
          }
        }
      } else {
        refetchBotStatusData();
      }
    },
  });

  const switchTierOrUpdatePaymentDependsOnBotStatus = useCallback(
    (nextTier: TierType, subscriptionPeriod: Period) => {
      if (!bot) {
        return;
      }
      const status = getBotStatus(bot);

      switch (status) {
        case BotStatus.noPage:
          setNextTierToUpdate({ tier: nextTier, subscriptionPeriod });
          connectPage({
            botId: bot.id,
            urlParams: {
              [ConnectPageField.origin]: ConnectPageOrigin.plans,
              [ConnectPageField.tierId]: nextTier,
              [ConnectPageField.subscriptionPeiod]: subscriptionPeriod,
            },
            dontShowSuccessState: true,
            onPageConnected: () => {
              switchTierOrUpdatePaymentDependsOnBotStatus(
                nextTier,
                subscriptionPeriod,
              );
            },
          });
          break;
        case BotStatus.pro:
          switchTier(nextTier, subscriptionPeriod);
          break;
        case BotStatus.debt:
        case BotStatus.expiredDebt:
        case BotStatus.pausedAndExpiredDebt:
          showPaymentUpdateDialog((paymentMethodId) =>
            switchTier(nextTier, subscriptionPeriod, paymentMethodId),
          );
          break;
        case BotStatus.trial:
        default:
          if (paymentInfo?.active_card?.card4LastDigits) {
            switchTier(nextTier, subscriptionPeriod);
          } else {
            Modal.show(
              ({ close, resolve, reject }) => (
                <PaymentDialog
                  onSuccess={resolve}
                  onClose={close}
                  renderPaymentError={(props) => (
                    <PaymentFailureDialog
                      {...props}
                      onTryAgain={() => {
                        sendEvent({
                          category: 'pricing',
                          action: 'click try new payment card',
                          label: 'new dialogs pricing',
                          propertyBag: {
                            bot: bot.id,
                            currentPlan: currentTier?.type,
                            nextPlan: nextTier,
                          },
                        });
                        close();
                        reject();
                      }}
                    />
                  )}
                  initialPayment
                  setupPayment={(paymentMethodId) =>
                    switchTier(nextTier, subscriptionPeriod, paymentMethodId)
                  }
                />
              ),
              {
                onOverlayDismiss: deepLinkCleanup,
                id: 'ShowPaymentDialog',
                mobileAdaptive: true,
                mobileProps: {
                  fitHeight: true,
                },
              },
            )
              ?.onClose(deepLinkCleanup)
              .catch(() => {
                switchTierOrUpdatePaymentDependsOnBotStatus(
                  nextTier,
                  subscriptionPeriod,
                );
              });
          }
          break;
      }
    },
    [
      bot,
      connectPage,
      currentTier,
      deepLinkCleanup,
      paymentInfo,
      showPaymentUpdateDialog,
      switchTier,
    ],
  );

  // after Facebook page was connected and page status refetched
  useEffect(() => {
    if (nextTierToUpdate) {
      switchTierOrUpdatePaymentDependsOnBotStatus(
        nextTierToUpdate.tier,
        nextTierToUpdate.subscriptionPeriod,
      );
      setNextTierToUpdate(undefined);
    }
  }, [switchTierOrUpdatePaymentDependsOnBotStatus, nextTierToUpdate]);

  // 2.1 Click on Downgrade button or Pause plan entry point
  const onDowngradeTier = useCallback(
    (tier: TierType, subscriptionPeriod: Period) => {
      if (tier === TierType.trial) {
        showChurnSurvey();
      } else {
        switchTier(tier, subscriptionPeriod);
      }
    },
    [showChurnSurvey, switchTier],
  );

  // 2.2 Click on Upgrade button entry point
  const onUpgradeTier = useCallback(
    (tier: TierType, subscriptionPeriod: Period) => {
      switchTierOrUpdatePaymentDependsOnBotStatus(tier, subscriptionPeriod);
    },
    [switchTierOrUpdatePaymentDependsOnBotStatus],
  );

  // 3. Deeplinks entry point. Ex. /go/plans, /go/plans?dlTierId=startup&dlTierPeriod=monthly
  useEffect(() => {
    if (bot && tiers.length) {
      const queryParams = new URLSearchParams(location.search);
      const nextTierId = queryParams.get(DeepLinksQueryParam.tierId);
      const nextSubscriptionPeriod = queryParams.get(
        DeepLinksQueryParam.tierPeriod,
      );
      if (
        queryParams.get(DeepLinksQueryParam.mode) === DeepLinksMode.plans &&
        isSomeEnum(TierType, nextTierId)
      ) {
        sendEvent({
          category: 'pricing',
          action: 'open plans via deeplink',
          label: 'new dialogs pricing',
          propertyBag: {
            botId: bot.id,
            plan: nextTierId,
            period: nextSubscriptionPeriod,
          },
        });

        history.push(
          getPlansPageUrl(bot.id, true, undefined, {
            tier: nextTierId,
            period: isSomeEnum(Period, nextSubscriptionPeriod)
              ? nextSubscriptionPeriod
              : undefined,
          }),
        );
      }
    }
  }, [bot, location.search, tiers, history]);

  return {
    onDowngradeTier,
    onUpgradeTier,
    switchingTier,
    cancelProLoading,
  };
};
