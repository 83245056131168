import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import { log } from 'cf-common/src/logger';
import {
  GetShopifyConnectionLink,
  GetShopifyConnectionLinkVariables,
} from '@utils/Integrations/Shopify/@types/GetShopifyConnectionLink';
import { GET_SHOPIFY_CONNECTION_LINK } from '@utils/Integrations/Shopify/queries';
import { redirect } from '@utils/UrlUtils';
import { SHOPIFY_ONBOARDING } from './useShopifyOnboardingState';

export interface ShopifyInstallProps {}

export const ShopifyInstall: React.FC<ShopifyInstallProps> = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [install] = useMutation<
    GetShopifyConnectionLink,
    GetShopifyConnectionLinkVariables
  >(GET_SHOPIFY_CONNECTION_LINK);

  useEffect(() => {
    const shopifySearchParams = new URLSearchParams(search);
    shopifySearchParams.delete(SHOPIFY_ONBOARDING);

    install({
      variables: { shopifySearchParams: `?${shopifySearchParams.toString()}` },
    })
      .then(({ data }) => {
        const redirectLink = data?.getShopifyConnectionLink.url;

        if (!redirectLink) {
          throw new Error('No redirect link received');
        }

        redirect(redirectLink);
      })
      .catch((error) => {
        log.error({ msg: 'Redirect link request failed', error });
        history.push('/oauth/shopify/error');
      });
  }, [history, install, search]);

  return null;
};
