import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { BotTabs, useFlowGroupIdParam, useFlowIdParam } from '@utils/Routing';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';

import { Button, ButtonUnstyled } from '@ui/Button';
import { ReactComponent as SuccessIcon } from '@ui/Icon/icons/done.svg';
import { Anchor } from '@ui/Links';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { TooltipPure } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { ReactComponent as AlertIcon } from '@ui/_deprecated/Icon/icons/ic_alert_circle2.svg';

import { useFlowClone } from '../../BotPage/FlowTab/FlowList/FlowItem/FlowsMutations';
import { useCloneFlowGroup } from '../../BotPage/FlowTab/FlowList/FlowGroup/FlowGroupMutations';
import {
  SharedFlowBotListQuery_me_roles as AdminRole,
  SharedFlowBotListQuery_bots_status as BotStatus,
  SharedFlowBotListQuery_bots as BotType,
} from './@types/SharedFlowBotListQuery';

import * as css from './SharedFlowChooseBot.css';
import { hasBotClonePermissions } from './utils';
import { useTemplateKeyFromLanding } from '@utils/Template/useTemplateKeyFromLanding';
import { applyTemplateCheckList } from '@utils/Template/applyTemplateCheckList';

const HideOnMobile: React.FC<{}> = ({ children }) => (
  <span className={css.mobileHidden}>{children}</span>
);

export const Placeholder: React.FC<{ wideButton?: boolean }> = ({
  wideButton,
}) => (
  <>
    <div className={css.botItem}>
      <LoadingPlaceholder style={{ width: 36, height: 36 }} />
      <Spacer horizontalFactor={4} />
      <div className={css.botDescription}>
        <LoadingPlaceholder className={css.botTitlePlaceholder} />
        <LoadingPlaceholder className={css.botPageTitlePlaceholder} />
      </div>
      <div className={css.botActionContainer}>
        <Button disabled>
          <LoadingPlaceholder
            className={cn(css.buttonPlaceholder, {
              [css.buttonWidePlaceholder]: wideButton,
            })}
            color="white"
          />
        </Button>
      </div>
    </div>
    <Spacer factor={5} />
  </>
);

export const ItemToSaveFlow: React.FC<{
  bot: BotType;
  adminRole: AdminRole | undefined;
}> = ({ adminRole, bot }) => {
  const { id: botId, title, status } = bot;
  const history = useHistory();
  const flowId = useFlowIdParam();
  const templateKey = useTemplateKeyFromLanding();
  const { cloneFlow, error, loading, called } = useFlowClone({
    onCompleted(data) {
      if (data) {
        if (templateKey) applyTemplateCheckList(templateKey);
        sendEvent({
          category: 'flow sharing',
          action: 'view flow',
          propertyBag: {
            flowId,
            botId,
          },
        });
        history.push(`/bot/${botId}/${BotTabs.flows}/${data.cloneFlow.id}`);
      }
    },
  });

  if (loading) {
    return <Placeholder />;
  }

  const isBotHasClonePermissions = hasBotClonePermissions(adminRole);
  const firstGroupId = getFirstFlowGroupId(bot);

  return (
    <>
      <div className={css.botItem} data-testid="share-flow-bot__bot-row">
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        <BotInfo
          title={title}
          status={status}
          data-testid="share-flow-bot__title"
        />
        <div className={css.botActionContainer}>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          {!isBotHasClonePermissions && <NeedPermissionStatus botId={botId} />}
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          {called && <SaveActionStatus error={!!error} botId={botId} />}
          <Button
            data-testid="shared-flow__save-to-bot"
            onClick={() => {
              sendEvent({
                category: 'flow sharing',
                action: 'save flow',
                propertyBag: {
                  flowId,
                  botId,
                },
              });
              cloneFlow(firstGroupId!, flowId!);
            }}
            disabled={!firstGroupId || !isBotHasClonePermissions}
          >
            {window.i18next.t('Bot-JSXText--196-save-flow')}
          </Button>
        </div>
      </div>
      <Spacer factor={5} />
    </>
  );
};

export const ItemToSaveFlowGroup: React.FC<{
  bot: BotType;
  adminRole: AdminRole | undefined;
}> = ({ adminRole, bot: { id: botId, title, status } }) => {
  const history = useHistory();
  const groupId = useFlowGroupIdParam();
  const templateKey = useTemplateKeyFromLanding();
  const { cloneFlowGroup, error, loading, called } = useCloneFlowGroup({
    botId,
    groupId,
    onCompleted(data) {
      if (data) {
        if (templateKey) applyTemplateCheckList(templateKey);
        const flowId = data.cloneFlowGroup?.flow_ids?.[0] ?? '';
        sendEvent({
          category: 'share flows',
          action: 'view group',
          propertyBag: {
            groupId,
            botId,
            firstFlowIdInSavedFlow: flowId,
          },
        });
        history.push(`/bot/${botId}/${BotTabs.flows}/${flowId}`);
      }
    },
  });

  if (loading) {
    return <Placeholder wideButton />;
  }

  const isBotHasClonePermissions = hasBotClonePermissions(adminRole);

  return (
    <>
      <div className={css.botItem}>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        <BotInfo title={title} status={status} />
        <div className={css.botActionContainer}>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          {!isBotHasClonePermissions && <NeedPermissionStatus botId={botId} />}
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          {called && <SaveActionStatus error={!!error} botId={botId} />}
          <Button
            onClick={() => {
              sendEvent({
                category: 'share flows',
                action: 'save group',
                propertyBag: {
                  groupId,
                  botId,
                },
              });
              cloneFlowGroup(botId);
            }}
            disabled={!isBotHasClonePermissions}
          >
            {window.i18next.t('Bot-JSXText-3691-save-group')}
          </Button>
        </div>
      </div>
      <Spacer factor={5} />
    </>
  );
};

interface BotInfoProps {
  title: string;
  status: BotStatus | null;
}

const BotInfo: React.FC<BotInfoProps> = ({ title, status }) => (
  <>
    {status?.page_info?.picture ? (
      <div
        className={css.botImage}
        style={{ backgroundImage: `url(${status?.page_info?.picture})` }}
      />
    ) : (
      <div className={cn(css.botImage, css.botDefaultImage)} />
    )}
    <Spacer horizontalFactor={4} />
    <div className={css.botDescription}>
      <Type as="p" weight="medium" noWrap size="15px_DEPRECATED">
        {title}
      </Type>
      {status?.page_info && (
        <Type as="p" color="greyDark" noWrap size="15px_DEPRECATED">
          <HideOnMobile>
            {window.i18next.t('Bot-JSXText-1587-connected-to')}
          </HideOnMobile>
          <Anchor
            hideArrow
            intent="subtitle"
            size="regular"
            className={css.pageLink}
            href={`https://www.facebook.com/${status.page_info.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {status.page_info.title}
          </Anchor>
        </Type>
      )}
    </div>
  </>
);

export const EmptyPlaceholder: React.FC<{}> = () => (
  <>
    <div className={css.botItem}>
      <div className={cn(css.botImage, css.botNotExistedImage)} />
      <Spacer horizontalFactor={4} />
      <div className={css.botDescription}>
        <Type as="p" weight="medium" size="15px_DEPRECATED">
          {window.i18next.t('Bot-JSXText-1632-no-bots-to-save-this-flow-to')}
        </Type>
      </div>
    </div>
    <Spacer factor={5} />
  </>
);

const SaveActionStatus: React.FC<{ error: boolean; botId?: string }> = ({
  botId,
  error,
}) => {
  const flowId = useFlowIdParam();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (error) {
      sendEvent({
        category: 'flow sharing',
        action: botId ? 'fail saving' : 'fail creating',
        propertyBag: {
          flowId,
          botId,
        },
      });
    }
  }, [botId, flowId, error]);

  const StatusIcon = error ? (
    <TooltipPure
      show={showTooltip}
      placement="top"
      boundariesElement="viewport"
      content={
        <Type as="p" size="12px" color="white">
          {window.i18next.t(
            'Bot-JSXText--763-couldnt-save-flow-please-try-again',
          )}
        </Type>
      }
      type="small"
      positionFixed
    >
      {(ref) => (
        <ButtonUnstyled
          ref={ref}
          className={css.iconContainer}
          onClick={() => setShowTooltip((prevState) => !prevState)}
        >
          <AlertIcon
            className={css.alertIcon}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        </ButtonUnstyled>
      )}
    </TooltipPure>
  ) : (
    <SuccessIcon />
  );

  const StatusText = error ? (
    <Type color="red" size="15px_DEPRECATED">
      {window.i18next.t('Bot-JSXText-1065-failed')}
    </Type>
  ) : (
    <Type color="green" size="15px_DEPRECATED">
      {window.i18next.t('Bot-JSXText--171-saved')}
    </Type>
  );

  return (
    <div className={css.actionStatusContainer}>
      {StatusIcon}
      <HideOnMobile>
        <Spacer horizontalFactor={2} />
      </HideOnMobile>
      <HideOnMobile>{StatusText}</HideOnMobile>
    </div>
  );
};

const NeedPermissionStatus: React.FC<{ botId: string }> = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <Spacer horizontalFactor={2} />
      <div className={css.actionStatusContainer}>
        <TooltipPure
          show={showTooltip}
          placement="bottom"
          boundariesElement="viewport"
          content={
            <Type as="p" size="12px" color="white">
              {window.i18next.t(
                'Bot-JSXText--949-you-must-be-an-admin-or-editor-of-the',
              )}
              <br />
              {window.i18next.t('Bot-JSXText-1667-bot-to-save-this-flow-there')}
            </Type>
          }
          type="small"
          positionFixed
        >
          {(ref) => (
            <ButtonUnstyled
              ref={ref}
              className={css.iconContainer}
              onClick={() => setShowTooltip((prevState) => !prevState)}
            >
              <AlertIcon
                className={css.alertIcon}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
            </ButtonUnstyled>
          )}
        </TooltipPure>
        <HideOnMobile>
          <Spacer horizontalFactor={2} />
        </HideOnMobile>
        <HideOnMobile>
          <Type color="red" size="15px_DEPRECATED">
            {window.i18next.t('Bot-JSXText--172-no-access')}
          </Type>
        </HideOnMobile>
      </div>
    </>
  );
};
