import React from 'react';
import gql from 'graphql-tag';
import { Type } from '@ui/Type';
import { Query } from '@apollo/react-components';
import { path } from 'ramda';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { Level, log } from 'cf-common/src/logger';
import { PermissionGroup } from '@utils/Permissions';
import { GlobalLoader } from '@components/GlobalLoader/GlobalLoader';
import { InitAuthProps } from '../../utils/AuthFlow';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { AuthFlowPage } from '../../modern-ui/Page/Page';
import { Oops } from '../Oops';
import { ReactComponent as FbIcon } from '../../modern-ui/_deprecated/Icon/icons/fb.svg';
import {
  PermissionsQuery,
  PermissionsQuery_me_fbPermissions as FbPermissions,
} from './@types/PermissionsQuery';
import { sendEvent } from '../../utils/Analytics';
import { safeRedirect } from '../../utils/UrlUtils';
import * as css from './Permissions.css';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/zero-state/exclamation-mark.svg';
import { ReactComponent as RocketIcon } from '../../assets/zero-state/rocket.svg';

interface PermissionsProps extends InitAuthProps {
  interruptedHref: string;
  reason: string;
}

const PERMISSIONS_QUERY = gql`
  query PermissionsQuery {
    me {
      id
      invited
      fbPermissions {
        hasMinimalPermissions
        hasAllPermissions
        hasValidToken
        hasManagePagesPermissions
        hasMarketingPermissions
        hasInstagramAuthPermissions
      }
    }
  }
`;

function KnowHasInvalidToken(props: { onClick: () => void }) {
  return (
    <React.Fragment>
      <RocketIcon />
      <div className={css.messageCenter}>
        <span>
          {window.i18next.t(
            'Permissions-JSXText--311-please-log-in-with-facebook-to',
          )}
        </span>
        <br />
        <span>
          {window.i18next.t(
            'Permissions-JSXText-1111-continue-using-the-platform',
          )}
        </span>
      </div>
      <div className={css.guideCenter}>
        <span>
          {window.i18next.t(
            'Permissions-JSXText--915-this-action-will-fix-any-possible-problems-with',
          )}
        </span>
        <br />
        <span>
          {window.i18next.t('Permissions-JSXText--919-non-working-bots')}
        </span>
      </div>
      <Button
        intent={ButtonIntent.primary}
        onClick={props.onClick}
        renderIcon={() => <FbIcon />}
      >
        {window.i18next.t('Permissions-JSXText-1665-continue-with-facebook')}
      </Button>
    </React.Fragment>
  );
}

function KnowNeedOnlyPermissions(props: { onClick: () => void }) {
  return (
    <React.Fragment>
      <ExclamationMarkIcon />
      <div className={css.messageCenter}>
        <Type size="24px" weight="semibold">
          {window.i18next.t(
            'Permissions-JSXText--932-we-need-your-permissions',
          )}
        </Type>
      </div>
      <div className={css.guideCenter}>
        <span>
          {window.i18next.t(
            'Permissions-JSXText-1785-you-need-to-grant-us-the-necessary-permissions-for',
          )}
        </span>
        <br />
        <span>
          {window.i18next.t(
            'Permissions-JSXText-2109-your-bot-to-work-remember-we-will-not-do-anything',
          )}
        </span>
        <br />
        <span>
          {window.i18next.t(
            'Permissions-JSXText-8041-without-your-explicit-direction',
          )}
        </span>
      </div>
      <Button intent={ButtonIntent.primary} onClick={props.onClick}>
        {window.i18next.t('Permissions-JSXText--561-give-permissions')}
      </Button>
    </React.Fragment>
  );
}

export function KnowNothingContent(props: {
  onGivePermissionsClick: () => void;
  spaceBetweenBlocks: number;
  textWidth: number;
}) {
  return (
    <React.Fragment>
      <div
        className={css.adviceContainer}
        style={{
          marginBottom: props.spaceBetweenBlocks,
        }}
      >
        <div
          className={css.textAdviceContainer}
          style={{
            width: props.textWidth,
          }}
        >
          <span className={css.number}>1</span>
          <div>
            <div className={css.adviceHeader}>
              {window.i18next.t(
                'Permissions-JSXText--610-make-sure-you-have-a-facebook-business-page',
              )}
            </div>
            <div className={css.adviceBody}>
              {window.i18next.t(
                'Permissions-JSXText-1460-you-need-to-have-a-page-dedicated-to-your-brand-business-cause-etc-to-connect-the-bot',
              )}
            </div>
          </div>
        </div>
        <div className={css.adviceButtonColumn}>
          <a
            href="https://www.facebook.com/pages/create/?ref_type=pages_browser"
            rel="noreferrer noopener"
            target="_blank"
            onClick={() => {
              sendEvent({
                category: 'permissions',
                action: 'create page',
              });
            }}
          >
            <Button intent={ButtonIntent.primary} className={css.button}>
              {window.i18next.t(
                'Permissions-JSXText-3677-create-a-facebook-page',
              )}
            </Button>
          </a>
          <div className={css.adviceSubtext}>
            {window.i18next.t('Permissions-JSXText--170-its-easy-and-quick')}
          </div>
        </div>
      </div>
      <div className={css.adviceContainer}>
        <div
          className={css.textAdviceContainer}
          style={{
            width: props.textWidth,
          }}
        >
          <span className={css.number}>2</span>
          <div className={css.numberTextFix}>
            <div className={css.adviceHeader}>
              {window.i18next.t(
                'Permissions-JSXText-1057-give-us-necessary-permissions',
              )}
            </div>
            <div className={css.adviceBody}>
              {window.i18next.t(
                'Permissions-JSXText-7008-remember-you-always-remain-in-control-we-will-not-do-anything-without-your-explicit-direction',
              )}
            </div>
          </div>
        </div>
        <div className={css.adviceButtonColumn}>
          <Button
            intent={ButtonIntent.primary}
            className={css.button}
            onClick={props.onGivePermissionsClick}
          >
            {window.i18next.t('Permissions-JSXText-2330-give-us-permissions')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export class Permissions extends React.Component<PermissionsProps, {}> {
  componentDidMount() {
    const { reason, interruptedHref } = this.props;

    sendEvent({
      category: 'permissions',
      action: 'seen',
    });

    log({
      msg: 'Mount permissions page',
      data: { interruptedHref, reason },
      level: Level.warn,
    });
  }

  render() {
    const { history, interruptedHref } = this.props;

    return (
      <Query<PermissionsQuery>
        query={PERMISSIONS_QUERY}
        onCompleted={(data) => {
          if (!data || !data.me) {
            return;
          }

          const fbPermissions = path<FbPermissions>(
            ['me', 'fbPermissions'],
            data,
          );
          const redirectPage = interruptedHref || '/';

          if (
            fbPermissions?.hasMinimalPermissions ||
            fbPermissions?.hasAllPermissions
          ) {
            safeRedirect(redirectPage);
          }

          removeGlobalLoader();
        }}
        fetchPolicy="network-only" // otherwise onCompleted not firing!?
      >
        {({ data, loading, error }) => {
          if (error) {
            return (
              <Oops
                reason="Error in PERMISSIONS_QUERY"
                errorText={error.toString()}
              />
            );
          }

          if (loading || !data || !data.me) {
            return null;
          }

          const {
            hasValidToken,
            hasManagePagesPermissions,
            hasMinimalPermissions,
          } = data.me.fbPermissions;

          return (
            <AuthFlowPage
              render={() => {
                if (!hasValidToken) {
                  return (
                    <KnowHasInvalidToken
                      onClick={() => {
                        history.push({
                          pathname: '/autotokeninvalidation',
                          state: {
                            interruptedHref,
                            reason: 'invalid token',
                          },
                        });
                      }}
                    />
                  );
                }
                if (hasManagePagesPermissions) {
                  return (
                    <KnowNeedOnlyPermissions
                      onClick={() => {
                        sendEvent({
                          category: 'permissions',
                          action: 'give permissions',
                        });

                        history.push({
                          pathname: '/autopermissionsrequest',
                          state: {
                            interruptedHref,
                            permissionGroup: PermissionGroup.full_and_marketing,
                            reason: window.i18next.t(
                              'Permissions-string-8709-need-more-permissions-but-there-is-manage-pages',
                            ),
                          },
                        });
                      }}
                    />
                  );
                }

                if (!hasMinimalPermissions) {
                  return (
                    <KnowNeedOnlyPermissions
                      onClick={() => {
                        sendEvent({
                          category: 'permissions',
                          action: 'give permissions',
                        });

                        history.push({
                          pathname: '/autopermissionsrequest',
                          state: {
                            interruptedHref,
                            reason: 'invited user need more permissions',
                          },
                        });
                      }}
                    />
                  );
                }

                return <GlobalLoader />;
              }}
            />
          );
        }}
      </Query>
    );
  }
}
