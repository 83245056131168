import React from 'react';
import * as css from './NoBotsWhiteLabel.css';
import { AuthFlowPage } from '../../../../modern-ui/Page';
import { InitAuthProps } from '../../../../utils/AuthFlow';
import eyesImg from './images/eyes.png';
import { LogoSize } from '../../../../modern-components/PageHeader/WhiteLabelLogo';

export const NoBotsWhiteLabel: React.FC<InitAuthProps> = () => {
  return (
    <AuthFlowPage
      logoSize={LogoSize.big}
      hideNav
      render={() => (
        <>
          <img src={eyesImg} alt="" className={css.eyes} />
          <div className={css.note}>
            {window.i18next.t(
              'NoBotsWhiteLabel-JSXText--138-you-have-no-bots-ask-admin-for-access',
            )}
          </div>
        </>
      )}
    />
  );
};
