import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloProvider, useQuery, useLazyQuery } from 'react-apollo';

import { PageHeader } from '@components/PageHeader';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { useCreateBlankBot } from '@utils/Data/Bot/useCreateBlankBot';
import { Page } from '@ui/Page';
import { ScrollBox } from '@ui/ScrollBox';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { ToasterProvider, useToaster } from '@ui/Toaster';
import { ReactComponent as GlobalLoaderIcon } from '@ui/_common/images/global_loader.svg';

import { sendEvent } from '@utils/Analytics';
import {
  BotTabs,
  useFlowGroupIdParam,
  useFlowIdParam,
  usePlatformParam,
} from '@utils/Routing';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import { BOTS_SELECTOR_QUERY } from '@utils/Data/Bot';
import { useAddFlowGroup } from '@utils/Data/Flow';
import { Level, log } from 'cf-common/src/logger';

import client from '../../../common/services/ApolloService';
import * as Bot from './Bot';
import * as Queries from './query';
import {
  SharedFlowBotListQuery,
  SharedFlowBotListQuery_bots,
} from './@types/SharedFlowBotListQuery';
import { useFlowClone } from '../../BotPage/FlowTab/FlowList/FlowItem/FlowsMutations';
import { useCloneFlowGroup } from '../../BotPage/FlowTab/FlowList/FlowGroup/FlowGroupMutations';

import * as css from './SharedFlowChooseBot.css';
import { hasBotClonePermissions } from './utils';
import { setFirstSessionDone } from '../../BotPage/KeywordsPage/FirstSessionPage/utils/setFirstSessionDone';
import { setTemplatesModalForNewUserShowed } from '../../BotPage/FlowTab/FlowList/utils';
import { sendIntercomEvent } from '@utils/Intercom/Intercom';
import { IntercomEvents } from '@utils/Intercom/consts';
import { useTemplateKeyFromLanding } from '@utils/Template/useTemplateKeyFromLanding';
import { applyTemplateCheckList } from '@utils/Template/applyTemplateCheckList';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getAllowedPlatforms } from '@utils/Platform/getAllowedPlatforms';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';

const BOTS_PLACEHOLDERS_COUNT = 4;

export enum SharedFlowPageMode {
  flow,
  flowGroup,
}

interface SharedFlowChooseBotProps {
  mode: SharedFlowPageMode;
}

const SharedFlowChooseBotPage: React.FC<SharedFlowChooseBotProps> = ({
  mode,
}) => {
  const { t } = useSafeTranslation();
  const {
    data,
    loading: sharedFlowBotListLoading,
    refetch: refetchSharedFlowBotList,
  } = useQuery<SharedFlowBotListQuery>(Queries.SHARED_FLOW_BOT_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
  });
  const platform = usePlatformParam();

  const bots = useMemo(
    () =>
      data?.bots?.filter(
        ({ allowedPlatforms }) =>
          !platform ||
          !allowedPlatforms ||
          testAllowedPlatforms([platform], allowedPlatforms),
      ) ?? [],
    [data, platform],
  );
  const botId = data?.bots?.[0]?.id!;

  const templateKey = useTemplateKeyFromLanding();

  const { addFlowGroup, loading: addFlowGroupLoading } = useAddFlowGroup({});
  const history = useHistory();
  const groupId = useFlowGroupIdParam();
  const flowId = useFlowIdParam();

  const [isCloning, setIsCloning] = useState(false); // loading states not perfectly suites this
  const { cloneFlow } = useFlowClone({
    onCompleted(data) {
      if (templateKey) applyTemplateCheckList(templateKey);
      history.push(
        `/bot/${botId}/${BotTabs.flows}/${data?.cloneFlow.id ?? ''}`,
      );
    },
  });
  const { cloneFlowGroup } = useCloneFlowGroup({
    botId,
    groupId,
    onCompleted(data) {
      if (templateKey) applyTemplateCheckList(templateKey);
      const [createFlowId] = data.cloneFlowGroup.flow_ids!;
      history.push(`/bot/${botId}/${BotTabs.flows}/${createFlowId ?? ''}`);
    },
  });
  const handleClone = useCallback(async () => {
    if (data && botId) {
      setIsCloning(true);

      const [bot] = data.bots;
      const flowGroupId = getFirstFlowGroupId(bot);

      if (!flowGroupId) {
        await addFlowGroup(bot.id);
      }

      sendEvent({
        category: 'flow sharing',
        action: 'save group',
        propertyBag: Object.assign(
          { status: !getFirstFlowGroupId(bot) ? 'new user' : 'only flow' },
          mode === SharedFlowPageMode.flow ? { flowId } : { groupId },
        ),
      });

      if (mode === SharedFlowPageMode.flow) {
        cloneFlow(flowGroupId!, flowId!);
      } else {
        cloneFlowGroup(bot.id);
      }
    }
  }, [
    data,
    botId,
    mode,
    flowId,
    groupId,
    cloneFlow,
    cloneFlowGroup,
    addFlowGroup,
  ]);

  const getAdminRoleForBot = (bot: SharedFlowBotListQuery_bots) =>
    data?.me.roles?.find((v) => v.bot_id === bot.id);

  const shouldClone =
    data?.bots?.length === 1 &&
    hasBotClonePermissions(getAdminRoleForBot(data.bots[0])) &&
    (data.bots[0].flow_groups?.length ?? 0) <= 1 &&
    (data.bots[0].flow_groups?.[0]?.flow_ids?.length ?? 0) <= 1 &&
    (!platform ||
      !data.bots[0].allowedPlatforms ||
      testAllowedPlatforms([platform], data.bots[0].allowedPlatforms)) &&
    !isCloning;

  useEffect(() => {
    if (shouldClone) {
      handleClone();
    }
  }, [shouldClone, handleClone]);

  useEffect(() => {
    setFirstSessionDone();
    setTemplatesModalForNewUserShowed();
    sendIntercomEvent(IntercomEvents.openSharedFlowTemplate, {
      flowId,
      groupId,
    });
  }, [flowId, groupId]);

  const { addToaster } = useToaster();
  const [fetchFlowListAdminRoles] = useLazyQuery(
    Queries.SHARED_FLOW_BOT_LIST_ADMIN_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: botSelectorData, loading: botSelectorLoading } =
    useQuery(BOTS_SELECTOR_QUERY);
  const [createNewBot, { loading: newBotLoading }] =
    useCreateBlankBot(botSelectorData);

  const loading = Boolean(
    sharedFlowBotListLoading || botSelectorLoading || addFlowGroupLoading,
  );
  const isFlowMode = mode === SharedFlowPageMode.flow;
  const buttonDisabled = newBotLoading || loading || sharedFlowBotListLoading;

  return sharedFlowBotListLoading || isCloning || shouldClone ? (
    <div className="global-loader">
      <GlobalLoaderIcon className="global-loader-cf" />
    </div>
  ) : (
    <Page>
      <PageHeader logoSize={LogoSize.big} hideNav />
      <div className={css.botChooseContainer}>
        <div className={css.infoContainer}>
          <Type as="h1" size="24px">
            {isFlowMode
              ? t(
                  'SharedFlowChooseBot-string--100-choose-a-bot-to-save-this-flow',
                )
              : t(
                  'SharedFlowChooseBot-string-7495-to-save-this-group-choose-a-bot',
                )}
          </Type>
          <Spacer factor={4} />
          <Type as="h2" color="greyDark" size="15px_DEPRECATED">
            {isFlowMode
              ? `${t(
                  'SharedFlowChooseBot-Template--186-this-flow-will-be-saved-to-the-default-group-in-flow-list-in-the-bot-that-you-choose',
                )}`
              : `${t(
                  'SharedFlowChooseBot-Template--125-choose-a-bot-youd-like-to-save-this-flow-group-to-it-will-appear-in-the-flow-list-of-the-bot-you-select',
                )}`}
          </Type>
        </div>
        <ScrollBox
          className={css.scrollBox}
          data-testid="shared-flow-choose-bot__bots-list"
        >
          {newBotLoading && <Bot.Placeholder wideButton={!isFlowMode} />}
          {loading &&
            Array.from({ length: BOTS_PLACEHOLDERS_COUNT }, (_, index) => (
              <Bot.Placeholder key={index} wideButton={!isFlowMode} />
            ))}
          {!loading && bots.length === 0 && <Bot.EmptyPlaceholder />}
          {!loading &&
            bots.length > 0 &&
            bots.map((bot) => {
              const adminRoleForBot = getAdminRoleForBot(bot);
              return isFlowMode ? (
                <Bot.ItemToSaveFlow
                  key={bot.id}
                  bot={bot}
                  adminRole={adminRoleForBot}
                />
              ) : (
                <Bot.ItemToSaveFlowGroup
                  key={bot.id}
                  bot={bot}
                  adminRole={adminRoleForBot}
                />
              );
            })}
        </ScrollBox>
        <div className={css.botChooseFooter}>
          <Button
            data-testid="shared-flow-choose-bot__create-bot-button"
            intent="secondary"
            onClick={async () => {
              sendEvent({
                category: 'flow sharing',
                action: 'create a bot',
                propertyBag: {
                  flowId,
                },
              });
              try {
                await createNewBot(null, getAllowedPlatforms(platform));
                fetchFlowListAdminRoles();
                refetchSharedFlowBotList();
              } catch {
                addToaster({
                  type: 'error',
                  content: (
                    <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
                      {t(
                        'SharedFlowChooseBot-JSXText-8890-something-went-wrong-please-try-again-later',
                      )}
                    </div>
                  ),
                  timeout: 2000,
                  closeButton: true,
                });
                log({
                  level: Level.warn,
                  msg: 'Shared flow bot creation failed',
                  data: {
                    flowId,
                  },
                });
              }
            }}
            disabled={buttonDisabled}
          >
            {t('SharedFlowChooseBot-JSXText-1176-create-a-bot')}
          </Button>
          <Button
            className={css.refreshButton}
            intent="text"
            icon={<Icon icon="process" />}
            data-testid="shared-flow-choose-bot__refresh-button"
            onClick={() => {
              if (groupId) {
                sendEvent({
                  category: 'share flows',
                  action: 'refresh bot list',
                  propertyBag: {
                    groupId,
                  },
                });
              } else {
                sendEvent({
                  category: 'flow sharing',
                  action: 'refresh bot list',
                  propertyBag: {
                    flowId,
                  },
                });
              }
              refetchSharedFlowBotList();
            }}
            disabled={buttonDisabled}
          >
            {t('SharedFlowChooseBot-JSXText-1809-refetch-bot-list')}
          </Button>
        </div>
      </div>
    </Page>
  );
};

export const SharedFlowChooseBot: React.FC<SharedFlowChooseBotProps> = (
  props,
) => (
  <ApolloProvider client={client}>
    <ToasterProvider>
      <SharedFlowChooseBotPage {...props} />
    </ToasterProvider>
  </ApolloProvider>
);
