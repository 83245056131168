// TODO @REMOVE ? can't find any usages in the project

import React from 'react';
import ng from 'angular';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import { AgencyLinkWhiteLabel } from './AgencyLinkWhiteLabel';
import client from '../../../../common/services/ApolloService';
import { getLinks } from '../../../../modern-components/PageHeader/PageHeaderNav';

const AgencyLinkWhiteLabelWrapper = () => {
  const { t } = useSafeTranslation();
  const links = getLinks(t);

  return (
    <ApolloProvider client={client}>
      <AgencyLinkWhiteLabel noUseRouter {...links[1]} />
    </ApolloProvider>
  );
};

export const ngAgencyLinkWhiteLabel = ng
  .module('app.links.agencyLinkWhiteLabel', [])
  .component(
    'agencyLinkWhiteLabel',
    react2angular(AgencyLinkWhiteLabelWrapper, [], []),
  ).name;
