import React, { useCallback } from 'react';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import * as css from './WhatsappPhoneCallout.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { DeepLinksMode } from '../../../DeepLinks/types';
import { sendEvent } from '@utils/Analytics';

export const WhatsappPhoneCallout: React.FC = () => {
  const { getValueDueToSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const history = useHistory();
  const botId = useCurrentBotId();
  const { isWhatsappEnabled, loading: loadingWhatsappEnabled } =
    useWhatsappEnabled();
  const { isConnected, loading: loadingWhatsappConnected } =
    useWhatsappConnected(botId);

  const connectClickHandler = useCallback(() => {
    sendEvent({
      category: 'plans',
      label: 'whatsapp phone callout',
      action: 'connect button click',
    });
    history.push(
      getTabLink(BotTabs.home, botId, {
        dlMode: DeepLinksMode.connectWhatsapp,
      }),
    );
  }, [botId, history]);

  if (
    !isWhatsappEnabled ||
    isConnected ||
    loadingWhatsappEnabled ||
    loadingWhatsappConnected
  ) {
    return null;
  }

  return (
    <>
      <Spacer factor={getValueDueToSize(4, 6)} />
      <div className={cn(css.box, getValueDueToSize(css.mBox, css.box))}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection={getValueDueToSize('column', 'row')}
          gapFactor={getValueDueToSize(3, 6)}
        >
          <div className={css.textBox}>
            <Type color="accent2Text" weight="bold" size="18px">
              {t('pages.Pricing.whatsapp.callout.title')}
            </Type>
            <Spacer factor={1} />
            <Type color="accent2Text">
              {t('pages.Pricing.whatsapp.callout.text')}
            </Type>
          </div>
          <Button
            data-testid="whatsapp-phone-callout_connect-button"
            className={cn(
              css.button,
              getValueDueToSize(css.mButton, css.button),
            )}
            intent="primary"
            onClick={connectClickHandler}
          >
            {t('pages.Pricing.whatsapp.callout.connect')}
          </Button>
        </Flex>
      </div>
    </>
  );
};
