import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { PremiumTierCardProps } from '../types';
import { TierCardPrice } from '../TierCardPrice';
import { PremiumTierCardAction } from '../PremiumTierCardAction';
import { PremiumTierCardLabel } from '../TierCardLabel';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ChargingEntityType, TierType } from '@globals';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import * as css from './PremiumTierCard.css';

export const PremiumTierCard: React.FC<PremiumTierCardProps> = (props) => {
  const { t } = useSafeTranslation();
  const { tier, nextTier, selectedTier, currency, pricing } = props;
  const chargingType =
    pricing.charging_entity_type === ChargingEntityType.users
      ? t('pages.PremiumPricing.tierCard.users')
      : t('pages.PremiumPricing.tierCard.conversations');
  const options = [
    t('pages.PremiumPricing.tierCard.usersLimit', {
      limit: tier.limit,
      chargingType,
    }),
    t('pages.PremiumPricing.tierCard.pagesLimit', { pages: tier.pagesLimit }),
  ];

  const isCurrentTierSelected = tier.type === selectedTier?.type;
  const isCurrentTierNext = tier.type === nextTier?.type;
  const isNextTierExists = !!nextTier;
  const moreExpensiveThanCurrent =
    parseFloat(tier.price) >
    parseFloat((nextTier ? nextTier?.price : selectedTier?.price) ?? '0');

  return (
    <Flex
      className={cn(css.root, {
        [css.nextTier]:
          isCurrentTierSelected && !isCurrentTierNext && isNextTierExists,
        [css.currentPlan]: isCurrentTierNext || isCurrentTierSelected,
      })}
      flexDirection="column"
      alignItems="center"
      data-testid="workspace_plans__premium_tier_card"
    >
      <PremiumTierCardLabel
        nextPrepaymentDate={pricing?.next_prepayment_date}
        currentSubscriptionPeriod={pricing?.current_subscription_period}
        hasNextTier={isNextTierExists}
        isCurrentTierSelected={isCurrentTierSelected}
        isCurrentTierNext={isCurrentTierNext}
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        className={css.container}
      >
        <Spacer factor={3} />
        <Type
          size="18px"
          weight="semibold"
          align="center"
          data-testid="premium_tier_card__tier_name"
        >
          {tier.customName ?? getTierNameByType(tier.type as TierType)}
        </Type>
        <Spacer factor={9} />
        <TierCardPrice
          currency={currency}
          price={parseFloat(tier.price)}
          data-testid="premium_tier_card__tier_price"
        />
        <Type size="15px" color="greyDark">
          {t('pages.PremiumPricing.tierCard.ifPayedAnnually', {
            users: tier.limit,
          })}
        </Type>
        <Spacer factor={8} />
        <ul className={css.list} data-testid="premium_tier_card__tier_options">
          {options.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={css.item}>
              <Type size="15px">{text}</Type>
            </li>
          ))}
        </ul>
        <Spacer factor={10} />
        <PremiumTierCardAction
          {...props}
          moreExpensiveThanCurrent={moreExpensiveThanCurrent}
          isCurrentTierSelected={isCurrentTierSelected}
          isCurrentTierNext={isCurrentTierNext}
        />
        <Spacer factor={5} />
      </Flex>
    </Flex>
  );
};
