import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as css from './LoginFormWhiteLabel.css';
import { Input } from '../../../../modern-ui/Input';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';
import { Autofocus } from '../../../../modern-ui/Autofocus';

export interface IWhiteLabelLoginData {
  login: string;
  password: string;
}

export enum LoginErrors {
  login = 'login',
  password = 'password',
}

export enum LoginFormMode {
  login = 'login',
  createLogin = 'createLogin',
}

interface ILoginFormLoginWhiteLabelProps
  extends React.HTMLProps<HTMLDivElement> {
  botName?: string | undefined;
  botPicture?: string | undefined;
  login?: string | undefined;
  onLoginRequest: (data: IWhiteLabelLoginData) => void;
  mode?: LoginFormMode;
  disabled?: boolean;
  error?: LoginErrors;
}

interface FormValues {
  login: string;
  password: string;
  passwordConfirmation: string;
}

export const LoginFormWhiteLabel: React.FC<ILoginFormLoginWhiteLabelProps> = ({
  botPicture,
  botName,
  onLoginRequest,
  mode,
  disabled: componentDisabled,
  error: serverError,
  ...props
}) => {
  const formInitialValues: FormValues = {
    login: props.login || '',
    password: '',
    passwordConfirmation: '',
  };

  return (
    <React.Fragment>
      {botPicture && (
        <div
          className={css.picture}
          style={{
            backgroundImage: `url('${botPicture}')`,
          }}
          title={botName}
        />
      )}
      <div className={css.title}>
        {botName ? (
          <React.Fragment>
            <span>
              {window.i18next.t(
                'LoginFormWhiteLabel-JSXText-1938-log-in-to-manage-your',
              )}
            </span>
            <br />
            <span>{`‘${botName}${window.i18next.t(
              'LoginFormWhiteLabel-Template--100-bot',
            )}`}</span>
          </React.Fragment>
        ) : (
          <span>
            {window.i18next.t(
              'LoginFormWhiteLabel-JSXText--259-log-in-to-manage-the-bot',
            )}
          </span>
        )}
      </div>
      <Formik
        initialValues={formInitialValues}
        validationSchema={Yup.object().shape({
          login: Yup.string().required(
            window.i18next.t(
              'LoginFormWhiteLabel-string--368-enter-your-login',
            ),
          ),
          password: Yup.string().required(
            window.i18next.t(
              'LoginFormWhiteLabel-string--159-password-is-required',
            ),
          ),
          passwordConfirmation: Yup.string().when('password', {
            is: (password) => !!password && mode === LoginFormMode.createLogin,
            then: Yup.string()
              .oneOf(
                [Yup.ref('password')],
                window.i18next.t(
                  'LoginFormWhiteLabel-string-5714-passwords-must-match',
                ),
              )
              .required(
                window.i18next.t(
                  'LoginFormWhiteLabel-string-2323-re-enter-password',
                ),
              ),
          }),
        })}
        onSubmit={async ({ login, password }, actions) => {
          onLoginRequest({
            login,
            password,
          });
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={css.inputBox}>
              {/* eslint-disable-next-line */}
              <label htmlFor="loginInputId" className={css.label}>
                {window.i18next.t('LoginFormWhiteLabel-JSXText--331-username')}
              </label>
              <Autofocus
                shouldFocus={!props.login}
                render={({ bind }) => (
                  <Input
                    value={values.login}
                    id="loginInputId"
                    name="login"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={mode === LoginFormMode.createLogin}
                    disabled={componentDisabled}
                    error={
                      serverError === LoginErrors.login ||
                      (!!errors.login && touched.login)
                    }
                    {...bind}
                  />
                )}
              />
              {serverError === LoginErrors.login && (
                <div className={css.error}>
                  {window.i18next.t(
                    'LoginFormWhiteLabel-JSXText--127-user-not-found',
                  )}
                </div>
              )}
              {errors.login && touched.login && (
                <div className={css.error}>{errors.login}</div>
              )}
            </div>
            <div className={css.inputBox}>
              {/* eslint-disable-next-line */}
              <label htmlFor="passwordInputId" className={css.label}>
                {window.i18next.t('LoginFormWhiteLabel-JSXText--138-password')}
              </label>
              <Autofocus
                shouldFocus={!!props.login}
                render={({ bind }) => (
                  <Input
                    value={values.password}
                    id="passwordInputId"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={
                      mode === LoginFormMode.createLogin
                        ? window.i18next.t(
                            'LoginFormWhiteLabel-string--120-create-new-password',
                          )
                        : undefined
                    }
                    disabled={componentDisabled}
                    type="password"
                    name="password"
                    error={
                      serverError === LoginErrors.password ||
                      (!!errors.password && touched.password)
                    }
                    {...bind}
                  />
                )}
              />
              {serverError === LoginErrors.password && (
                <div className={css.error}>
                  {window.i18next.t(
                    'LoginFormWhiteLabel-JSXText--701-wrong-password-for-this-login',
                  )}
                </div>
              )}
              {errors.password && touched.password && (
                <div className={css.error}>{errors.password}</div>
              )}
            </div>
            {mode === LoginFormMode.createLogin && (
              <div className={css.inputBox}>
                {/* eslint-disable-next-line */}
                <label htmlFor="confirmPasswordInputId" className={css.label}>
                  {window.i18next.t(
                    'LoginFormWhiteLabel-JSXText--232-confirm-password',
                  )}
                </label>
                <Input
                  value={values.passwordConfirmation}
                  id="confirmPasswordInputId"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={componentDisabled}
                  type="password"
                  name="passwordConfirmation"
                  error={
                    serverError === LoginErrors.password ||
                    (!!errors.passwordConfirmation &&
                      touched.passwordConfirmation)
                  }
                />
                {errors.passwordConfirmation &&
                  touched.passwordConfirmation && (
                    <div className={css.error}>
                      {errors.passwordConfirmation}
                    </div>
                  )}
              </div>
            )}
            <div className={css.buttonBox}>
              <Button
                intent={ButtonIntent.primary}
                disabled={!isValid || componentDisabled}
                className={css.button}
              >
                {window.i18next.t('LoginFormWhiteLabel-JSXText--195-log-in')}
              </Button>
              <div>
                {mode === LoginFormMode.login && (
                  <span className={css.ask}>
                    {window.i18next.t(
                      'LoginFormWhiteLabel-JSXText--618-forgot-password-or-login-ask-us-for-help',
                    )}
                  </span>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
