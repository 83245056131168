import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Flex } from '@ui/Flex';
import { useIsWorkspacePlansAvailable } from './hooks/useIsWorkspacePlansAvailable/useIsWorkspacePlansAvailable';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { Page } from '@ui/Page';
import { PageContent } from '@ui/PageContent';
import { PageHeader } from '@components/PageHeader';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  CHATFUEL_DEMO_URL,
  getPremiumPlanTabs,
  PRICING_PLAN_QUERY_PARAM,
} from './constants';
import { useQueryParams } from '@utils/queryParams';
import { usePremiumTiersData } from '@utils/Data/Pricing/hooks';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { ContactUsBanner } from './components/ContactUsBanner';
import { PremiumTierList } from './components/PremiumTierList';
import { PREMIUM_BILLING_PATH } from '@pages/Billing/PremiumBillingPage/constants';
import { PageNavigationTabs } from '@ui/PageNavigationTabs';
import { TierSubtype } from '@globals';
import * as css from './PremiumPlansPage.css';

const PremiumPlansPage: React.FC<{}> = () => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();

  const initialTabId = TierSubtype.premium;
  const { [PRICING_PLAN_QUERY_PARAM]: activeTab } = useQueryParams();

  const { data: tiersData, loading: tiersLoading } = usePremiumTiersData();
  const tiers = tiersData?.tiers?.filter((v) => v.subType === activeTab) ?? [];

  return (
    <Page className={css.page}>
      {isSmallScreenSize ? (
        <Spacer factor={16} />
      ) : (
        <PageHeader logoSize={LogoSize.default} />
      )}
      <PageContent>
        <Flex className={css.pageContentContainer} justifyContent="center">
          <Flex
            className={css.pageContent}
            flexDirection="column"
            alignItems="center"
          >
            <Spacer factor={10} />
            <Type as="h1" size="24px" weight="bold" align="center">
              {t('pages.PremiumPricing.title')}
            </Type>
            {tiersData?.workspaceName && (
              <>
                <Spacer factor={1} />
                <Type
                  as="h2"
                  size="18px"
                  weight="medium"
                  align="center"
                  color="greyDark"
                  data-testid="workspace_plans__workspace_name"
                >
                  {tiersData.workspaceName}
                </Type>
              </>
            )}
            <Spacer factor={3} />
            <PageNavigationTabs
              className={css.segmentButton}
              tabColor="toggleBlueOnly"
              tabs={getPremiumPlanTabs()}
              initialTabId={initialTabId}
              navParam={PRICING_PLAN_QUERY_PARAM}
            />
            <Spacer />
            <PremiumTierList
              loading={tiersLoading}
              currency={tiersData?.currency}
              tiers={tiers}
            />
            <Spacer factor={5} />
            <ContactUsBanner
              onContactUs={() => {
                sendEvent({
                  category: 'premium pricing',
                  action: 'click FAQ item',
                  label: 'new workspace pricing',
                  propertyBag: { activeTab },
                });
                window.open(CHATFUEL_DEMO_URL, '_blank');
              }}
              isSmallScreenSize={isSmallScreenSize}
            />
            <Spacer factor={4} horizontalFactor={1} />
          </Flex>
        </Flex>
      </PageContent>
    </Page>
  );
};

export const PremiumPlansPageContainer = () => {
  useResetMetaForMobile();

  const { isAvailable, loading } = useIsWorkspacePlansAvailable();

  useEffect(() => {
    if (!loading) {
      removeGlobalLoader();
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  if (!isAvailable) {
    return <Redirect to={PREMIUM_BILLING_PATH} />;
  }

  return <PremiumPlansPage />;
};
