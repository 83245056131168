import React from 'react';
import cn from 'classnames';
import { Page } from '@ui/Page';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as ShopifyBagIcon } from './images/shopify-bag.svg';
import { ReactComponent as ArrowIcon } from './images/arrow.svg';
import { ReactComponent as ChatfuelIcon } from './images/chatfuel.svg';
import * as css from './ShopifyChooseBotPage.css';

export interface ShopifyChooseBotPageProps {
  disabled: boolean;
  footerView?: React.ReactNode;
}

export const ShopifyChooseBotPage: React.FC<ShopifyChooseBotPageProps> = ({
  disabled,
  children,
  footerView,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Page className={css.page}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={css.wrapper}
      >
        <div className={css.main}>
          <Type as="h1" size="18px" weight="bold">
            {t('ShopifyOnboarding.chooseBot.title')}
          </Type>

          <Spacer factor={5} />

          <Flex
            justifyContent="center"
            alignItems="center"
            className={css.icon}
          >
            <ShopifyBagIcon />
            <ArrowIcon className={cn({ [css.disabled]: disabled })} />
            <ChatfuelIcon className={cn({ [css.disabled]: disabled })} />
          </Flex>

          <Spacer factor={5} />

          {children}
        </div>

        {footerView}
      </Flex>
    </Page>
  );
};
