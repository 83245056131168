import gql from 'graphql-tag';

export const SHARED_FLOW_BOT_LIST_ADMIN_FRAGMENT = gql`
  fragment SharedFlowAdminFragment on Admin {
    id
    roles {
      bot_id
      role_id
    }
  }
`;

export const SHARED_FLOW_BOT_LIST_QUERY = gql`
  query SharedFlowBotListQuery {
    me {
      ...SharedFlowAdminFragment
    }
    bots {
      id
      title
      allowedPlatforms
      status {
        page_info {
          id
          title
          picture
        }
      }
      flow_groups {
        id
        flow_ids
      }
    }
  }
  ${SHARED_FLOW_BOT_LIST_ADMIN_FRAGMENT}
`;

export const SHARED_FLOW_BOT_LIST_ADMIN_QUERY = gql`
  query SharedFlowBotListAdminQuery {
    me {
      ...SharedFlowAdminFragment
    }
  }
  ${SHARED_FLOW_BOT_LIST_ADMIN_FRAGMENT}
`;

export const CREATE_NEW_BOT_MUTATION = gql`
  mutation CreateNewBotForSharedFlowBotListMutation($title: String!) {
    createBot(title: $title) {
      id
      title
      status {
        page_info {
          id
          title
          picture
        }
      }
    }
  }
`;
