import React from 'react';
import cn from 'classnames';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import * as css from './FAQSection.css';

interface FAQSectionProps {
  title: string;
  description: string;
  onChangeExtended?(extended: boolean): void;
}

export const FAQSection: React.FC<FAQSectionProps> = ({
  title,
  onChangeExtended,
  description,
}) => (
  <div className={css.container}>
    <CollapsibleSection
      anchor={({ changeExtended, extended }) => (
        <ButtonUnstyled
          className={css.sectionTitle}
          onClick={() => {
            onChangeExtended?.(extended);
            changeExtended();
          }}
        >
          <Type size="15px" weight="semibold">
            {title}
          </Type>
          <Icon
            className={cn(css.icon, { [css.iconExtended]: extended })}
            icon="triangle"
          />
        </ButtonUnstyled>
      )}
    >
      {({ bind }) => (
        <div {...bind}>
          <Spacer factor={4} />
          <Type size="15px" as="p">
            {description}
          </Type>
        </div>
      )}
    </CollapsibleSection>
  </div>
);
