import { useQuery } from 'react-apollo';
import { USER_WORKSPACE_PLANS_AVAILABILITY_QUERY } from './queries';
import { UserWorkspacePlansAvailabilityQuery } from './@types/UserWorkspacePlansAvailabilityQuery';

export const useIsWorkspacePlansAvailable = () => {
  const { data, loading } = useQuery<UserWorkspacePlansAvailabilityQuery>(
    USER_WORKSPACE_PLANS_AVAILABILITY_QUERY,
  );
  const isAvailable = !!data?.me.show_workspace_plans;

  return {
    isAvailable,
    loading,
  };
};
