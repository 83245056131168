import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import Maybe from 'graphql/tsutils/Maybe';
import { SKIP_PAYWALL_MUTATION } from './queries';
import {
  SkipPaywallMutation,
  SkipPaywallMutationVariables,
} from './@types/SkipPaywallMutation';
import { updatePaywallStatusCache } from '@utils/Data/Paywall/helpers';
import { assert } from '@utils/Assert';
import { PaywallStatus } from '@globals';

export const usePaywallSkip = (pageId: Maybe<string>) => {
  const [skipPaywallMutation, { loading }] = useMutation<
    SkipPaywallMutation,
    SkipPaywallMutationVariables
  >(SKIP_PAYWALL_MUTATION, {
    onCompleted: () => {
      assert(pageId, {
        msg: 'paywall does not show without page, so you can not skip it without pageId',
      });
      updatePaywallStatusCache(pageId, PaywallStatus.skipped);
    },
  });

  const skipPaywall = useCallback(() => {
    assert(pageId, {
      msg: 'paywall does not show without page, so you can not skip it without pageId',
    });
    return skipPaywallMutation({
      variables: { pageId },
    });
  }, [pageId, skipPaywallMutation]);

  return {
    skipPaywall,
    loading,
  };
};
