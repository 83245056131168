import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { InitAuthProps } from '@utils/AuthFlow';
import { useCurrentTab } from '@utils/Routing';
import { GlobalLoader } from '@components/GlobalLoader/GlobalLoader';
import { UserBotsIdsQuery } from './@types/UserBotsIdsQuery';
import { Oops } from '../Oops';
import {
  DOMAIN_TO_TAB_STATE_NAME,
  redirectToAvailableTabIfNeed,
} from '../../common/services/NgRoutePermissionsService';
import { Domain } from '../../common/services/RoleService';

type RedirectToBotParams = {
  botId: string | undefined;
};

// fast get user bots ids from roles
const USER_BOTS_IDS_QUERY = gql`
  query UserBotsIdsQuery {
    me {
      id
      abt_serialized
      roles {
        bot_id
      }
      last_opened_bot_id
    }
  }
`;

const DEFAULT_DOMAIN: Domain = 'grow';

export const RedirectToBot: React.FC<InitAuthProps> = ({
  match: { params },
  client,
}) => {
  const [execute, { data, loading, error, called }] =
    useLazyQuery<UserBotsIdsQuery>(USER_BOTS_IDS_QUERY, { client } as any);
  const [botId, setBotId] = useState<string | undefined | null>(
    (params as RedirectToBotParams).botId,
  );

  const currentDomain = (useCurrentTab() as Domain) || DEFAULT_DOMAIN;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!botId && !called) {
      execute();
    }
  }, [called, botId, execute]);

  useEffect(() => {
    if (!loading && !error && data) {
      setBotId(
        data?.me.last_opened_bot_id || data?.me.roles?.[0]?.bot_id || null,
      );
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (botId) {
      redirectToAvailableTabIfNeed(
        botId,
        currentDomain,
        history,
        location,
      ).then((redirected) => {
        if (!redirected) {
          history.replace(
            `/bot/${botId}/${DOMAIN_TO_TAB_STATE_NAME[currentDomain]}`,
          );
        }
        removeGlobalLoader();
      });
    }
  }, [botId, currentDomain, history, location]);

  const isUserNoHaveBots = botId === null;

  useEffect(() => {
    if (error || isUserNoHaveBots) {
      removeGlobalLoader();
    }
  }, [error, isUserNoHaveBots]);

  return (
    <>
      {isUserNoHaveBots && <Redirect to="/bots" push />}
      {error ? (
        <Oops reason="Error load bots data" errorText={error.message} />
      ) : (
        <GlobalLoader />
      )}
    </>
  );
};
