import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as PremiumPlansSvg } from './assets/PremiumPlans.svg';
import * as css from './ContactUsBanner.css';

interface ContactUsBannerProps {
  isSmallScreenSize: boolean;
  onContactUs(): void;
}

export const ContactUsBanner: React.FC<ContactUsBannerProps> = ({
  isSmallScreenSize,
  onContactUs,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      className={cn(css.container, {
        [css.containerNarrow]: isSmallScreenSize,
      })}
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
      alignItems={isSmallScreenSize ? 'flex-start' : 'center'}
      justifyContent="space-around"
    >
      <PremiumPlansSvg />

      <Spacer
        factor={isSmallScreenSize ? 4 : 0}
        horizontalFactor={isSmallScreenSize ? 0 : 8}
      />
      <Flex flexDirection="column">
        <Type size="18px" weight="semibold">
          {t('pages.PremiumPricing.contactUs.title')}
        </Type>
        <Type size="15px">{t('pages.PremiumPricing.contactUs.body')}</Type>
      </Flex>
      <Spacer
        factor={isSmallScreenSize ? 5 : 0}
        horizontalFactor={isSmallScreenSize ? 0 : 10}
      />
      <Button
        className={cn({
          [css.buttonNarrow]: !isSmallScreenSize,
          [css.buttonWide]: isSmallScreenSize,
        })}
        onClick={onContactUs}
      >
        {t('pages.PremiumPricing.contactUs.button')}
      </Button>
    </Flex>
  );
};
