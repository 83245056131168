import React, { useCallback, useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDialoguesPricing, useTiersData } from '@utils/Data/Pricing';
import { useCurrentBotId } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { AnnualPricingCallout } from '@components/AnnualPricingCallout';
import { useUnpausePlan } from '@components/DialogsPricing/hooks/useUnpausePlan';
import { useBotProStatus } from '@utils/Data/Bot';
import { sendEvent } from '@utils/Analytics';
import { FAQSection, TierCard, TierCardLoader } from './components';
import { PricingPlanType } from './types';
import { PRICING_PLAN_QUERY_PARAM, getPlanTabs } from './constants';
import { isTierIncluded } from './helpers';
import { usePaymentFlow } from './hooks/usePaymentFlow';
import { useAdminFeatures } from '@utils/Data/Admin';
import {
  RequestDemoForm,
  RequestDemoFormProps,
} from '@components/RequestDemoForm/RequestDemoForm';
import { useRequestDemo } from '@utils/Data/Premium/hooks';
import { Modal } from '@services/index';
import { RequestDemoSubmitDialog } from '@components/RequestDemoForm/RequestDemoSubmitDialog/RequestDemoSubmitDialog';
import { State } from 'react-powerplug';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '../../common/services/RoleService';
import { TierPriceSizeProvider } from './hooks/useTierPriceSize';
import { PageNavigationTabs } from '@ui/PageNavigationTabs';
import { useQueryParams } from '@utils/queryParams';
import { PromoCode } from '@components/PromoCode';
import { useBotPromoCodeQuery } from '@utils/Data/PromoCode/useBotPromoCodeQuery';
import { checkPromoCodeAvailableFreeMonth } from '@utils/Data/PromoCode/checkPromoCodeAvailableFreeMonth';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { TimeLimitedTrialCallout } from './components/TimeLimitedTrialCallout/TimeLimitedTrialCallout';
import { WhatsappTiersList } from './components/WhatsappTiersList/WhatsappTiersList';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { Period, TierType } from '@globals';
import css from './PlansPage.css';

const PlansLoader = () => (
  <>
    {Array.from({ length: 4 }).map((_, index) => (
      <React.Fragment
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {index !== 0 && <Spacer factor={0} horizontalFactor={5} />}
        <TierCardLoader />
      </React.Fragment>
    ))}
  </>
);

export const PlansPage: React.FC<{}> = () => {
  useResetMetaForMobile();
  const { allowed: editAllowed } = useRolePermission({
    domain: 'pro',
    can: Permission.EDIT,
  });

  const { t } = useSafeTranslation();

  const botId = useCurrentBotId()!;
  const { isSmallScreenSize, getValueDueToSize } = useDeviceMedia();

  const { adminFeatures } = useAdminFeatures();
  const { botIsPaused } = useBotProStatus();
  const {
    trialExperiment,
    currentTierTimeLimitedTrial,
    trialExpired: timeLimitedTrialExpired,
    trialStatsData,
  } = useTimeLimitedTrial();
  const { isWhatsappEnabled, loading: whatsappStatusLoading } =
    useWhatsappEnabled();
  const { pricing, pricingLoading } = useDialoguesPricing();
  const { data: botPromoCodeData } = useBotPromoCodeQuery(botId);
  const checkAvailableFreeMonth = useCallback(
    checkPromoCodeAvailableFreeMonth.bind(null, botPromoCodeData),
    [botPromoCodeData],
  );
  const { currency, pricing_plan_id: pricingPlanId } = pricing || {};
  const { tiers, tiersLoading } = useTiersData(botId, pricingPlanId, currency);
  const [requestDemo, requestDemoLoading] = useRequestDemo();

  const currentTierIndex = tiers.findIndex(
    (tier) => tier.type === pricing?.current_tier,
  );
  const currentTier =
    currentTierIndex !== -1 ? tiers[currentTierIndex] : undefined;
  const nextTier = tiers.find((tier) => tier.type === pricing?.next_tier);

  const initialTabId = PricingPlanType.business;
  const { [PRICING_PLAN_QUERY_PARAM]: activeTab = initialTabId } =
    useQueryParams<{ plan: PricingPlanType }>();
  useEffect(() => {
    if (activeTab !== PricingPlanType.business) {
      sendEvent({
        category: 'request-demo',
        action: 'dash_plans_page',
      });
    }
  }, [activeTab]);

  const { unpausePlan, loading: unpausingPlan } = useUnpausePlan(botId, {
    from: 'plans',
    tier: currentTier?.type,
  });

  const { onDowngradeTier, onUpgradeTier, switchingTier, cancelProLoading } =
    usePaymentFlow({
      botId,
      pricing,
      currentTier,
      tiers,
    });

  const handleRequestDemoFormSubmitted: RequestDemoFormProps['onSubmitted'] = (
    state,
    input,
  ) => {
    Modal.show(
      ({ close }) => (
        <State initial={{ formState: state }}>
          {({ state: { formState }, setState }) => (
            <RequestDemoSubmitDialog
              formState={formState}
              setFromState={(state) => setState({ formState: state })}
              onClose={close}
              requestDemoInput={input}
              landing={false}
              mobileAdaptive={isSmallScreenSize}
            />
          )}
        </State>
      ),
      { mobileAdaptive: true },
    );
  };

  const handleUpgradeTier = (tier: TierType, period: Period) => {
    sendEvent({
      category: 'pricing',
      action: 'click upgrade plan',
      label: 'new dialogs pricing',
      propertyBag: {
        botId,
        activeTab,
        currentPlan: currentTier?.type,
        nextPlan: tier,
        currentPeriod: pricing?.current_subscription_period,
        nextPriod: period,
        isFreeMonthByPromoCode: checkAvailableFreeMonth(tier),
      },
    });
    onUpgradeTier(tier, period);
  };

  const loading = tiersLoading || pricingLoading || whatsappStatusLoading;

  return (
    <Flex
      justifyContent="center"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        className={css.container}
      >
        <Spacer factor={getValueDueToSize(6, 10)} />
        <Type as="h1" size="24px" weight="bold" align="center">
          {t('pages.Pricing.title')}
        </Type>
        <Spacer factor={getValueDueToSize(4, 8)} />
        {isWhatsappEnabled ? (
          <WhatsappTiersList
            loading={loading}
            tiers={tiers}
            currency={currency!}
            pricing={pricing}
            onUpgrade={handleUpgradeTier}
            checkAvailableFreeMonth={checkAvailableFreeMonth}
          />
        ) : (
          <>
            {editAllowed && (
              <PageNavigationTabs
                tabs={getPlanTabs()}
                initialTabId={PricingPlanType.business}
                navParam={PRICING_PLAN_QUERY_PARAM}
                tabColor="toggleWhiteOnly"
              />
            )}
            {editAllowed && pricing && (
              <>
                <Spacer factor={4} />
                {currentTierTimeLimitedTrial && timeLimitedTrialExpired ? (
                  <TimeLimitedTrialCallout trialStatsData={trialStatsData} />
                ) : (
                  <AnnualPricingCallout />
                )}
              </>
            )}
            <Spacer factor={6} />
            {activeTab === PricingPlanType.business && (
              <Flex
                style={{
                  overflowX: 'auto',
                  minHeight: '416px',
                  width: 'calc(100% + 32px)',
                  margin: '0 -16px',
                  padding: '0 16px',
                  flex: 'none',
                }}
              >
                {loading ? (
                  <PlansLoader />
                ) : (
                  <TierPriceSizeProvider
                    maxPrice={Math.max(...tiers.map((v) => v.tier_price))}
                  >
                    {tiers
                      /**
                       * не показываем триал тир в списке тиров
                       */
                      .filter((tier) =>
                        trialExperiment ? tier.type !== TierType.trial : true,
                      )
                      .filter((_, index, arr) =>
                        isTierIncluded(index, currentTierIndex, arr.length - 1),
                      )
                      .map((tier, index) => (
                        <React.Fragment key={tier.type}>
                          {index !== 0 && (
                            <Spacer factor={0} horizontalFactor={4} />
                          )}
                          <TierCard
                            discount={
                              botPromoCodeData?.bot.assignedPromoCode?.promoCode
                                .discountPercents
                            }
                            readOnly={!editAllowed}
                            tier={tier}
                            checkAvailableFreeMonth={checkAvailableFreeMonth}
                            currency={currency!}
                            pricing={pricing}
                            selectedTier={currentTier}
                            nextTier={nextTier}
                            botIsPaused={!!botIsPaused}
                            disabledByUnpausing={
                              unpausingPlan || cancelProLoading
                            }
                            disabledBySwitching={
                              switchingTier || cancelProLoading
                            }
                            showYearPrices={adminFeatures?.show_year_prices}
                            onDowngrade={(tier, period) => {
                              sendEvent({
                                category: 'pricing',
                                action: 'click downgrade plan',
                                label: 'new dialogs pricing',
                                propertyBag: {
                                  botId,
                                  activeTab,
                                  currentPlan: currentTier?.type,
                                  nextPlan: tier,
                                  currentPeriod:
                                    pricing?.current_subscription_period,
                                  nextPriod: period,
                                },
                              });
                              onDowngradeTier(tier, period);
                            }}
                            onUnpause={() => {
                              sendEvent({
                                category: 'pricing',
                                action: 'click unpause plan',
                                label: 'new dialogs pricing',
                                propertyBag: {
                                  botId,
                                  activeTab,
                                  plan: currentTier?.type,
                                  period: pricing?.current_subscription_period,
                                },
                              });
                              unpausePlan();
                            }}
                            onUpgrade={handleUpgradeTier}
                          />
                        </React.Fragment>
                      ))}
                  </TierPriceSizeProvider>
                )}
              </Flex>
            )}
            {activeTab === PricingPlanType.enterprise && (
              <RequestDemoForm
                onSubmitted={handleRequestDemoFormSubmitted}
                requestDemoFormValue={requestDemo}
                loading={requestDemoLoading}
              />
            )}
            <Spacer factor={5} />
          </>
        )}
        {activeTab === PricingPlanType.business && (
          <>
            <PromoCode
              botId={botId}
              pricingPlanId={pricingPlanId}
              currency={currency}
            />
            <Spacer factor={5} />
          </>
        )}
        <Spacer factor={5} />
        <Type as="h2" size="24px" weight="semibold" align="center">
          {t('pages.Pricing.FAQ.title')}
        </Type>
        <Spacer />
        {[
          {
            question: t('pages.Pricing.FAQ.faqs.0.question'),
            answer: isWhatsappEnabled
              ? t('pages.Pricing.FAQ.faqs.0_Whatsapp.answer')
              : t('pages.Pricing.FAQ.faqs.0.answer'),
          },
          {
            question: t('pages.Pricing.FAQ.faqs.1.question'),
            answer: t('pages.Pricing.FAQ.faqs.1.answer'),
          },
          {
            question: t('pages.Pricing.FAQ.faqs.2.question'),
            answer: t('pages.Pricing.FAQ.faqs.2.answer'),
          },
          {
            question: t('pages.Pricing.FAQ.faqs.3.question'),
            answer: t('pages.Pricing.FAQ.faqs.3.answer'),
          },
          {
            question: t('pages.Pricing.FAQ.faqs.4.question'),
            answer: t('pages.Pricing.FAQ.faqs.4.answer'),
          },
          {
            question: t('pages.Pricing.FAQ.faqs.5.question'),
            answer: t('pages.Pricing.FAQ.faqs.5.answer'),
          },
        ].map(({ answer, question }, index) => (
          <React.Fragment
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {index !== 0 && <Spacer factor={getValueDueToSize(2, 3)} />}
            <FAQSection
              title={question}
              description={answer}
              onChangeExtended={(extended) => {
                sendEvent({
                  category: 'pricing',
                  action: 'click FAQ item',
                  label: 'new dialogs pricing',
                  propertyBag: { botId, question, extended, activeTab },
                });
              }}
            />
          </React.Fragment>
        ))}
        <Spacer factor={4} horizontalFactor={1} />
      </Flex>
    </Flex>
  );
};
