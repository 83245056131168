import { createNewBlankBotMutation_createBlankBot } from '@common/services/GQLqueries/@types/createNewBlankBotMutation';
import { sendEvent } from '@utils/Analytics';
import { chooseShopifyBots } from './useShopifyBots';
import { ChooseShopifyBots_bots } from './@types/ChooseShopifyBots';

export const sendShopifyEvent = (
  action: string,
  propertyBag?: PureAnalyticsEvent['propertyBag'],
  immediate?: boolean,
) =>
  sendEvent(
    {
      category: 'onboarding',
      label: 'shopify v3',
      action,
      propertyBag,
    },
    immediate,
  );

export const addToShopifyBotListCache = (
  bot: createNewBlankBotMutation_createBlankBot,
) => {
  const data = chooseShopifyBots.read();

  if (!data) {
    return;
  }

  const shopifyBot: ChooseShopifyBots_bots = {
    ...bot,
    paymentInformation: {
      __typename: 'BotPaymentInformation' as const,
      status: null,
    },
    connectedShop: null,
  };

  chooseShopifyBots.write({
    bots: [shopifyBot, ...data.bots],
  });
};
