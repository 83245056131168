import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import css from './WhatsappTierCard.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface WhatsappTierCardProps {
  name: string;
  price: React.ReactNode;
  conversations: string;
  extraConversations: React.ReactNode;
  description: React.ReactNode;
  button: React.ReactNode;
  badge?: React.ReactNode;
  hideFrom?: Boolean;
}

export const WhatsappTierCardLoader = () => {
  const { isSmallScreenSize } = useDeviceMedia();
  return (
    <LoadingPlaceholder
      className={css.container}
      color="white"
      height={412}
      width={isSmallScreenSize ? 260 : 320}
    />
  );
};

export const WhatsappTierCard: React.FC<WhatsappTierCardProps> = ({
  name,
  price,
  description,
  conversations,
  extraConversations,
  button,
  badge,
  hideFrom,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      data-testid="plans-page__tier-card"
      className={css.container}
    >
      <Type
        weight="semibold"
        size="18px"
        data-testid="plans-page__tier-card__tier-name"
      >
        {name}
      </Type>
      <Spacer factor={1} />
      {badge ?? <Spacer factor={6} />}
      {!hideFrom && (
        <>
          <Spacer factor={3} />
          <Type>{t('pages.Pricing.whatsapp.from')}</Type>
        </>
      )}
      {price}
      <Type>{description}</Type>
      <Spacer factor={5} />
      <Flex
        fullWidth
        justifyContent="space-between"
        className={css.conversations}
      >
        <Type whiteSpace="pre">
          {t('pages.Pricing.whatsapp.conversations')}
        </Type>
        <Type>{conversations}</Type>
      </Flex>
      <Spacer factor={3} />
      <Flex fullWidth justifyContent="space-between">
        <Type size="12px" color="greyDark">
          {t('pages.Pricing.whatsapp.extraConversation')}
        </Type>
        <Type size="12px" color="greyDark">
          {extraConversations}
        </Type>
      </Flex>
      <Spacer factor={7} />
      {button}
    </Flex>
  );
};
