import React, { useState } from 'react';
import cn from 'classnames';

import { IFeature } from '../../../constants/premiumData';
import { Button, ButtonIntentKey } from '../../../modern-ui/Button';
import { CheckBox } from '../../../modern-ui/CheckBox';
import { Flex } from '../../../modern-ui/Flex';
import { Type } from '../../../modern-ui/Type';
import { Spacer } from '../../../modern-ui/Spacer';

import { Feature } from './Feature';
import { PaymentInterval, paymentIntervalToString } from './paymentInterval';

import * as css from './Offer.css';

export const DEFAULT_PREMIUM_PRICE = '0';

export interface IOffer {
  title: string;
  banner?: React.ReactNode;
  hasBanner?: boolean;
  price: number | string;
  description: React.ReactNode;
  features: IFeature[];
  showTerms?: boolean;
  buttonText: string;
  buttonIntent?: ButtonIntentKey;
  info?: React.ReactNode;
  buttonClass?: string;
  finalPrice?: boolean;
  buttonAction?: () => void;
  interval: PaymentInterval;
  premium?: boolean;
  premiumAccount?: boolean;
}

interface OfferProps {
  offer: IOffer;
  highlightSyncClone: boolean;
}

export const Offer: React.FC<OfferProps> = ({ offer, highlightSyncClone }) => {
  const [termsChecked, setTermsChecked] = useState(true);
  const disabled = offer.showTerms && !termsChecked;

  return (
    <div className={css.offer}>
      <Type size="24px" weight="light">
        {offer.title}
      </Type>
      <Spacer factor={2} />
      <div className={css.description}>{offer.description}</div>
      {offer.hasBanner && <div className={css.banner}>{offer.banner}</div>}
      {offer.info ?? (
        <>
          <div className={css.price}>
            <div className={css.startsFrom}>
              {offer.price === DEFAULT_PREMIUM_PRICE || offer.finalPrice
                ? ''
                : 'starts from'}
            </div>
            <div>
              {!offer.premium || offer.price !== DEFAULT_PREMIUM_PRICE ? (
                <span className={css.priceSum}>{offer.price}</span>
              ) : (
                <span className={css.priceSum}>
                  {window.i18next.t('Offer-JSXText--333-lets-talk')}
                </span>
              )}
              {offer.price !== DEFAULT_PREMIUM_PRICE && (
                <span className={css.pricePeriod}>
                  {`/${paymentIntervalToString()[offer.interval]}`}
                </span>
              )}
            </div>
          </div>
          {offer.premium && offer.premiumAccount ? (
            <Flex alignItems="center" className={css.premiumButtonPlaceholder}>
              <span className={css.premiumButtonPlaceholderText}>
                {window.i18next.t(
                  'Offer-JSXText-2068-manage-your-premium-status',
                )}
                <br />
                {window.i18next.t('Offer-JSXText--160-in-profile-menu')}
              </span>
            </Flex>
          ) : (
            <Button
              onClick={offer.buttonAction}
              intent={offer.buttonIntent || 'primary'}
              disabled={disabled || !offer.buttonAction}
              className={cn(
                {
                  [css.pulseButton]: highlightSyncClone && offer.premium,
                },
                offer.buttonClass,
              )}
            >
              {offer.buttonText}
            </Button>
          )}
          <div className={css.terms}>
            {offer.showTerms && (
              <React.Fragment>
                <CheckBox
                  checked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                />
                <span className={css.termsText}>
                  <span>{window.i18next.t('Offer-JSXText--424-i-accept')}</span>
                  <a
                    href="https://chatfuel.com/files/TermsOfUse.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.termsLink}
                  >
                    {window.i18next.t('Offer-JSXText--135-terms-of-service')}
                  </a>
                </span>
              </React.Fragment>
            )}
          </div>
        </>
      )}
      <div className={css.featuresList}>
        {offer.features.map((feature: IFeature) => (
          <Feature feature={feature} key={feature.title} highlight={false} />
        ))}
      </div>
    </div>
  );
};
