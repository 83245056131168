import React, { useEffect } from 'react';
import {
  BillingPeriodDialog,
  BillingPeriodDialogProps,
} from './BillingPeriodDialog';
import { useCurrentBotId } from '@utils/Routing';
import { useAdminFeatures } from '@utils/Data/Admin';
import { usePricingPeriodList } from '@utils/Data/Pricing/hooks';
import { sendEvent } from '@utils/Analytics';
import { useBotPromoCodeQuery } from '@utils/Data/PromoCode/useBotPromoCodeQuery';
import { checkPromoCodeAvailableFreeMonth } from '@utils/Data/PromoCode/checkPromoCodeAvailableFreeMonth';

interface PageBillingDialogProps
  extends Omit<BillingPeriodDialogProps, 'loading' | 'pricePeriodList'> {}

export const PageBillingPeriodDialog: React.FC<PageBillingDialogProps> = ({
  paymentIntent,
  actionType,
  close,
  currency,
  onUpdate,
  tierType,
  isCurrentTierSelected,
  pricing,
  initialPeriod,
  disabledItems,
  replaceFooter,
}) => {
  const botId = useCurrentBotId()!;

  const { adminFeatures } = useAdminFeatures();
  const { data: botPromoCodeData } = useBotPromoCodeQuery(botId);
  const isAvailableFreeMonth = checkPromoCodeAvailableFreeMonth(
    botPromoCodeData,
    tierType,
  );
  const isYearPrices = adminFeatures?.show_year_prices;

  const { data, loading } = usePricingPeriodList({
    botId,
    currency,
    tierType,
    pricingPlanId: pricing?.pricing_plan_id,
    /**
     * after applying a promotional code or any other discount,
     * as well as after switching to a particular tariff,
     * we must request a new value.
     */
    fetchPolicy: 'network-only',
  });

  const currentPeriod = pricing?.current_subscription_period;

  useEffect(() => {
    sendEvent({
      category: 'pricing',
      action: 'open billing period dialog',
      label: 'new dialogs pricing',
      propertyBag: {
        botId,
        currentPeriod,
        currentPlan: tierType,
        isShowYearPrices: isYearPrices,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BillingPeriodDialog
      paymentIntent={paymentIntent}
      actionType={actionType}
      close={close}
      discount={
        botPromoCodeData?.bot.assignedPromoCode?.promoCode.discountPercents
      }
      pricing={pricing}
      tierType={tierType}
      currency={currency}
      isCurrentTierSelected={isCurrentTierSelected}
      loading={loading}
      onUpdate={onUpdate}
      pricePeriodList={data}
      initialPeriod={initialPeriod}
      isYearPrices={isYearPrices}
      isAvailableFreeMonth={isAvailableFreeMonth}
      disabledItems={disabledItems}
      replaceFooter={replaceFooter}
    />
  );
};
