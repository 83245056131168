import { TierType } from '@globals';

export enum PricingPlanType {
  business = 'business',
  enterprise = 'enterprise',
}

export const TierTypeRegular = {
  [TierType.trial]: TierType.trial,
  [TierType.entrepreneur]: TierType.entrepreneur,
  [TierType.startup]: TierType.startup,
  [TierType.smallBusiness]: TierType.smallBusiness,
  [TierType.growingBusiness]: TierType.growingBusiness,
  [TierType.establishedBusiness]: TierType.establishedBusiness,
  [TierType.manual]: TierType.manual,
} as const;
