import React from 'react';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as MagnifyerIcon } from './images/magnifyer.svg';
import * as css from './ShopifyChooseBotEmptyState.css';

export const ShopifyChooseBotEmptyState = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex alignItems="center" justifyContent="center" className={css.wrapper}>
      <Flex
        alignItems="center"
        flexDirection="column"
        className={css.container}
      >
        <MagnifyerIcon />
        <Spacer factor={4} />
        <Type as="h1" weight="medium" color="baseTertiary" align="center">
          {t('ShopifyOnboarding.chooseBot.noBots')}
        </Type>
      </Flex>
    </Flex>
  );
};
