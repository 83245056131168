import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { getMonthsCount } from '@components/DialogsPricing/utils/getMonthsCount';
import { TierCardLabelProps } from '../types';
import css from './TierCardLabel.css';
import { formatPricingDate } from '@utils/DateTime/formatDate';

export const TierCardLabel: React.FC<TierCardLabelProps> = ({
  isCurrentTierSelected,
  isStartsAfterTrial,
  botIsPaused,
  isCurrentTierNext,
  pricing,
  nextTier,
}) => {
  const { t } = useSafeTranslation();
  const dataTestId = 'plans-page__tier-card__tier-card-label';

  if (isCurrentTierSelected && botIsPaused)
    return (
      <Type
        size="12px"
        color="white"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {t('pages.Pricing.businessCard.paused')}
        <Tooltip2 content={t('pages.Pricing.businessCard.yourPlanPause')}>
          {(ref, bind) => (
            <Icon color="white" icon="info" size="20px" ref={ref} {...bind} />
          )}
        </Tooltip2>
      </Type>
    );

  if (isCurrentTierSelected && !isCurrentTierNext && Boolean(nextTier))
    return (
      <Type
        size="12px"
        color="black"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {t('pages.Pricing.businessCard.current')}
      </Type>
    );

  if (isCurrentTierSelected) {
    return (
      <Type
        size="12px"
        color="white"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {t('pages.Pricing.businessCard.currentMonth', {
          count: getMonthsCount(pricing?.current_subscription_period ?? null),
        })}
      </Type>
    );
  }

  if (isCurrentTierNext && isStartsAfterTrial) {
    return (
      <Type
        size="12px"
        color="white"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {t('pages.Billing.dialogues.startsAfterTrial')}
      </Type>
    );
  }

  if (isCurrentTierNext && pricing?.next_prepayment_date) {
    return (
      <Type
        size="12px"
        color="white"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {`${t('pages.Pricing.businessCard.startAt')} ${formatPricingDate(
          pricing.next_prepayment_date,
        )}`}
      </Type>
    );
  }

  return <div className={css.root} />;
};
