import React from 'react';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import Maybe from 'graphql/tsutils/Maybe';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import css from './BillingPeriodDialog.css';

interface InfoTooltipProps {
  isAdditionalDiscount: boolean;
  isPriceLess: boolean;
  discountMonthsLeft: Maybe<number>;
  isAvailableFreeMonth?: boolean;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  isAdditionalDiscount,
  isPriceLess,
  discountMonthsLeft,
  isAvailableFreeMonth,
}) => {
  const { t } = useSafeTranslation();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const content = isAvailableFreeMonth ? (
    <>
      {!isWhatsappEnabled && (
        <>
          {t('PromoCode.firstMonthDiscountAvailable')}
          <br />
          <br />
        </>
      )}
      {t('PromoCode.youCanApplyOnePromoCodePer', {
        type: t(isWhatsappEnabled ? 'PromoCode.phoneNumber' : 'PromoCode.page'),
      })}
    </>
  ) : (
    <>
      {isAdditionalDiscount && discountMonthsLeft && (
        <Type whiteSpace="pre-line" size="12px" color="white">
          {t('pages.Billing.periodDialog.discount', {
            count: discountMonthsLeft,
          })}
        </Type>
      )}
      {isAdditionalDiscount && isPriceLess && <Spacer factor={4} />}
      {isPriceLess && t('pages.Billing.periodDialog.youOnlyPay')}
    </>
  );

  return isAdditionalDiscount || isPriceLess || isAvailableFreeMonth ? (
    <Tooltip2 placement="right" boundariesElement="viewport" content={content}>
      {(ref, bind) => (
        <Icon
          ref={ref}
          icon="info"
          size="28px"
          color="black"
          className={css.infoIcon}
          {...bind}
        />
      )}
    </Tooltip2>
  ) : null;
};
