import React, { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ExecutionResult } from '@apollo/react-common';
import { submitPaymentEvent } from 'cf-common/src/conversionTracking';
import { PremiumPaymentDialog } from '@components/Payments';
import {
  PaymentFailureDialog,
  UpgradedPricingDialog,
  DowngradedPricingDialog,
} from '@components/DialogsPricing/dialogs';
import { sendEvent } from '@utils/Analytics';
import {
  PremiumTier,
  useSwitchPremiumPricingTierMutation,
} from '@utils/Data/Pricing';
import { useUpdatePremiumPaymentDialog } from '@components/DialogsPricing/hooks';
import { Modal } from '@services/index';
import { useDeepLinkCleanup } from '@pages/DeepLinks/helpers';
import { DeepLinksMode, DeepLinksQueryParam } from '../../DeepLinks/types';
import { isSomeEnum } from '@utils/isSomeEnum';
import { getPremiumPlansPageUrl } from '@utils/Routing';
import { PREMIUM_PRICING_INFO_QUERY } from '@utils/Data/Pricing/queries';
import { Period, TierType, PaymentDetailsStatus } from '@globals';
import { SwitchPremiumPricingTierMutation } from '@utils/Data/Pricing/@types/SwitchPremiumPricingTierMutation';
import { PremiumPricingInfoQuery_premiumPricingInfo } from '@utils/Data/Pricing/@types/PremiumPricingInfoQuery';
import { updateWorkspaceAvailableCache } from '@utils/Data/Workspaces/updateWorkspaceAvailableCache';
import {
  PaymentFlowError,
  PaymentFlowErrorKind,
} from 'cf-common/src/utils/Stripe/errors';
import { useAdminId } from '@utils/Admin/useAdminId';

interface PremiumPaymentFlowConfig {
  tiers: PremiumTier[];
  currentTier?: PremiumTier;
  pricing?: PremiumPricingInfoQuery_premiumPricingInfo;
}

export const usePremiumPaymentFlow = ({
  currentTier,
  tiers,
  pricing,
}: PremiumPaymentFlowConfig) => {
  const location = useLocation();
  const history = useHistory();
  const { deepLinkCleanup } = useDeepLinkCleanup();
  const { id: adminId } = useAdminId();

  const showPaymentUpdateDialog = useUpdatePremiumPaymentDialog();

  const { switchPricingTierMutation, handleMutationError, switchingTier } =
    useSwitchPremiumPricingTierMutation({
      onSwitchingCompleted(data) {
        const tier = data.switchPremiumPricingTier;
        submitPaymentEvent(
          {
            currency: tier.currency,
            tierName: tier.next_tier ?? tier.current_tier!,
            value: tier.invoice_total!,
          },
          adminId!,
        );
      },
    });

  const switchTier = useCallback(
    async (
      nextTier: TierType,
      subscriptionPeriod: Period,
      paymentMethodId?: string,
    ) => {
      const prevTier = pricing?.next_tier
        ? tiers.find((t) => t.type === pricing?.next_tier)
        : currentTier;
      let res: ExecutionResult<SwitchPremiumPricingTierMutation> | undefined;
      try {
        res = await switchPricingTierMutation({
          variables: { nextTier, subscriptionPeriod, paymentMethodId },
          refetchQueries: [{ query: PREMIUM_PRICING_INFO_QUERY }],
        });
      } catch (error) {
        handleMutationError(error as any);
        return;
      }

      const { data, errors } = res;

      if (errors?.length || !data?.switchPremiumPricingTier) {
        return;
      }

      const { stripe_error } = data.switchPremiumPricingTier;
      if (stripe_error) {
        const error = new PaymentFlowError(stripe_error.description, {
          kind: PaymentFlowErrorKind.stripeDecline,
          declineCode: stripe_error.decline_code || stripe_error.code,
        });
        Modal.show(({ close, resolve }) => (
          <PaymentFailureDialog
            error={error.errorData}
            onTryAgain={() => {
              sendEvent({
                category: 'premium pricing',
                action: 'click try new payment card',
                label: 'new workspace pricing',
                propertyBag: {
                  error: error.errorData,
                  currentPlan: prevTier?.type,
                  nextPlan: nextTier,
                },
              });
              close();
              resolve();
            }}
            onDismiss={() => {
              sendEvent({
                category: 'premium pricing',
                action: 'click dismiss on try new payment card',
                label: 'new workspace pricing',
                propertyBag: { error: error.errorData },
              });
              close();
            }}
          />
        ))?.then(() =>
          showPaymentUpdateDialog((paymentMethodId) =>
            switchTier(nextTier, subscriptionPeriod, paymentMethodId),
          ),
        );
        return;
      }

      const newPricing = data.switchPremiumPricingTier;
      const newNextTierType = newPricing.next_tier ?? newPricing.current_tier;
      const newNextTier = tiers.find((t) => t.type === newNextTierType);

      if (prevTier && newNextTier) {
        if (prevTier.limit < newNextTier.limit) {
          Modal.show(({ close }) => (
            <UpgradedPricingDialog
              newNextTierType={newNextTier.type as TierType}
              prevTierType={prevTier.type as TierType}
              prevPeriod={pricing?.current_subscription_period!}
              newPeriod={subscriptionPeriod}
              newPricing={newPricing}
              close={() => {
                sendEvent({
                  category: 'premium pricing',
                  action: 'click close after switch plan',
                  label: 'new workspace pricing',
                  propertyBag: { plan: newNextTier.type },
                });
                close();
              }}
            />
          ));
        } else {
          Modal.show(({ close }) => (
            <DowngradedPricingDialog
              newNextTierType={newNextTier.type as TierType}
              prevTierType={prevTier.type as TierType}
              newPricing={newPricing}
              close={() => {
                sendEvent({
                  category: 'premium pricing',
                  action: 'click close after downgrade plan',
                  label: 'new workspace pricing',
                  propertyBag: { newPlan: newNextTier.price },
                });
                close();
              }}
            />
          ));
        }
      } else if (/** first update */ !prevTier && newNextTier) {
        updateWorkspaceAvailableCache(true);
        history.push('/bots');
      }
    },
    [
      history,
      pricing,
      tiers,
      currentTier,
      switchPricingTierMutation,
      handleMutationError,
      showPaymentUpdateDialog,
    ],
  );

  const switchTierOrUpdatePaymentMethod = useCallback(
    (nextTier: TierType, subscriptionPeriod: Period) => {
      if (!pricing) return;
      if (
        pricing.status === PaymentDetailsStatus.trial &&
        !pricing.hasPaymentMethod
      ) {
        Modal.show(
          ({ close, resolve, reject }) => (
            <PremiumPaymentDialog
              onSuccess={resolve}
              onClose={close}
              renderPaymentError={(props) => (
                <PaymentFailureDialog
                  {...props}
                  onTryAgain={() => {
                    // TODO: send event
                    close();
                    reject();
                  }}
                />
              )}
              initialPayment
              setupPayment={(paymentMethodId) =>
                switchTier(nextTier, subscriptionPeriod, paymentMethodId)
              }
            />
          ),
          {
            onOverlayDismiss: deepLinkCleanup,
            id: 'ShowPaymentDialog',
          },
        )
          ?.onClose(deepLinkCleanup)
          .catch(() => {
            switchTierOrUpdatePaymentMethod(nextTier, subscriptionPeriod);
          });
      } else if (!pricing.hasPaymentMethod || pricing.hasDebt) {
        showPaymentUpdateDialog((paymentMethodId) =>
          switchTier(nextTier, subscriptionPeriod, paymentMethodId),
        );
      } else {
        switchTier(nextTier, subscriptionPeriod);
      }
    },
    [pricing, deepLinkCleanup, showPaymentUpdateDialog, switchTier],
  );

  // Deeplinks entry point. Ex. /go/premium-plans, /go/premium-plans?dlTierId=startup&dlTierPeriod=monthly
  useEffect(() => {
    if (tiers.length) {
      const queryParams = new URLSearchParams(location.search);
      const nextTierId = queryParams.get(DeepLinksQueryParam.tierId);
      const nextSubscriptionPeriod = queryParams.get(
        DeepLinksQueryParam.tierPeriod,
      );
      if (
        queryParams.get(DeepLinksQueryParam.mode) ===
          DeepLinksMode.premiumPlans &&
        isSomeEnum(TierType, nextTierId)
      ) {
        sendEvent({
          category: 'premium pricing',
          action: 'open plans via deeplink',
          label: 'new workspace pricing',
          propertyBag: {
            plan: nextTierId,
            period: nextSubscriptionPeriod,
          },
        });

        history.push(
          getPremiumPlansPageUrl({
            tier: nextTierId,
            period: isSomeEnum(Period, nextSubscriptionPeriod)
              ? nextSubscriptionPeriod
              : undefined,
          }),
        );
      }
    }
  }, [location.search, tiers, history]);

  return {
    onDowngradeTier: switchTier,
    onUpgradeTier: switchTierOrUpdatePaymentMethod,
    switchingTier,
  };
};
