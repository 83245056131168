import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ChooseShopifyBots } from './@types/ChooseShopifyBots';
import { useMemo } from 'react';
import { ShopifyBot } from './ShopifyChooseBotList';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import { Platform } from '@globals';
import { GraphqlCacheManager } from '@utils/GQL/GraphqlCacheManager';
import client from '@common/services/ApolloService';

export const CHOOSE_SHOPIFY_BOTS = gql`
  query ChooseShopifyBots {
    bots {
      id
      title
      allowedPlatforms
      workspace_id
      connectedShop {
        id
      }
      status {
        page
        page_info {
          id
          title
          picture
          trial_end_date
          whatsapp_business_account {
            id
            name
          }
          whatsapp_phone {
            id
            display_phone_number
          }
        }
      }
      paymentInformation {
        status
      }
    }
  }
`;

export const chooseShopifyBots = new GraphqlCacheManager<ChooseShopifyBots>(
  client,
  CHOOSE_SHOPIFY_BOTS,
);

export const useShopifyBots = () => {
  const { data, ...queryResult } =
    useQuery<ChooseShopifyBots>(CHOOSE_SHOPIFY_BOTS);

  const bots: Array<ShopifyBot> = useMemo(() => {
    return (data?.bots || [])
      .filter(
        (bot) =>
          testAllowedPlatforms(
            [Platform.whatsapp],
            bot.allowedPlatforms || [],
          ) &&
          !bot.connectedShop &&
          !['active', 'forbidden', 'to_be_cancelled'].includes(
            bot.paymentInformation?.status || '',
          ) &&
          !bot.workspace_id,
      )

      .map((bot) => ({
        id: bot.id,
        title: bot.title,
        pageInfo: bot.status?.page_info,
        statusPage: bot.status?.page,
      }));
  }, [data]);

  return { bots, allBots: data?.bots, ...queryResult };
};
