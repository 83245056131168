import ng from 'angular';
import { ngDeprecateMessage } from '@components/DeprecateMessage/ngDeprecateMessage';
import { ngAgencyLinkWhiteLabel } from './WhiteLabel/AgencyWhiteLabel/AgencyLinkWhiteLabel/ngAgencyLinkWhiteLabel';
import { ngAbandonedCartSetup } from './GrowPage/AbandonedCart';
import { ngConfigureTabWrapper } from './BotPage/ConfigureTab';
import { ngPeopleTabWrapper } from './BotPage/PeopleTab';
import {
  ngAutomateNullTabWrapper,
  ngAutomateTabWrapper,
} from './BotPage/AutomateTab';
import { ngReEngageAsideWrapper } from './BotPage/ReEngageTab';
import {
  ngReEngageNullTabWrapper,
  ngReEngageSentTabWrapper,
  ngReEngageTabWrapper,
} from './BotPage/ReEngageTab/ReEngageTab';
import { ngAnalyzeTabWrapper } from './BotPage/AnalyzeTab';
import { ngHomeTabWrapper } from './AquireUsersFromComments';
import { ngCommentsAutoreplyPopup } from './GrowPage/CommentsAutoreply';
import { ngCommentsAutoreplySwitchToFlowsPopup } from './GrowPage/CommentsAutoreplySwitchToFlows/ngCommentsAutoreplyPopup';

export default ng.module('app.pages', [
  ngDeprecateMessage,
  ngAgencyLinkWhiteLabel,
  ngAbandonedCartSetup,
  ngConfigureTabWrapper,
  ngPeopleTabWrapper,
  ngAutomateTabWrapper,
  ngAutomateNullTabWrapper,
  ngReEngageAsideWrapper,
  ngReEngageTabWrapper,
  ngReEngageNullTabWrapper,
  ngReEngageSentTabWrapper,
  ngAnalyzeTabWrapper,
  ngHomeTabWrapper,
  ngCommentsAutoreplyPopup,
  ngCommentsAutoreplySwitchToFlowsPopup,
]).name;
