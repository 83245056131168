import React, { useCallback, useEffect } from 'react';
import { log } from 'cf-common/src/logger';
import { useHistory, useLocation } from 'react-router-dom';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Divider } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { CenteredLoader } from '@ui/Loader';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import { useCreateBlankBot } from '@utils/Data/Bot/useCreateBlankBot';
import { ShopifyBot, ShopifyChooseBotList } from './ShopifyChooseBotList';
import { ShopifyChooseBotEmptyState } from './ShopifyChooseBotEmptyState';
import { ShopifyChooseBotPage } from './ShopifyChooseBotPage';
import { useShopifyChooseBotRedirect } from './useShopifyChooseBotRedirect';
import { useShopifyBots } from './useShopifyBots';
import { addToShopifyBotListCache, sendShopifyEvent } from './utils';
import { useConfirmShopifyConnection } from '@utils/Integrations/Shopify';
import { SHOPIFY_ONBOARDING } from './useShopifyOnboardingState';
import * as css from './ShopifyChooseBot.css';
import { useAdminId } from '@utils/Admin/useAdminId';
import { sendEvent } from '@utils/Analytics';

export const ShopifyChooseBot = () => {
  const history = useHistory();
  const { id: userId } = useAdminId();
  const { bots, allBots, loading: botListLoading, refetch } = useShopifyBots();
  const { t } = useSafeTranslation();
  const location = useLocation();
  const { loading } = useShopifyChooseBotRedirect();
  const [onCreateBlankBot, { loading: blankBotLoading }] = useCreateBlankBot(
    { bots },
    /**
     * В случае шопифая приходится обновлять кеш так, а не добавлять эту функцию
     * в `update` хука внутри, так как из-за порядка вызова функций будет меняться поведение.
     * Если эту функцию расположить первой, то будет создаваться 2 бота, если последней, то
     * будет падать ошибка
     */
    (newBot) => addToShopifyBotListCache(newBot),
  );
  const [confirmShopifyConnection, { loading: isConnecting }] =
    useConfirmShopifyConnection();

  const onCreateNewBotClick = useCallback(() => {
    sendShopifyEvent('create bot click');
    onCreateBlankBot(null, [Platform.whatsapp]);
  }, [onCreateBlankBot]);

  const onRefetchClick = useCallback(() => {
    sendShopifyEvent('refetch bots click');
    refetch();
  }, [refetch]);

  const onConnectClick = useCallback(
    (bot: ShopifyBot) => {
      sendShopifyEvent('connect bot click', { bot });
      const urlParams = new URLSearchParams(location.search);
      urlParams.delete(SHOPIFY_ONBOARDING);

      const shop = urlParams.get('shop');
      const authCode = urlParams.get('code');
      const queryString = urlParams.toString().replace(/^\?/, '');
      const stateKey = urlParams.get('state');

      if (!shop || !authCode || !stateKey) {
        log.error({
          msg: 'There is no required url params',
          data: { shop, authCode, stateKey },
        });
        history.push('/oauth/shopify/error');
        return;
      }

      confirmShopifyConnection({
        shop,
        authCode,
        queryString,
        stateKey,
        botId: bot.id,
        allBots,
      });
    },
    [allBots, confirmShopifyConnection, history, location.search],
  );

  useEffect(() => {
    const domain = new URLSearchParams(location.search)?.get('shop');
    if (!userId || !domain) {
      return;
    }
    sendEvent({
      category: 'shopify store',
      action: 'install app',
      label: 'shopify admin',
      propertyBag: {
        shopify_store_id: domain,
        shopify_shop_domain: domain,
        user_id: userId,
        platform: Platform.whatsapp,
      },
    });
  }, [location.search, userId]);

  if (botListLoading || loading) {
    return <CenteredLoader />;
  }

  const noBots = bots.length === 0;

  return (
    <ShopifyChooseBotPage
      disabled={noBots}
      footerView={
        <>
          <Divider className={css.divider} />

          <Spacer factor={4} />

          <Flex justifyContent="space-between" className={css.buttonWrapper}>
            <Button
              data-testid="shopify-choose-bot__create-new-bot"
              disabled={blankBotLoading}
              intent={noBots ? 'primary' : 'secondary'}
              onClick={() => onCreateNewBotClick()}
            >
              {t('ShopifyOnboarding.chooseBot.createNewBot')}
            </Button>

            <Button
              data-testid="shopify-choose-bot__refresh"
              intent="secondary"
              icon={<Icon icon="refresh2" size="16px" />}
              onClick={() => onRefetchClick()}
            >
              {t('ShopifyOnboarding.chooseBot.refreshBotList')}
            </Button>
          </Flex>
        </>
      }
    >
      {noBots ? (
        <ShopifyChooseBotEmptyState />
      ) : (
        <ShopifyChooseBotList
          bots={bots}
          isConnecting={isConnecting}
          onConnectClick={onConnectClick}
        />
      )}
    </ShopifyChooseBotPage>
  );
};
