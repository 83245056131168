import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { pathOr } from 'ramda';
import gql from 'graphql-tag';
import cn from 'classnames';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { formatPricePromo } from '@utils/Format/priceFormatter';
import { useBotFeatures } from '@utils/Data/Bot';
import { getPlansPageUrl, useCurrentBotId } from '@utils/Routing';
import { FeatureType, getFeatures } from '../../constants/premiumData';
import { Button } from '../../modern-ui/Button';
import { UPGRADE_PAGE_FROM_GROUP_CONTEXT_MENU_PARAM } from '../../modern-components/Aside/Group/GroupContextMenu';
import { ProPlanManagementLite } from '../../modern-components/ProPlanSection/components/ProPlanManagementLite';

import {
  PaymentInterval,
  paymentIntervalToEnum,
} from './components/paymentInterval';
import { IOffer, Offer, DEFAULT_PREMIUM_PRICE } from './components/Offer';

import {
  botUpgradePageQuery as BotUpgradePageQuery,
  botUpgradePageQuery_bot as Bot,
  botUpgradePageQuery_me as Admin,
  botUpgradePageQueryVariables as BotUpgradePageQueryVariables,
} from './@types/botUpgradePageQuery';

import * as css from './UpgradePage.css';
import { sendEvent } from '../../utils/Analytics';
import { DeepLinksMode, DeepLinksQueryParam } from '../DeepLinks/types';

const BOT_UPGRADE_PAGE_QUERY = gql`
  query botUpgradePageQuery($botId: String) {
    me {
      id
      premium_account_id
      premiumAccount {
        id
        plan_info {
          id
          amount
          interval
        }
      }
    }
    bot(id: $botId) {
      id
      pricingData {
        currency
        currentPrice
        currentUsers
        currentMaxUsers
        pricing
        reached_limit
      }
      status {
        status
        page_info {
          id
          title
          picture
        }
      }
      pro {
        status
      }
      limits {
        usersLimit
      }
      user_limit_reached
    }
  }
`;

interface BotPageProps {
  bot: Bot;
}

const BotPage: React.FC<BotPageProps> = ({ bot }) => {
  const title = pathOr('', ['status', 'page_info', 'title'], bot);
  const picture = pathOr('', ['status', 'page_info', 'picture'], bot);

  return (
    <>
      <div
        style={{ backgroundImage: `url(${picture})` }}
        className={css.botPageIcon}
      />
      <span className={cn(css.botPageTitle, css.sectionHeader)}>{title}</span>
    </>
  );
};

const sendUpgradeToProEvent = () => {
  sendEvent({
    category: 'upgrade tab',
    action: 'click upgrade to pro',
    propertyBag: {
      'new flow': 'true',
    },
  });
};

export const UpgradePage: React.FC = () => {
  const { t } = useSafeTranslation();
  const [blinkSyncClone, setBlinkSyncClone] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const blinkSyncCloneTimeout = React.useRef<ReturnType<typeof setTimeout>>();
  const botId = useCurrentBotId();
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId ?? '');
  const { loading, error, data } = useQuery<
    BotUpgradePageQuery,
    BotUpgradePageQueryVariables
  >(BOT_UPGRADE_PAGE_QUERY, {
    variables: { botId },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const from = params.get('from');

    const isFromGroupContextMenu =
      from === UPGRADE_PAGE_FROM_GROUP_CONTEXT_MENU_PARAM;

    if (isFromGroupContextMenu) {
      setBlinkSyncClone(true);
      blinkSyncCloneTimeout.current = setTimeout(
        () => setBlinkSyncClone(false),
        2000,
      );
    }
    return () => {
      if (blinkSyncCloneTimeout.current) {
        window.clearTimeout(blinkSyncCloneTimeout.current);
      }
    };
  }, [location.search]);

  if (!botId || botFeaturesLoading) {
    return null;
  }

  if (botFeatures?.dialogs_pricing_enabled) {
    return <Redirect to={getPlansPageUrl(botId, true)} />;
  }

  const getOffers = (
    admin: Admin,
    currentPrice: number,
    usersLimit: number,
    currency?: string,
  ): IOffer[] => {
    return [
      {
        title: t('UpgradePage-string-8107-trial'),
        description: t(
          'UpgradePage-string--720-get-your-business-up-and-running',
        ),
        hasBanner: false,
        price: formatPricePromo(0, { currency }),
        interval: PaymentInterval.month,
        features: getFeatures(FeatureType.free, usersLimit, t),
        buttonText: t('UpgradePage-string-1573-current-plan'),
        buttonIntent: 'primary',
        finalPrice: true,
      },
      {
        title: t('UpgradePage-string-8052-pro'),
        description: t(
          'UpgradePage-string-1054-most-powerful-features-to-level-up-with',
        ),
        hasBanner: false,
        price: formatPricePromo(currentPrice > 0 ? currentPrice : 15, {
          currency,
        }),
        interval: PaymentInterval.month,
        features: getFeatures(FeatureType.pro, usersLimit, t),
        showTerms: true,
        info: data?.bot ? (
          <ProPlanManagementLite
            bot={data.bot}
            onGoPro={() => {
              sendUpgradeToProEvent();
              history.push(
                `/bot/${botId}/settings?${DeepLinksQueryParam.mode}=${DeepLinksMode.upgrade}`,
              );
            }}
          />
        ) : null,
        buttonText: t('UpgradePage-string-1727-upgrade-to-pro'),
        finalPrice: currentPrice > 0,
        buttonAction: () => {
          sendEvent({
            category: 'upgrade tab',
            action: 'click upgrade to pro',
          });
          history.push(
            `/bot/${botId}/settings?${DeepLinksQueryParam.mode}=${DeepLinksMode.upgrade}`,
          );
        },
        buttonClass: 'test-popup-upgrade-to-pro-button',
      },
      {
        title: t('UpgradePage-string-1346-premium'),
        description: t(
          'UpgradePage-string--101-premium-service-and-expert-guidance-to-get-results-fast',
        ),
        hasBanner: false,
        price: admin.premiumAccount
          ? formatPricePromo(admin.premiumAccount.plan_info.amount / 100)
          : DEFAULT_PREMIUM_PRICE,
        interval: admin.premiumAccount
          ? paymentIntervalToEnum(admin.premiumAccount.plan_info.interval)
          : PaymentInterval.month,
        features: getFeatures(FeatureType.premium, usersLimit, t),
        buttonText: t('UpgradePage-string-2133-contact-us'),
        buttonIntent: 'primary',
        finalPrice: !!(
          admin.premiumAccount && admin.premiumAccount.plan_info.amount
        ),
        buttonAction: () => {
          sendEvent({
            category: 'upgrade tab',
            action: 'contact us premium',
          });
          sendEvent({
            category: 'request-demo',
            action: 'dash_contact_us_tab',
          });
          window.open('https://tiny.cc/chatfueldemo', '_blank');
        },
        premium: true,
        premiumAccount: !!admin.premium_account_id,
      },
    ];
  };

  if (!data || loading || error) {
    return <div className={css.pageWrapper} />;
  }

  const hasPage = !!data.bot?.status?.page_info?.title;
  const currentPrice = data.bot?.pricingData?.currentPrice ?? 0;
  const currency = data.bot?.pricingData?.currency;
  const { usersLimit } = data.bot.limits;
  const offers: IOffer[] = getOffers(
    data.me,
    currentPrice,
    usersLimit,
    currency ?? undefined,
  );

  return (
    <div className={css.pageWrapper}>
      {hasPage && (
        <div className={css.section}>
          <div className={css.botPageHeader}>
            <span className={css.sectionHeader}>
              {t('UpgradePage-JSXText-9268-activate-pro-plan-for-the')}{' '}
            </span>
            <BotPage bot={data.bot} />
            <span className={css.sectionHeader}>
              {t('UpgradePage-JSXText-3298-page')}
            </span>
          </div>
        </div>
      )}

      <div className={css.section}>
        <div className={css.offersList}>
          {offers.map((offer) => (
            <Offer
              key={offer.title}
              offer={offer}
              highlightSyncClone={blinkSyncClone}
            />
          ))}
        </div>
      </div>

      <div className={css.section}>
        <div className={css.sectionHeader}>
          {t('UpgradePage-JSXText--143-have-a-question')}
        </div>
        <Button
          intent="primary"
          onClick={() =>
            window.open(t('common.helpDocLinks.pricing'), '_blank')
          }
        >
          {t('UpgradePage-JSXText-2129-check-faq')}
        </Button>
      </div>
    </div>
  );
};
