/**
 * Одновременно на экране может показываться 4 тира
 * Этот хелпер реализует эту логику в зависимости от позиции выбранного тира в списке тиров по правилу:
 * - если элемент массива 2 или меньше -> первые 4 элемента
 * - если элемент массива N            -> 0, N-1, N, N+1
 * - если элемент массива максимальный -> 0, N-1, N-2, N-3
 */

export const isTierIncluded = (
  tierIndex: number,
  currentTierIndex: number,
  maxIndex: number,
): boolean => {
  if (currentTierIndex <= 1) {
    return tierIndex <= 3;
  }

  if (currentTierIndex === maxIndex) {
    return (
      tierIndex === 0 ||
      tierIndex === currentTierIndex - 2 ||
      tierIndex === currentTierIndex - 1 ||
      tierIndex === currentTierIndex
    );
  }

  return (
    tierIndex === 0 ||
    tierIndex === currentTierIndex - 1 ||
    tierIndex === currentTierIndex ||
    tierIndex === currentTierIndex + 1
  );
};
