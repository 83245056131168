import React, { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal } from '@services/index';
import { Button } from '@ui/Button';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Period, TierType } from '@globals';
import { UPDATE_PLAN_MODAL_QUERY_PARAMS } from '@utils/Data/Pricing';
import { ActiveCardButton } from '../../ActiveCardButton';
import { PremiumBillingPeriodDialog } from '../../BillingPeriodDialog/PremiumBillingPeriodDialog';
import { BillingPeriodActionType, PremiumTierCardActionProps } from '../types';
import { isSomeEnum } from '@utils/isSomeEnum';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './PremiumTierCardAction.css';

const buttonLoader = (
  <div className={css.loader}>
    <LoadingPlaceholder height={6} width={64} />
  </div>
);

const PREMIUM_BILLING_PERIOD_DIALOG = 'PREMIUM_BILLING_PERIOD_DIALOG';

export const PremiumTierCardAction: React.FC<PremiumTierCardActionProps> = ({
  isCurrentTierSelected,
  onDowngrade,
  onUpgrade,
  isCurrentTierNext,
  moreExpensiveThanCurrent,
  disabledBySwitching,
  nextTier,
  pricing,
  tier,
  currency,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useSafeTranslation();
  const currentTier = pricing?.current_tier;
  const readOnly = false;

  const showModal = useCallback(
    (
      actionType: BillingPeriodActionType,
      tierType: TierType,
      period?: Period,
    ) =>
      Modal.show(
        ({ close }) => (
          <PremiumBillingPeriodDialog
            actionType={actionType}
            close={close}
            pricing={pricing}
            tierType={tierType}
            currency={currency}
            isCurrentTierSelected={isCurrentTierSelected}
            initialPeriod={period}
            onUpdate={
              actionType === BillingPeriodActionType.downgrade
                ? onDowngrade
                : onUpgrade
            }
          />
        ),
        { id: PREMIUM_BILLING_PERIOD_DIALOG, mobileAdaptive: true },
      ),
    [pricing, currency, isCurrentTierSelected, onDowngrade, onUpgrade],
  );

  useEffect(() => {
    /**
     * Will be done once
     */
    if (!isCurrentTierSelected) return;
    const queryParams = new URLSearchParams(location.search);
    const tierType = queryParams.get(UPDATE_PLAN_MODAL_QUERY_PARAMS.tier);
    const tierPeriod = queryParams.get(UPDATE_PLAN_MODAL_QUERY_PARAMS.period);
    if (isSomeEnum(TierType, tierType)) {
      showModal(
        tierType === currentTier
          ? BillingPeriodActionType.change
          : BillingPeriodActionType.upgrade,
        tierType,
        isSomeEnum(Period, tierPeriod) ? tierPeriod : undefined,
      );
      queryParams.delete(UPDATE_PLAN_MODAL_QUERY_PARAMS.tier);
      queryParams.delete(UPDATE_PLAN_MODAL_QUERY_PARAMS.period);
      history.push({ search: queryParams.toString() });
    }
  }, [location.search, isCurrentTierSelected, showModal, history, currentTier]);

  if (isCurrentTierSelected && tier.type === TierType.trial)
    return (
      <ActiveCardButton
        className={css.button}
        title={t('pages.Pricing.activeCardButton.yourPlan')}
        icon="check"
      />
    );

  if (isCurrentTierNext || (isCurrentTierSelected && !nextTier))
    return (
      <Button
        data-testid="plans-page__tier-card__change-billing-button"
        className={css.button}
        onClick={() =>
          showModal(BillingPeriodActionType.change, tier.type as TierType)
        }
        intent="secondary"
        disabled={
          readOnly || tier.type === TierType.trial || disabledBySwitching
        }
      >
        {disabledBySwitching
          ? buttonLoader
          : t('pages.Pricing.activeCardButton.changeBilling')}
      </Button>
    );

  if (moreExpensiveThanCurrent)
    return (
      <Button
        data-testid="plans-page__tier-card__upgrade-button"
        className={css.button}
        onClick={() =>
          showModal(BillingPeriodActionType.upgrade, tier.type as TierType)
        }
        disabled={readOnly || disabledBySwitching}
      >
        {disabledBySwitching ? buttonLoader : t('pages.Pricing.upgradeButton')}
      </Button>
    );

  return (
    <Button
      data-testid="plans-page__tier-card__downgrade-button"
      className={css.button}
      intent="secondary"
      onClick={() => {
        if (tier.type === TierType.trial) {
          onDowngrade(TierType.trial, Period.monthly);
        } else {
          showModal(BillingPeriodActionType.downgrade, tier.type as TierType);
        }
      }}
      disabled={readOnly || disabledBySwitching}
    >
      {disabledBySwitching ? buttonLoader : t('pages.Pricing.downgradeButton')}
    </Button>
  );
};
