import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { getMonthsCount } from '@components/DialogsPricing/utils/getMonthsCount';
import { PremiumTierCardLabelProps } from '../types';
import css from './TierCardLabel.css';
import { formatPricingDate } from '@utils/DateTime/formatDate';

export const PremiumTierCardLabel: React.FC<PremiumTierCardLabelProps> = ({
  isCurrentTierSelected,
  isCurrentTierNext,
  currentSubscriptionPeriod,
  nextPrepaymentDate,
  hasNextTier,
}) => {
  const { t } = useSafeTranslation();
  const dataTestId = 'premium-plans-page__tier-card__tier-card-label';

  if (isCurrentTierSelected && !isCurrentTierNext && hasNextTier)
    return (
      <Type
        size="12px"
        color="black"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {t('pages.Pricing.businessCard.current')}
        <Tooltip2 content={t('pages.Pricing.businessCard.youHaveSuccessfully')}>
          {(ref, bind) => (
            <Icon icon="info" size="20px" color="black" ref={ref} {...bind} />
          )}
        </Tooltip2>
      </Type>
    );

  if (isCurrentTierSelected) {
    return (
      <Type
        size="12px"
        color="white"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {t('pages.Pricing.businessCard.currentMonth', {
          count: getMonthsCount(currentSubscriptionPeriod ?? null),
        })}
      </Type>
    );
  }

  if (isCurrentTierNext && nextPrepaymentDate) {
    return (
      <Type
        size="12px"
        color="white"
        className={css.root}
        weight="medium"
        data-testid={dataTestId}
      >
        {`${t('pages.Pricing.businessCard.startAt')} ${formatPricingDate(
          nextPrepaymentDate,
        )}`}
      </Type>
    );
  }

  return <div className={css.root} />;
};
