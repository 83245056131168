import React from 'react';

import { acceptGdpr } from 'cf-common/src/analytics';
import { InitAuthProps } from '@utils/AuthFlow';
import { redirect } from '@utils/UrlUtils';
import { SET_AUTH_MUTATION } from '@utils/AuthFlow/queries';
import {
  setAutotestLoginFlag,
  setSyntheticLoginFlag,
} from '@utils/syntheticLogin';

type TokenParams = {
  token: string;
};

export class Token extends React.Component<InitAuthProps, {}> {
  componentDidMount() {
    const {
      location,
      match: { params },
    } = this.props;

    const searchParams = new URLSearchParams(location.search);

    const { token } = params as TokenParams;

    this.setTokenAndRedirect(token, searchParams.has('autotest'));
  }

  setTokenAndRedirect = async (token: string, isAutotest: boolean) => {
    const { client } = this.props;
    acceptGdpr(true);

    await client.mutate({
      mutation: SET_AUTH_MUTATION,
      variables: { token },
    });

    setSyntheticLoginFlag();
    if (isAutotest) {
      setAutotestLoginFlag();
    }
    redirect('/');
  };

  render() {
    return null;
  }
}

export default Token;
