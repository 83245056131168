import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import {
  ShopifyPricingPlanQuery,
  ShopifyPricingPlanQueryVariables,
} from './@types/ShopifyPricingPlanQuery';

const SHOPIFY_PRICING_PLAN_QUERY = gql`
  query ShopifyPricingPlanQuery($botId: ID!) {
    shopifyPricingPlan(botId: $botId) {
      subscriptionName
      subscriptionPrice
      includedConversations
      extraDialogPrice
      cappedAmount
      subscriptionCurrency
      subscriptionTrialDays
    }
  }
`;

export const useShopifyPricingPlan = () => {
  const botId = useCurrentBotId();
  const { data, ...queryState } = useQuery<
    ShopifyPricingPlanQuery,
    ShopifyPricingPlanQueryVariables
  >(SHOPIFY_PRICING_PLAN_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  return {
    shopifyPricingPlan: data?.shopifyPricingPlan,
    ...queryState,
  };
};
