import React from 'react';
import {
  DowngradedPricingDialog,
  PaymentFailureDialog,
  UpgradedPricingDialog,
} from '@components/DialogsPricing/dialogs';
import { PaymentDialogProps } from '@components/Payments/PaymentDialog/types';
import { Period, TierType } from '@globals';
import { Modal } from '@services/index';
import { sendEvent } from '@utils/Analytics';
import { BOT_PRO_STATUS_QUERY } from '@utils/Data/Bot/BotProStatus/queries';
import { DIALOGS_PRICING_QUERY, Pricing, Tier } from '@utils/Data/Pricing';
import {
  SwitchPricingTierMutation,
  SwitchPricingTierMutationVariables,
} from '@utils/Data/Pricing/@types/SwitchPricingTierMutation';
import {
  PAYMENT_HISTORY_QUERY,
  PAYMENT_INFO_QUERY,
} from '@utils/Data/Pricing/queries';
import { ApolloError } from 'apollo-client';
import { ExecutionResult } from 'graphql';
import Maybe from 'graphql/tsutils/Maybe';
import { MutationTuple } from 'react-apollo';
import {
  PaymentFlowError,
  PaymentFlowErrorKind,
} from 'cf-common/src/utils/Stripe/errors';

interface GetSwitchTierCallbackPayload {
  botId: string;
  currentTier: Maybe<Tier>;
  switchPricingTierMutation: MutationTuple<
    SwitchPricingTierMutation,
    SwitchPricingTierMutationVariables
  >[0];
  handleMutationError: (error: ApolloError) => void;
  showPaymentUpdateDialog: (
    setupPayment?: PaymentDialogProps['setupPayment'],
  ) => void;
  pricing: Maybe<Pricing>;
  isFirstSessionTab: boolean;
  tiers: Tier[];
}

export const getSwitchTierCallback = ({
  botId,
  currentTier,
  switchPricingTierMutation,
  handleMutationError,
  showPaymentUpdateDialog,
  pricing,
  isFirstSessionTab,
  tiers,
}: GetSwitchTierCallbackPayload) =>
  // eslint-disable-next-line consistent-return
  async function switchTier(
    nextTier: TierType,
    subscriptionPeriod: Period,
    paymentMethodId?: string,
  ) {
    const prevTier = pricing?.next_tier
      ? tiers.find((t) => t.type === pricing?.next_tier)
      : currentTier;
    let res: ExecutionResult<SwitchPricingTierMutation> | undefined;
    try {
      res = await switchPricingTierMutation({
        variables: { botId, nextTier, subscriptionPeriod, paymentMethodId },
        refetchQueries: [
          { query: DIALOGS_PRICING_QUERY, variables: { botId } },
          { query: BOT_PRO_STATUS_QUERY, variables: { botId } },
          { query: PAYMENT_HISTORY_QUERY, variables: { botId } },
          { query: PAYMENT_INFO_QUERY, variables: { botId } },
        ],
      });
    } catch (error) {
      handleMutationError(error as any);
      return;
    }

    const { data, errors } = res;

    if (errors?.length || !data?.switchPricingTier) {
      return;
    }

    const { stripe_error } = data.switchPricingTier;
    if (stripe_error) {
      const error = new PaymentFlowError(stripe_error.description, {
        kind: PaymentFlowErrorKind.stripeDecline,
        declineCode: stripe_error.decline_code || stripe_error.code,
      });
      Modal.show(
        ({ close, resolve }) => (
          <PaymentFailureDialog
            error={error.errorData}
            onTryAgain={() => {
              sendEvent({
                category: 'payments',
                action: 'click try new payment card',
                label: 'new dialogs pricing',
                propertyBag: {
                  botId,
                  error: error.errorData,
                  currentPlan: prevTier?.type,
                  nextPlan: nextTier,
                },
              });
              close();
              resolve();
            }}
            onDismiss={() => {
              sendEvent({
                category: 'payments',
                action: 'click dismiss on try new payment card',
                label: 'new dialogs pricing',
                propertyBag: { botId, error: error.errorData },
              });
              close();
            }}
          />
        ),
        {
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      )?.then(() =>
        showPaymentUpdateDialog((paymentMethodId) =>
          switchTier(nextTier, subscriptionPeriod, paymentMethodId),
        ),
      );
      return;
    }

    const newPricing = data.switchPricingTier;
    const newNextTierType = newPricing.next_tier ?? newPricing.current_tier;
    const newNextTier = tiers.find((t) => t.type === newNextTierType);

    if (isFirstSessionTab) return;

    if (prevTier && newNextTier) {
      if (prevTier.tier_price < newNextTier.tier_price) {
        Modal.show(
          ({ close }) => (
            <UpgradedPricingDialog
              newNextTierType={newNextTier.type}
              prevTierType={prevTier.type}
              prevPeriod={pricing?.current_subscription_period!}
              newPeriod={subscriptionPeriod}
              newPricing={newPricing}
              close={() => {
                sendEvent({
                  category: 'pricing',
                  action: 'click close after switch plan',
                  label: 'new dialogs pricing',
                  propertyBag: { botId, plan: newNextTier.type },
                });
                close();
              }}
            />
          ),
          {
            mobileAdaptive: true,
            mobileProps: {
              fitHeight: true,
            },
          },
        );
      } else {
        Modal.show(
          ({ close }) => (
            <DowngradedPricingDialog
              newNextTierType={newNextTier.type}
              prevTierType={prevTier.type}
              newPricing={newPricing}
              close={() => {
                sendEvent({
                  category: 'pricing',
                  action: 'click close after downgrade plan',
                  label: 'new dialogs pricing',
                  propertyBag: { botId, newPlan: newNextTier.tier_price },
                });
                close();
              }}
            />
          ),
          {
            mobileAdaptive: true,
            mobileProps: {
              fitHeight: true,
            },
          },
        );
      }
    }
  };
