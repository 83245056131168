import { FadeInCallout } from '@ui/Callout';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import Maybe from 'graphql/tsutils/Maybe';
import React from 'react';
import { ReactComponent as Picture } from './picture.svg';
import css from './TimeLimitedTrialCallout.css';
import { TrialStatsQuery_trialStats } from '@utils/Data/Pricing/@types/TrialStatsQuery';

interface TimeLimitedTrialCalloutProps {
  trialStatsData: Maybe<TrialStatsQuery_trialStats>;
}

const TextComponent: React.FC<{ blue?: boolean }> = ({ children, blue }) => (
  <Type
    size="32px"
    color={blue ? 'accent1Secondary' : 'blueLight30'}
    weight="bold"
  >
    {children}
  </Type>
);

export const TimeLimitedTrialCallout: React.FC<TimeLimitedTrialCalloutProps> =
  ({ trialStatsData }) => {
    const { t } = useSafeTranslation();
    return (
      <FadeInCallout
        className={css.container}
        iconDisabled
        text={
          <Flex fullWidth justifyContent="space-between">
            <div className={css.textContainer}>
              {trialStatsData?.reachedDialogs ||
              trialStatsData?.reachedUsers ? (
                <>
                  <TextComponent>
                    {t('pages.Pricing.with7DaysTrialYour')}{' '}
                  </TextComponent>
                  <TextComponent blue>
                    {t('pages.Pricing.reachedUsers', {
                      count: trialStatsData.reachedUsers ?? 0,
                    })}{' '}
                  </TextComponent>
                  <TextComponent>{t('pages.Pricing.and')} </TextComponent>
                  <TextComponent blue>
                    {t('pages.Pricing.sentConversations', {
                      count: trialStatsData.reachedDialogs ?? 0,
                    })}{' '}
                  </TextComponent>
                  <TextComponent>
                    {t('pages.Pricing.selectPlanToGet')}
                  </TextComponent>
                </>
              ) : (
                <>
                  <TextComponent>
                    {t('pages.Pricing.trialHasEnded')}{' '}
                  </TextComponent>
                  <TextComponent blue>
                    {t('pages.Pricing.selectPlan')}{' '}
                  </TextComponent>
                  <TextComponent>
                    {t('pages.Pricing.toKeepYourBot')}
                  </TextComponent>
                </>
              )}
            </div>
            <Picture className={css.image} />
          </Flex>
        }
        type="info"
      />
    );
  };
