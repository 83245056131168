import React from 'react';
import cn from 'classnames';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Anchor } from '@ui/Links';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { TierCardPrice } from '../TierCard/TierCardPrice';
import { Tier } from '@utils/Data/Pricing';
import { Modal } from '@services/index';
import { PageBillingPeriodDialog } from '../BillingPeriodDialog/PageBillingPeriodDialog';
import { BillingPeriodActionType } from '../TierCard/types';
import { dialogsPricingFragment } from '@utils/Data/Pricing/@types/dialogsPricingFragment';
import Maybe from 'graphql/tsutils/Maybe';
import { formatPrice } from '@utils/Format';
import { TierPriceSizeProvider } from '@pages/Plans/hooks/useTierPriceSize';
import { CHATFUEL_DEMO_URL } from '@pages/Plans/constants';
import { sendEvent } from '@utils/Analytics';
import { TierType } from '@globals';
import { BillingPeriodDialogProps } from '../BillingPeriodDialog/BillingPeriodDialog';
import css from './WhatsappTiersList.css';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { useCurrentBotId } from '@utils/Routing';
import { WhatsappTierCard } from './components/WhatsappTierCard';
import { WhatsappTierCardLoader } from './components/WhatsappTierCard/WhatsappTierCard';
import { WhatsappBillingPeriodDialogCallout } from './components/WhatsappBillingPeriodDialogCallout';
import { WhatsappPhoneCallout } from '../WhatsappPhoneCallout';
import { TimeLimitedTrialCallout } from '../TimeLimitedTrialCallout/TimeLimitedTrialCallout';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { TierCardBadge } from '../TierCard/TierCardBadge';
import { ENTERPRISE_PRICE } from './consts';
import { useBotPromoCodeQuery } from '../../../../utils/Data/PromoCode/useBotPromoCodeQuery';

interface WhatsappTiersListProps {
  loading: boolean;
  currency: string;
  tiers: Tier[];
  pricing: Maybe<dialogsPricingFragment>;
  onUpgrade: BillingPeriodDialogProps['onUpdate'];
  checkAvailableFreeMonth(tierType: TierType): boolean;
}

const getEnterprisePrice = (currency: Maybe<string>): number => {
  if (!currency) return ENTERPRISE_PRICE.EUR;
  if (currency in ENTERPRISE_PRICE)
    return ENTERPRISE_PRICE[currency as keyof typeof ENTERPRISE_PRICE];
  return ENTERPRISE_PRICE.EUR;
};

export const WhatsappTiersList: React.FC<WhatsappTiersListProps> = ({
  loading,
  tiers,
  currency,
  pricing,
  onUpgrade,
  checkAvailableFreeMonth,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const { getValueDueToSize } = useDeviceMedia();
  const { isConnected, loading: whatsappConnectedLoading } =
    useWhatsappConnected(botId);
  const { data: promocodeData } = useBotPromoCodeQuery(botId!);
  const {
    currentTierTimeLimitedTrial,
    trialExpired: timeLimitedTrialExpired,
    trialStatsData,
    timeLimitedTrialLoading,
  } = useTimeLimitedTrial();

  const businessTier = tiers.find(({ type }) => type === TierType.business);
  const gpt4oTier = tiers.find(({ type }) => type === TierType.gpt4o);

  const getCallout = () => {
    if (whatsappConnectedLoading || timeLimitedTrialLoading) return null;
    if (!isConnected) return <WhatsappPhoneCallout />;
    if (currentTierTimeLimitedTrial && timeLimitedTrialExpired)
      return (
        <>
          <Spacer factor={getValueDueToSize(4, 6)} />
          <TimeLimitedTrialCallout trialStatsData={trialStatsData} />
        </>
      );
    return null;
  };

  const enterprisePrice = getEnterprisePrice(pricing?.currency);

  return (
    <TierPriceSizeProvider
      maxPrice={Math.max(businessTier?.tier_price ?? 0, enterprisePrice)}
    >
      {getCallout()}
      <Spacer factor={getValueDueToSize(4, 6)} />
      <Flex gapFactor={5} className={css.container}>
        {loading || whatsappConnectedLoading ? (
          <>
            <WhatsappTierCardLoader />
            <WhatsappTierCardLoader />
          </>
        ) : (
          <>
            {(
              [
                {
                  tier: businessTier,
                  description: 'pages.Pricing.whatsapp.forBusinessesStartups',
                },
                {
                  tier: gpt4oTier,
                  description: 'pages.Pricing.whatsapp.gpt4o',
                },
              ] as const
            ).map(
              ({ tier, description }) =>
                tier && (
                  <WhatsappTierCard
                    name={getTierNameByType(tier.type)}
                    badge={
                      <TierCardBadge
                        discount={
                          promocodeData?.bot?.assignedPromoCode?.promoCode
                            .discountPercents
                        }
                        type={tier.type}
                        isCurrentTierSelected={
                          pricing?.current_tier === tier.type
                        }
                        checkAvailableFreeMonth={checkAvailableFreeMonth}
                      />
                    }
                    price={
                      <TierCardPrice
                        currency={currency}
                        price={Math.trunc(tier.tier_price)}
                        decimals={0}
                      />
                    }
                    conversations={t('pages.Pricing.whatsapp.convercationsMO', {
                      count: tier.tier_dialogs,
                    })}
                    extraConversations={
                      <span>
                        {formatPrice(tier.adjustment_fee_per_dialog, {
                          currency,
                          decimals: 2,
                        })}{' '}
                        +{' '}
                        <Anchor
                          intent="external"
                          size="small"
                          target="_blank"
                          hideArrow
                          href="https://developers.facebook.com/docs/whatsapp/pricing"
                        >
                          {t('pages.Pricing.whatsapp.metaCosts')}
                        </Anchor>
                      </span>
                    }
                    description={t(description)}
                    button={
                      <Button
                        data-testid="plans-page__tier-card__upgrade-button"
                        className={css.button}
                        intent={isConnected ? 'primary' : 'secondary'}
                        onClick={() =>
                          Modal.show(
                            ({ close }) => (
                              <PageBillingPeriodDialog
                                actionType={BillingPeriodActionType.upgrade}
                                close={close}
                                pricing={pricing}
                                tierType={tier.type}
                                currency={currency}
                                onUpdate={onUpgrade}
                                disabledItems={!isConnected}
                                replaceFooter={
                                  isConnected ? undefined : (
                                    <WhatsappBillingPeriodDialogCallout
                                      onConnectClick={close}
                                    />
                                  )
                                }
                              />
                            ),
                            {
                              mobileAdaptive: true,
                              mobileProps: {
                                fitHeight: true,
                              },
                            },
                          )
                        }
                      >
                        {t('pages.Pricing.whatsapp.selectPlan')}
                      </Button>
                    }
                  />
                ),
            )}
            <WhatsappTierCard
              name="Enterprise"
              price={
                <TierCardPrice
                  currency={currency}
                  price={enterprisePrice}
                  decimals={0}
                />
              }
              conversations={t('pages.Pricing.whatsapp.custom')}
              extraConversations={t('pages.Pricing.whatsapp.custom')}
              description={t('pages.Pricing.whatsapp.forBusinessesAllSizes')}
              button={
                <Button
                  className={cn(css.button, isConnected && css.contactUs)}
                  intent={isConnected ? 'primary' : 'secondary'}
                  onClick={() => {
                    sendEvent({
                      category: 'whatsapp pricing',
                      action: 'click contact us in enterprise tier',
                    });
                    window.open(CHATFUEL_DEMO_URL, '_blank');
                  }}
                >
                  {t('pages.Pricing.whatsapp.contactUs')}
                </Button>
              }
            />
          </>
        )}
      </Flex>
    </TierPriceSizeProvider>
  );
};
