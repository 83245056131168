import gql from 'graphql-tag';

export const USER_WORKSPACE_PLANS_AVAILABILITY_QUERY = gql`
  query UserWorkspacePlansAvailabilityQuery {
    me {
      id
      show_workspace_plans
    }
  }
`;
