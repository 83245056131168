import React from 'react';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

export const PremiumTierCardLoader: React.FC<{}> = () => (
  <LoadingPlaceholder
    color="white"
    width={240}
    height={368}
    style={{ borderRadius: 12, flexShrink: 0 }}
  />
);
