/**
 * @deprecated use direct import from `@utils/Payment/paymentInterval` instead
 *
 * avoid imports from `pages/UpgradePage/components/paymentInterval` if you outside UpgradePage folder
 */
export {
  PaymentInterval,
  paymentIntervalToEnum,
  paymentIntervalToString,
} from '@utils/Payment/paymentInterval';
