import React, { useCallback } from 'react';
import cn from 'classnames';
import * as css from './WhatsappBillingPeriodDialogCallout.css';
import { Flex } from '@ui/Flex';
import { ReactComponent as PhoneSvg } from './images/phone.svg';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useHistory } from 'react-router-dom';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { DeepLinksMode } from '../../../../../DeepLinks/types';

interface WhatsappBillingPeriodDialogCallout {
  onConnectClick: VoidFunction;
}

export const WhatsappBillingPeriodDialogCallout: React.FC<WhatsappBillingPeriodDialogCallout> =
  ({ onConnectClick }) => {
    const { t } = useSafeTranslation();
    const { getValueDueToSize } = useDeviceMedia();
    const history = useHistory();
    const botId = useCurrentBotId();

    const connectClickHandler = useCallback(() => {
      sendEvent({
        category: 'plans',
        label: 'whatsapp billing dialog callout',
        action: 'connect button click',
      });
      history.push(
        getTabLink(BotTabs.home, botId, {
          dlMode: DeepLinksMode.connectWhatsapp,
        }),
      );
      onConnectClick();
    }, [botId, history, onConnectClick]);

    return (
      <Flex
        className={cn(css.box, getValueDueToSize(css.mBox, css.box))}
        alignItems="center"
        justifyContent="space-between"
        gapFactor={4}
        flexDirection={getValueDueToSize('column', 'row')}
      >
        <Flex
          alignItems={getValueDueToSize('flex-start', 'center')}
          gapFactor={2}
        >
          <PhoneSvg
            className={cn(css.noFlex, getValueDueToSize(css.mIcon, css.noFlex))}
          />
          <Type size="12px">
            {t('pages.Pricing.whatsapp.dialogCallout.text')}
          </Type>
        </Flex>
        <Button
          onClick={connectClickHandler}
          data-testid="whatsapp-billing-period-dialog-callout_connect-button"
          className={cn(css.noFlex, getValueDueToSize(css.mButton, css.noFlex))}
          intent="primary"
        >
          {t('pages.Pricing.whatsapp.dialogCallout.connect')}
        </Button>
      </Flex>
    );
  };
