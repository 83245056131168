import React, { useEffect, useState } from 'react';
import { reverse } from 'ramda';
import cn from 'classnames';
import { Period, TierType } from '@globals';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { Flex } from '@ui/Flex';
import { RadioButton } from '@ui/Radio';
import { dialogsPricingFragment } from '@utils/Data/Pricing/@types/dialogsPricingFragment';
import { List, ListItem } from '@ui/List';
import { DefaultDialog } from '@ui/Dialog';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { formatPrice } from '@utils/Format';
import { Spacer } from '@ui/Spacer';
import Maybe from 'graphql/tsutils/Maybe';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { BillingPeriodActionType } from '../TierCard/types';
import { getMonthsCount } from '@components/DialogsPricing/utils/getMonthsCount';
import { BillingPeriodBadge } from './BillingPeriodBadge';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { calcAnnualOverpayment } from './calcAnnualOverpayment';
import { PricePeriodListQuery_bot_pricePeriodList } from '@utils/Data/Pricing/@types/PricePeriodListQuery';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { formatPricingDate } from '@utils/DateTime/formatDate';
import { PaymentIntent } from '@components/Payments';
import { InfoTooltip } from './InfoTooltip';
import css from './BillingPeriodDialog.css';

const ListLoader: React.FC = () => {
  const { getValueDueToSize } = useDeviceMedia();
  return (
    <>
      {Array.from(new Array(3), (_, index) => (
        <LoadingPlaceholder
          key={index}
          fullWidth
          height={getValueDueToSize(124, 54)}
          className={css.loadingPlaceholder}
        />
      ))}
    </>
  );
};

export interface BillingPeriodDialogProps {
  paymentIntent?: PaymentIntent;
  pricing?: Maybe<dialogsPricingFragment>;
  pricePeriodList: PricePeriodListQuery_bot_pricePeriodList[];
  loading: boolean;
  isCurrentTierSelected?: boolean;
  tierType: TierType;
  currency: string;
  close: VoidFunction;
  actionType: BillingPeriodActionType;
  onUpdate(tier: TierType, period: Period): void;
  initialPeriod?: Period;
  isYearPrices?: boolean;
  isAvailableFreeMonth?: boolean;
  disabledItems?: boolean;
  replaceFooter?: React.ReactNode;
  discount?: string | null;
}

export const BillingPeriodDialog: React.FC<BillingPeriodDialogProps> = ({
  paymentIntent = PaymentIntent.regular,
  pricing,
  tierType,
  currency,
  close,
  actionType,
  onUpdate,
  loading,
  isYearPrices,
  initialPeriod,
  pricePeriodList: list,
  isAvailableFreeMonth,
  disabledItems,
  replaceFooter,
  discount,
}) => {
  const { t } = useSafeTranslation();
  const { getValueDueToSize, isSmallScreenSize } = useDeviceMedia();
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(
    initialPeriod ?? null,
  );

  let pricePeriodList = list;
  if (isYearPrices) {
    pricePeriodList = reverse(pricePeriodList);
  }
  const currentTier = pricing?.current_tier === tierType;
  const currentPeriod = pricing?.current_subscription_period;
  const currentPricePeriod = currentTier
    ? pricePeriodList.find(({ period }) => period === currentPeriod)
    : null;
  const isNextTier = pricing?.next_tier
    ? pricing.next_tier === tierType
    : /**
        when we upgraded to an equivalent tier next_tier: null
      */
      currentTier && Boolean(pricing.next_subscription_period);
  const nextPricePeriod = isNextTier
    ? pricePeriodList.find(
        ({ period }) => period === pricing?.next_subscription_period,
      )
    : null;

  const selectedPricePeriod = pricePeriodList.find(
    ({ period }) => period === selectedPeriod,
  );

  useEffect(() => {
    if (loading || selectedPeriod) return;
    setSelectedPeriod(isYearPrices ? Period.annual : Period.semiannual);
  }, [loading, selectedPeriod, isYearPrices]);

  const headerText = {
    [BillingPeriodActionType.change]: t(
      'pages.Billing.periodDialog.changePeriod',
    ),
    [BillingPeriodActionType.downgrade]: t(
      'pages.Billing.periodDialog.downgrade',
    ),
    [BillingPeriodActionType.upgrade]: t(
      'pages.Billing.periodDialog.upgradeTo',
      { tier: getTierNameByType(tierType) },
    ),
  };

  const getHeaderText = () => {
    if (paymentIntent === PaymentIntent.paywall) {
      return t('pages.Billing.periodDialog.plan', {
        tier: getTierNameByType(tierType),
      });
    }

    return headerText[actionType];
  };

  const isUpdateToEquivalentPeriod =
    actionType === BillingPeriodActionType.upgrade &&
    selectedPeriod === pricing?.current_subscription_period;
  const nextPrepaymentDate =
    formatPricingDate(pricing?.next_prepayment_date) ?? '';

  const isUpdateToday =
    isUpdateToEquivalentPeriod || pricing?.current_tier === TierType.trial;

  const getUpdateButtonText = () => {
    if (paymentIntent === PaymentIntent.paywall) {
      return t('pages.Billing.periodDialog.next');
    }
    if (isNextTier || (currentTier && currentPeriod))
      return t('pages.Billing.periodDialog.confirm');
    if (actionType === BillingPeriodActionType.downgrade)
      return t('pages.Billing.periodDialog.downgrade');
    if (isUpdateToday) {
      const { priceToUpgrade, priceTotalWithDiscounts } =
        selectedPricePeriod || {};
      return t('pages.Billing.periodDialog.payAndUpgrade', {
        sum: formatPrice(priceToUpgrade ?? priceTotalWithDiscounts ?? 0, {
          currency,
          decimals: 2,
        }),
      });
    }
    return t('pages.Billing.periodDialog.upgrade');
  };

  const getUpdateLabelText = () => {
    if (paymentIntent === PaymentIntent.paywall) {
      return t('pages.Billing.periodDialog.paywallStarts');
    }

    return `${t('pages.Billing.periodDialog.starts')} ${
      isUpdateToday
        ? t('pages.Billing.periodDialog.today')
        : nextPrepaymentDate ?? ''
    }`;
  };

  return (
    <DefaultDialog
      className={isSmallScreenSize ? css.root_mobile : css.root}
      onDismiss={close}
      mobileAdaptive={isSmallScreenSize}
      header={
        <Type size="24px" weight="semibold">
          {getHeaderText()}
        </Type>
      }
      footer={
        replaceFooter || (
          <Flex
            flexDirection={getValueDueToSize('column', 'row')}
            alignItems="center"
            style={getValueDueToSize(
              {
                marginTop: '-16px',
                padding: '0 16px 16px',
                flexGrow: 1,
              },
              {},
            )}
          >
            {selectedPeriod && (isUpdateToday || nextPrepaymentDate) && (
              <Type
                size="15px"
                data-testid="billing-period-dialog__starts-date"
              >
                {getUpdateLabelText()}
              </Type>
            )}
            <Spacer horizontalFactor={getValueDueToSize(3, 4)} />
            <Button
              data-testid="billing-period-dialog__update-button"
              style={getValueDueToSize({ width: '100%' }, {})}
              size={getValueDueToSize('l', 'm')}
              onClick={() => {
                close();
                if (selectedPeriod) onUpdate(tierType, selectedPeriod);
              }}
              loading={loading || !selectedPeriod}
              disabled={selectedPeriod === null}
            >
              {getUpdateButtonText()}
            </Button>
          </Flex>
        )
      }
    >
      <List className={css.list}>
        {loading ? (
          <ListLoader />
        ) : (
          pricePeriodList.map(
            (
              {
                period,
                pricePerMonth,
                priceTotal,
                save,
                priceTotalWithDiscounts,
                priceToUpgrade,
              },
              index,
            ) => {
              const isYourPlan =
                currentTier && period === pricing?.current_subscription_period;
              const isNextPlan =
                isNextTier &&
                Boolean(period === pricing?.next_subscription_period);
              const monthsCount = getMonthsCount(period);
              const isAdditionalDiscount = priceTotal > priceTotalWithDiscounts;
              const isPriceLess =
                !!priceToUpgrade && priceTotalWithDiscounts > priceToUpgrade;
              const isDisabledItem =
                disabledItems ||
                (isYourPlan ? !pricing?.next_tier : isNextPlan);

              return (
                <ListItem
                  data-testid={`billing-period-dialog__list-item_${period}`}
                  key={period}
                  drawDivider={pricePeriodList.length - 1 !== index}
                  boxClassName={cn(
                    selectedPeriod === period && css.selectedItem,
                    css.listItem,
                  )}
                  interactive={!isDisabledItem}
                  onClick={
                    isDisabledItem ? undefined : () => setSelectedPeriod(period)
                  }
                >
                  <Flex
                    alignItems="flex-start"
                    flexDirection="column"
                    className={css.listItemContent}
                  >
                    <RadioButton
                      name="period"
                      id={period}
                      value={selectedPeriod ?? undefined}
                      className={css.radioButton}
                      disabled
                      data-testid="billing-period-dialog__list-item__radio-button"
                    />
                    <Flex
                      flexDirection={getValueDueToSize('column', 'row')}
                      className={getValueDueToSize(
                        css.containerMobile,
                        css.container,
                      )}
                    >
                      <Type
                        size="18px"
                        weight="semibold"
                        color={isDisabledItem ? 'greyDark' : 'black'}
                        className={getValueDueToSize(
                          css.textLineMobile,
                          css.textLine,
                        )}
                        data-testid="billing-period-dialog__list-item__period"
                      >
                        {t('pages.Billing.periodDialog.month', {
                          count: monthsCount,
                        })}
                      </Type>
                      <Flex
                        className={getValueDueToSize(
                          css.textLineMobile,
                          css.textLine,
                        )}
                        alignItems="center"
                        data-testid="billing-period-dialog__list-item__price-per-month"
                      >
                        <Type
                          size="18px"
                          weight="semibold"
                          color={isDisabledItem ? 'greyDark' : 'black'}
                        >
                          {formatPrice(pricePerMonth, {
                            currency,
                            decimals: 2,
                          })}
                        </Type>
                        <Spacer horizontalFactor={1} />
                        <Type
                          size="15px"
                          color={isDisabledItem ? 'greyDark' : 'black'}
                        >
                          {t('pages.Billing.periodDialog.perMonth')}
                        </Type>
                      </Flex>
                    </Flex>
                    <Flex
                      flexDirection={getValueDueToSize('column', 'row')}
                      className={getValueDueToSize(
                        css.descriptionContainerMobile,
                        css.descriptionContainer,
                      )}
                      alignItems={getValueDueToSize('flex-start', 'flex-end')}
                    >
                      <BillingPeriodBadge
                        discount={discount}
                        isMonthly={period === Period.monthly}
                        isNextPlan={isNextPlan}
                        nextPrepaymentDate={nextPrepaymentDate}
                        isYourPlan={isYourPlan}
                        save={save}
                        extra={calcAnnualOverpayment(
                          pricePerMonth,
                          currentPricePeriod?.pricePerMonth ??
                            nextPricePeriod?.pricePerMonth,
                        )}
                        currency={currency}
                        isAdditionalDiscount={
                          isAdditionalDiscount || isPriceLess
                        }
                        isAvailableFreeMonth={isAvailableFreeMonth}
                      />
                      <Flex
                        alignItems="flex-start"
                        data-testid="billing-period-dialog__list-item__price-total"
                        className={getValueDueToSize(
                          css.priceContainerMobile,
                          css.priceContainer,
                        )}
                      >
                        <Type
                          size="15px"
                          color={isDisabledItem ? 'greyDark' : 'black'}
                        >
                          {t('pages.Billing.periodDialog.total')}
                        </Type>
                        <Spacer horizontalFactor={1} factor={0} />
                        {(isAdditionalDiscount || isPriceLess) && (
                          <>
                            <Type
                              size="15px"
                              color={isDisabledItem ? 'greyDark' : 'black'}
                              className={css.priceWithoutDiscount}
                            >
                              {formatPrice(priceTotal, {
                                currency,
                                decimals: 2,
                              })}
                            </Type>
                            <Spacer horizontalFactor={1} factor={0} />
                          </>
                        )}
                        <Type
                          size="15px"
                          weight="semibold"
                          color={isDisabledItem ? 'greyDark' : 'black'}
                        >
                          {formatPrice(
                            priceToUpgrade ?? priceTotalWithDiscounts,
                            { currency, decimals: 2 },
                          )}
                        </Type>
                        <InfoTooltip
                          isAdditionalDiscount={isAdditionalDiscount}
                          isPriceLess={isPriceLess}
                          discountMonthsLeft={pricing?.discount_months_left}
                          isAvailableFreeMonth={isAvailableFreeMonth}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </ListItem>
              );
            },
          )
        )}
      </List>
    </DefaultDialog>
  );
};
