import { useLocation } from 'react-router-dom';

export const SHOPIFY_ONBOARDING = 'shopify-onboarding';

export const useShopifyOnboardingState = () => {
  const { search } = useLocation();

  return {
    getState: () => {
      const params = new URLSearchParams(search);

      if (params.get(SHOPIFY_ONBOARDING) === '1') {
        return 'started';
      }

      return 'done';
    },
    startOnboarding: (search: string) => {
      const params = new URLSearchParams(search);

      params.set(SHOPIFY_ONBOARDING, '1');

      return params.toString();
    },
    finishOnboarding: (search: string) => {
      const params = new URLSearchParams(search);

      params.delete(SHOPIFY_ONBOARDING);
      return params.toString();
    },
  };
};
