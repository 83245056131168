import ng from 'angular';
import { react2angular } from 'react2angular';
import { KnowNothingContent } from './Permissions';

export const ngKnowNothingPermissionsContent = ng
  .module('app.modernComponents.knowNothingPermissionsContent', [])
  .component(
    'knowNothingPermissionsContent',
    react2angular(KnowNothingContent, [
      'spaceBetweenBlocks',
      'textWidth',
      'onGivePermissionsClick',
    ]),
  ).name;
