import React, { useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { formatPrice } from '@utils/Format/priceFormatter';
import { TierCardOptionsProps } from '../types';
import css from './TierCardOptions.css';

export const TierCardOptions: React.FC<TierCardOptionsProps> = ({
  tier: {
    adjustment_fee_per_dialog,
    priority_support,
    full_featured,
    tier_dialogs,
  },
  isTrialTier,
  currency,
  showYearPrices,
}) => {
  const { t } = useSafeTranslation();

  const options = useMemo(() => {
    if (isTrialTier)
      return [
        full_featured && t('pages.Pricing.businessCard.fullFeaturedBot'),
        t('pages.Pricing.businessCard.maxDialogs', { count: tier_dialogs }),
        t('pages.Pricing.businessCard.prioritySupportForTrial'),
      ].filter(Boolean) as string[];
    return [
      full_featured && t('pages.Pricing.businessCard.fullFeaturedBot'),
      `${t('pages.Pricing.businessCard.pricePerExtraDialog', {
        price: formatPrice(adjustment_fee_per_dialog, {
          currency,
          decimals: 3,
        }),
      })} ${
        showYearPrices && !isTrialTier
          ? `(${t('pages.Pricing.businessCard.ifPaidAnnually')})`
          : ''
      }`,
      priority_support && t('pages.Pricing.businessCard.prioritySupport'),
    ].filter(Boolean) as string[];
  }, [
    currency,
    adjustment_fee_per_dialog,
    full_featured,
    isTrialTier,
    tier_dialogs,
    priority_support,
    t,
    showYearPrices,
  ]);

  return (
    <ul className={css.list}>
      {options.map((text, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={css.item}>
          <Type size="15px">{text}</Type>
        </li>
      ))}
    </ul>
  );
};
