import React from 'react';
import cn from 'classnames';
import { IFeature } from '../../../constants/premiumData';
import { Tooltip2 } from '../../../modern-ui/Tooltip2';
import * as css from './Feature.css';

interface IFeatureProps {
  feature: IFeature;
  highlight: boolean;
}

export const Feature: React.FC<IFeatureProps> = (props) => {
  const {
    feature: { title, tooltip, isNew },
    highlight,
  } = props;

  return (
    <div className={cn(css.feature, { [css.highlight]: highlight })}>
      {!tooltip ? (
        <span>{title}</span>
      ) : (
        <Tooltip2 content={tooltip} positionFixed placement="left" hoverable>
          {(ref, bind) => (
            <span {...bind} className={css.withTooltip} ref={ref}>
              {title}
              {isNew && (
                <div className={css.labelNew}>
                  {window.i18next.t('Feature-JSXText-7820-new')}
                </div>
              )}
            </span>
          )}
        </Tooltip2>
      )}
    </div>
  );
};
