import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from '@apollo/react-components';
import { path, pathOr } from 'ramda';
import { log } from 'cf-common/src/logger';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { InitAuthProps } from '@utils/AuthFlow';
import { AuthFlowPage } from '@ui/Page';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { locationRedirectToBot } from '@utils/WhiteLabelUtils';
import { SET_AUTH_MUTATION } from '@utils/AuthFlow/queries';
import {
  InviteWhiteLabelInfoQuery,
  InviteWhiteLabelInfoQueryVariables,
} from './@types/InviteWhiteLabelInfoQuery';
import {
  LoginFormMode,
  LoginFormWhiteLabel,
} from '../LoginWhiteLabel/LoginFormLoginWhiteLabel';
import {
  AcceptInviteWhiteLabelMutation,
  AcceptInviteWhiteLabelMutationVariables,
} from './@types/AcceptInviteWhiteLabelMutation';
import { Messages, toaster } from '../../../services/MessageService';
import { Oops } from '../../Oops';
import { setFirstSessionDone } from '../../BotPage/KeywordsPage/FirstSessionPage/utils/setFirstSessionDone';
import { Invalid } from '../../MultiSystemAuth/pages/Invite/Invalid/Invalid';

const INVITE_WHITE_LABEL_INFO_QUERY = gql`
  query InviteWhiteLabelInfoQuery($token: String!) {
    inviteInfo(token: $token) {
      botId
      alreadyHasThisBot
      currentUserId
      botTitle
      pagePicture
      isUsed
      whiteLabelAgencyId
      whiteLabelUserLogin
    }
  }
`;

const ACCEPT_INVITE_WHITE_LABEL_MUTATION = gql`
  mutation AcceptInviteWhiteLabelMutation(
    $inviteToken: String!
    $password: String!
  ) {
    acceptWhiteLabelInviteToken(
      inviteToken: $inviteToken
      password: $password
    ) {
      token
    }
  }
`;

export const InviteWhiteLabel: React.FC<InitAuthProps> = ({
  match: { params },
  history,
  client,
}) => {
  const { token } = params as {
    token: string;
  };

  const botIdRef = useRef<string | undefined>();

  return (
    <Mutation<
      AcceptInviteWhiteLabelMutation,
      AcceptInviteWhiteLabelMutationVariables
    >
      mutation={ACCEPT_INVITE_WHITE_LABEL_MUTATION}
      onError={(error) => {
        log.warn({ error, msg: 'Error while joining the white label bot' });

        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: Messages.somethingWentWrong,
          },
        });
      }}
      onCompleted={async (data) => {
        const { token } = data && data.acceptWhiteLabelInviteToken;
        try {
          await client.mutate({
            mutation: SET_AUTH_MUTATION,
            variables: { token },
          });
          await setFirstSessionDone();
          locationRedirectToBot(botIdRef.current);
        } catch (error) {
          log.warn({
            error,
            msg: 'Error while setting token on white label accept invite page',
          });

          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: Messages.somethingWentWrong,
            },
          });
        }
      }}
    >
      {(acceptToken, { loading: acceptTokenLoading }) => (
        <Query<InviteWhiteLabelInfoQuery, InviteWhiteLabelInfoQueryVariables>
          query={INVITE_WHITE_LABEL_INFO_QUERY}
          variables={{ token }}
          onCompleted={(data) => {
            const alreadyHasThisBot = path<boolean | undefined>(
              ['inviteInfo', 'alreadyHasThisBot'],
              data,
            );
            const isUsed = pathOr<boolean>(
              false,
              ['inviteInfo', 'isUsed'],
              data,
            );
            const currentUserId = pathOr<boolean>(
              false,
              ['inviteInfo', 'currentUserId'],
              data,
            );

            if (isUsed && !currentUserId) {
              const login = path<string | undefined>(
                ['inviteInfo', 'whiteLabelUserLogin'],
                data,
              );
              history.push({
                pathname: `/login-page${login ? `/${login}` : ''}`,
              });
              return;
            }

            if (alreadyHasThisBot && currentUserId && botIdRef.current) {
              locationRedirectToBot(botIdRef.current);
              return;
            }

            removeGlobalLoader();
          }}
          onError={() => {
            removeGlobalLoader();
          }}
        >
          {({ data, loading, error }) => {
            if (error) {
              return (
                <Oops
                  reason="Error load white label invite data"
                  errorText={error.message}
                />
              );
            }

            if (loading || !data || !data.inviteInfo) {
              return null;
            }

            botIdRef.current = path<string | undefined>(
              ['inviteInfo', 'botId'],
              data,
            );

            if (!botIdRef.current) {
              return (
                <AuthFlowPage
                  logoSize={LogoSize.big}
                  render={() => <Invalid />}
                />
              );
            }

            const login = path<string | undefined>(
              ['inviteInfo', 'whiteLabelUserLogin'],
              data,
            );
            const botPicture = path<string | undefined>(
              ['inviteInfo', 'pagePicture'],
              data,
            );
            const botName = path<string | undefined>(
              ['inviteInfo', 'botTitle'],
              data,
            );

            return (
              <AuthFlowPage
                logoSize={LogoSize.big}
                render={() => (
                  <LoginFormWhiteLabel
                    login={login}
                    botPicture={botPicture}
                    botName={botName}
                    disabled={acceptTokenLoading}
                    mode={LoginFormMode.createLogin}
                    onLoginRequest={({ password }) => {
                      acceptToken({
                        variables: {
                          password,
                          inviteToken: token,
                        },
                      });
                    }}
                  />
                )}
              />
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};
