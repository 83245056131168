import React from 'react';
import i18next from 'i18next';
import cn from 'classnames';
import gql from 'graphql-tag';
import { Button } from '@ui/Button';
import { Query, Mutation } from '@apollo/react-components';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from 'react-apollo';
import { ADMIN_QUERY } from '@common/user/UserNavigationItem/queries';
import { AdminQuery } from '@common/user/UserNavigationItem/@types/AdminQuery';
import { RoleIds } from '../../components/settings/admins/RolesList';
import { Tooltip2 } from '@ui/Tooltip2';
import { CenteredLoader } from '@ui/Loader';
import { pathOr } from 'ramda';
import { PageContent } from '../../modern-ui/PageContent';
import { PageHeader } from '../../modern-components/PageHeader';
import { Page } from '../../modern-ui/Page';
import { ZapierAuthParams } from '../../../@types/globalTypes';
import { ServiceMessageType } from '../../modern-ui/ServiceMessage2';
import { toaster } from '../../services/MessageService';
import { InitAuthProps } from '../../utils/AuthFlow';
import { redirect } from '../../utils/UrlUtils';
import { ZapierConnectPageDataQuery } from './@types/ZapierConnectPageDataQuery';
import defaultBotPicture from './images/av_def_bot.png';
import { ReactComponent as ZapierIcon } from './images/zapier.svg';
import {
  ZapierAuthMutation,
  ZapierAuthMutationVariables,
} from './@types/ZapierAuthMutation';
import * as css from './ZapierConnectPage.css';

const ZAPIER_CONNECT_PAGE_DATA_QUERY = gql`
  query ZapierConnectPageDataQuery {
    bots {
      id
      title
      status {
        page_info {
          id
          picture
        }
      }
    }
  }
`;

const ZAPIER_AUTH_MUTATION = gql`
  mutation ZapierAuthMutation($params: ZapierAuthParams!) {
    getZapierAuthUrl(zapierAuthParams: $params) {
      zapierAuthUrl
    }
  }
`;

const getBotPictureUrl = pathOr(defaultBotPicture, [
  'status',
  'page_info',
  'picture',
]);

const prepareZapierAuthUrlParams = (botId: string, searchParams: string) => {
  const urlParams = new URLSearchParams(searchParams);
  return {
    botId,
    clientId: urlParams.get('client_id') || '',
    state: urlParams.get('state') || '',
    redirectUri: urlParams.get('redirect_uri') || '',
    scopes: urlParams.get('scopes') || '',
  } as ZapierAuthParams;
};

const handleGetZapierAuthUrlError = () => {
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: i18next.t(
        'ZapierConnectPage-string-9729-couldnt-auth-to-zapier-please-try-again-later',
      ),
    },
  });
};

export const ZapierConnectPage: React.SFC<InitAuthProps> = ({
  location: { search },
}) => {
  const { t } = useSafeTranslation();
  const adminQuery = useQuery<AdminQuery>(ADMIN_QUERY);

  return (
    <Page>
      <PageHeader />
      <PageContent className={cn(css.ZapierConnectPage, css.listMask)}>
        <ZapierIcon className={css.logo} />
        <div className={css.title}>
          {t(
            'ZapierConnectPage-JSXText--148-choose-a-bot-to-connect-to-zapier',
          )}
        </div>

        <div className={css.botList}>
          <Mutation<ZapierAuthMutation, ZapierAuthMutationVariables>
            mutation={ZAPIER_AUTH_MUTATION}
            onCompleted={({ getZapierAuthUrl: { zapierAuthUrl } }) =>
              redirect(zapierAuthUrl)
            }
            onError={handleGetZapierAuthUrlError}
          >
            {(getZapierAuthUrl, { loading }) => (
              <Query<ZapierConnectPageDataQuery>
                query={ZAPIER_CONNECT_PAGE_DATA_QUERY}
              >
                {({ data, loading: dataLoading, error }) => {
                  if (adminQuery.error || error) {
                    return (
                      <span>
                        {t('ZapierConnectPage-JSXText-6856-bots-loading-error')}
                      </span>
                    );
                  }

                  if (loading || dataLoading) {
                    return <CenteredLoader />;
                  }

                  return (
                    <>
                      {data?.bots.map((bot) => {
                        const botRoles = adminQuery.data?.me.roles?.find(
                          ({ bot_id }) => bot_id === bot.id,
                        );
                        const isNotAdmin = botRoles?.role_id !== RoleIds.admin;

                        return (
                          <div key={bot.id} className={css.botItem}>
                            <div className={css.botInfo}>
                              <div
                                className={css.botPicture}
                                style={{
                                  backgroundImage: `url(${getBotPictureUrl(
                                    bot,
                                  )})`,
                                }}
                              />
                              <div className={css.botTitle}>{bot.title}</div>
                            </div>

                            <Tooltip2
                              content={t('ZapierConnectPage.tooltip')}
                              placement="right"
                              type="small"
                              disabled={!isNotAdmin}
                              hoverable
                            >
                              {(ref, bind) => (
                                <div ref={ref} {...bind}>
                                  <Button
                                    intent="secondary"
                                    className={css.connectButton}
                                    size="s"
                                    onClick={() =>
                                      getZapierAuthUrl({
                                        variables: {
                                          params: prepareZapierAuthUrlParams(
                                            bot.id,
                                            search,
                                          ),
                                        },
                                      })
                                    }
                                    disabled={isNotAdmin || loading}
                                  >
                                    {t(
                                      'ZapierConnectPage-JSXText--135-connect',
                                    )}
                                  </Button>
                                </div>
                              )}
                            </Tooltip2>
                          </div>
                        );
                      })}
                      {/* Нужен спейсер чтобы последний айтем не загораживала тень от стиля .listMask::after */}
                      <div className={css.botItem} />
                    </>
                  );
                }}
              </Query>
            )}
          </Mutation>
        </div>
      </PageContent>
    </Page>
  );
};
