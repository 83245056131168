export const ENTERPRISE_PRICE = {
  BRL: 1499,
  MXN: 5299,
  ARS: 72499,
  COP: 1299999,
  EUR: 300,
  PEN: 1099,
  CLP: 239999,
  IDR: 4499999,
} as const;
