import React, { useCallback } from 'react';
import { useQuery } from 'react-apollo';
import { ExecutionResult } from '@apollo/react-common';
import { useUpdatePaymentDialog } from '@components/DialogsPricing/hooks';
import { PaymentDialog, PaymentIntent } from '@components/Payments';
import { PaymentFailureDialog } from '@components/DialogsPricing/dialogs';
import {
  Pricing,
  usePaymentInfo,
  useSwitchPricingTierMutation,
} from '@utils/Data/Pricing';
import { BOT_STATUS_DATA_QUERY } from '@utils/Bot';
import { sendEvent } from '@utils/Analytics';
import { SwitchPricingTierMutation } from '@utils/Data/Pricing/@types/SwitchPricingTierMutation';
import {
  BotStatusDataQuery,
  BotStatusDataQueryVariables,
} from '@utils/Bot/@types/BotStatusDataQuery';
import { Modal } from '@services/index';
import { PageBillingPeriodDialog } from '../components/BillingPeriodDialog/PageBillingPeriodDialog';
import { BillingPeriodActionType } from '../components/TierCard/types';
import { PaywallStatus, Period, TierType } from '@globals';
import { usePaywallSkip } from './usePaywallSkip';
import { updatePaywallStatusCache } from '@utils/Data/Paywall/helpers';
import Maybe from 'graphql/tsutils/Maybe';
import { assert } from '@utils/Assert';
import {
  PaymentFlowError,
  PaymentFlowErrorKind,
} from 'cf-common/src/utils/Stripe/errors';
import { useAdmitAd } from './admitAd';
import { useShareasale } from './shareasale';

interface PaywallPaymentFlowConfig {
  botId: string;
  pageId: Maybe<string>;
  pricing?: Pricing;
  onDone(): void;
}

export const usePaywallPaymentFlow = ({
  botId,
  pageId,
  pricing,
  onDone,
}: PaywallPaymentFlowConfig) => {
  const { paymentInfo } = usePaymentInfo();
  const showPaymentUpdateDialog = useUpdatePaymentDialog();

  const { skipPaywall, loading: skippingPaywall } = usePaywallSkip(pageId);
  const sendAdminAdEvent = useAdmitAd(botId);
  const { shareasaleLoading, sendShareasaleEvent } = useShareasale();
  const { switchPricingTierMutation, handleMutationError, switchingTier } =
    useSwitchPricingTierMutation({
      botId,
      onSwitchingCompleted() {
        assert(pageId, { msg: 'you can not swith tiers without pageId' });
        updatePaywallStatusCache(pageId, PaywallStatus.accepted);
      },
    });

  const { data: botStatusData } = useQuery<
    BotStatusDataQuery,
    BotStatusDataQueryVariables
  >(BOT_STATUS_DATA_QUERY, {
    variables: { botId },
    skip: !botId,
  });

  const bot = botStatusData?.bot;

  const switchTier = useCallback(
    // eslint-disable-next-line consistent-return
    async (
      nextTier: TierType,
      subscriptionPeriod: Period,
      paymentMethodId?: string,
    ) => {
      let res: ExecutionResult<SwitchPricingTierMutation> | undefined;
      try {
        res = await switchPricingTierMutation({
          variables: { botId, nextTier, subscriptionPeriod, paymentMethodId },
        });
      } catch (error) {
        handleMutationError(error as any);
        return;
      }

      const { data, errors } = res;

      if (errors?.length || !data?.switchPricingTier) {
        return;
      }

      const { stripe_error } = data.switchPricingTier;
      if (stripe_error) {
        const error = new PaymentFlowError(stripe_error.description, {
          kind: PaymentFlowErrorKind.stripeDecline,
          declineCode: stripe_error.decline_code || stripe_error.code,
        });
        Modal.show(
          ({ close, resolve }) => (
            <PaymentFailureDialog
              error={error.errorData}
              onTryAgain={() => {
                sendEvent({
                  category: 'payments',
                  action: 'click try new payment card',
                  label: 'paywall',
                  propertyBag: {
                    botId,
                    error: error.errorData,
                    nextPlan: nextTier,
                  },
                });
                close();
                resolve();
              }}
              onDismiss={() => {
                sendEvent({
                  category: 'payments',
                  action: 'click dismiss on try new payment card',
                  label: 'paywall',
                  propertyBag: { botId, error: error.errorData },
                });
                close();
              }}
            />
          ),
          {
            id: 'PaymentFailureDialog',
            mobileAdaptive: true,
          },
        )?.then(() =>
          showPaymentUpdateDialog((paymentMethodId) =>
            switchTier(nextTier, subscriptionPeriod, paymentMethodId),
          ),
        );

        return;
      }

      const tier = data.switchPricingTier;
      const isFirstPayment = !tier.previous_tier;
      if (isFirstPayment) {
        sendAdminAdEvent({
          price: tier.invoice_total!,
          priceCurrency: tier.currency,
          period: tier.current_subscription_period || Period.monthly,
        });
        sendShareasaleEvent();
      }
    },
    [
      handleMutationError,
      showPaymentUpdateDialog,
      switchPricingTierMutation,
      botId,
      sendAdminAdEvent,
      sendShareasaleEvent,
    ],
  );

  const switchTierOrUpdatePayment = useCallback(
    (nextTier: TierType, subscriptionPeriod: Period) => {
      if (!bot) return;

      if (paymentInfo?.active_card?.card4LastDigits) {
        switchTier(nextTier, subscriptionPeriod);
      } else {
        Modal.show(
          ({ close, resolve, reject }) => (
            <PaymentDialog
              paymentIntent={PaymentIntent.paywall}
              onSuccess={resolve}
              onClose={close}
              renderPaymentError={(props) => (
                <PaymentFailureDialog
                  {...props}
                  onTryAgain={() => {
                    sendEvent({
                      category: 'payments',
                      action: 'click try new payment card',
                      label: 'paywall',
                      propertyBag: {
                        bot: bot.id,
                        nextPlan: nextTier,
                      },
                    });
                    close();
                    reject();
                  }}
                  onDismiss={() => {
                    sendEvent({
                      category: 'payments',
                      action: 'click dismiss on try new payment card',
                      label: 'paywall',
                    });
                    close();
                  }}
                />
              )}
              setupPayment={(paymentMethodId) =>
                switchTier(nextTier, subscriptionPeriod, paymentMethodId)
              }
              initialPayment
            />
          ),
          {
            id: 'ShowPaymentDialog',
            mobileAdaptive: true,
          },
        )?.catch(() => {
          switchTierOrUpdatePayment(nextTier, subscriptionPeriod);
        });
      }
    },
    [bot, paymentInfo, switchTier],
  );

  const onUpgradeTier = useCallback(
    (tier: TierType, isFreeMonthByPromoCode?: boolean) => {
      sendEvent({
        category: 'payments',
        action: 'click Start free trial',
        label: 'paywall',
        propertyBag: {
          tier,
          isFreeMonthByPromoCode,
        },
      });

      if (!tier || !pricing) {
        return null;
      }

      return Modal.show(
        ({ close }) => (
          <PageBillingPeriodDialog
            paymentIntent={PaymentIntent.paywall}
            actionType={BillingPeriodActionType.upgrade}
            close={close}
            pricing={pricing}
            tierType={tier}
            currency={pricing.currency}
            onUpdate={switchTierOrUpdatePayment}
          />
        ),
        { mobileAdaptive: true },
      );
    },
    [pricing, switchTierOrUpdatePayment],
  );

  const onSkip = useCallback(async () => {
    sendEvent({
      category: 'payments',
      action: 'click skip for now',
      label: 'paywall',
    });
    const { data } = await skipPaywall();
    if (data?.skipPaywall === true) {
      onDone();
    }
  }, [onDone, skipPaywall]);

  return {
    loading: switchingTier || skippingPaywall || shareasaleLoading,
    onUpgrade: onUpgradeTier,
    onSkip,
  };
};
