import React from 'react';
import { formatPrice } from '@utils/Format/priceFormatter';
import { TierCardPriceProps } from '../types';
import { useTierPriceSize } from '../../../hooks/useTierPriceSize';
import * as css from './TierCardPrice.css';

export const TierCardPrice: React.FC<TierCardPriceProps> = ({
  price,
  currency,
  decimals = 2,
}) => {
  const sizeStyles = useTierPriceSize();
  const fp = formatPrice(price, { currency, decimals });
  const nonDigits = fp.match(/[^\d]/g) ?? [];
  const dotSymbolLastIndex = nonDigits.lastIndexOf('.');
  const commaSymbolLastIndex = nonDigits.lastIndexOf(',');
  let fpWithoutDecimals = '';
  if (!decimals) {
    fpWithoutDecimals = fp;
  } else if (commaSymbolLastIndex > dotSymbolLastIndex || currency === 'UAH') {
    fpWithoutDecimals = fp.match(/[^,]*/g)?.[0] ?? '';
  } else if (dotSymbolLastIndex > commaSymbolLastIndex) {
    fpWithoutDecimals = fp.match(/[^.]*/g)?.[0] ?? '';
  }
  const int = fpWithoutDecimals.match(/\d.*/)?.[0] ?? '';

  return (
    <div
      className={css.container}
      data-testid="plans-page__tier-card__tier-price"
    >
      <div className={css.price} style={{ lineHeight: sizeStyles.lineHeight }}>
        <span className={css.currency}>{fp.substring(0, fp.indexOf(int))}</span>
        <span style={{ fontSize: sizeStyles.fontSize }}>{int}</span>
        <span className={css.decimals}>
          {fp.substring(fp.indexOf(int) + int.length, fp.length)}
        </span>
      </div>
    </div>
  );
};
