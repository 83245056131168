import { Period, TierType } from '@globals';
import { Tier, PremiumTier } from '@utils/Data/Pricing';
import { dialogsPricingFragment } from '@utils/Data/Pricing/@types/dialogsPricingFragment';
import { PremiumPricingInfoQuery_premiumPricingInfo } from '@utils/Data/Pricing/@types/PremiumPricingInfoQuery';

export interface TierCardProps {
  readOnly: boolean;
  tier: Tier;
  selectedTier?: Tier;
  nextTier?: Tier;
  botIsPaused: boolean;
  currency: string;
  discount?: string | null;
  disabledByUnpausing?: boolean;
  disabledBySwitching?: boolean;
  onDowngrade(tier: TierType, period: Period): void;
  onUnpause(): void;
  onUpgrade(tier: TierType, period: Period): void;
  pricing?: dialogsPricingFragment;
  showYearPrices?: null | boolean;
  checkAvailableFreeMonth?: (tierType: TierType) => boolean;
}

export interface PremiumTierCardProps {
  tier: PremiumTier;
  selectedTier?: PremiumTier;
  nextTier?: PremiumTier;
  currency: string;
  pricing: PremiumPricingInfoQuery_premiumPricingInfo;
  disabledBySwitching: boolean;
  onUpgrade(tier: TierType, period: Period): void;
  onDowngrade(tier: TierType, period: Period): void;
}

export interface PremiumTierCardActionProps extends PremiumTierCardProps {
  isCurrentTierSelected: boolean;
  isCurrentTierNext: boolean;
  moreExpensiveThanCurrent: boolean;
}

export interface TierCardActionProps extends TierCardProps {
  isCurrentTierSelected: boolean;
  isCurrentTierNext: boolean;
  moreExpensiveThanCurrent: boolean;
}

export interface TierCardBadgeProps {
  type: TierType;
  isCurrentTierSelected: boolean;
  checkAvailableFreeMonth?: (tierType: TierType) => boolean;
  discount?: string | null;
}

export interface TierCardOptionsProps extends TierCardProps {
  isTrialTier: boolean;
}

export interface TierCardPriceProps {
  price: number;
  currency: string;
  decimals?: number;
}

export interface PremiumTierCardLabelProps {
  currentSubscriptionPeriod: Period | null | undefined;
  nextPrepaymentDate: number | null | undefined;
  hasNextTier: boolean;
  isCurrentTierSelected: boolean;
  isCurrentTierNext: boolean;
}

export interface TierCardLabelProps extends TierCardProps {
  isCurrentTierSelected: boolean;
  isCurrentTierNext: boolean;
  isStartsAfterTrial: boolean;
}

export enum BillingPeriodActionType {
  upgrade = 'upgrade',
  downgrade = 'downgrade',
  change = 'change',
}
