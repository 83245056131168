import React, { useEffect } from 'react';
import {
  BillingPeriodDialog,
  BillingPeriodDialogProps,
} from './BillingPeriodDialog';
import { usePremiumPricingPeriodList } from '@utils/Data/Pricing/hooks';
import { sendEvent } from '@utils/Analytics';

interface PremiumBillingDialogProps
  extends Omit<BillingPeriodDialogProps, 'loading' | 'pricePeriodList'> {}

export const PremiumBillingPeriodDialog: React.FC<PremiumBillingDialogProps> =
  ({
    actionType,
    close,
    currency,
    onUpdate,
    tierType,
    isCurrentTierSelected,
    pricing,
    initialPeriod,
  }) => {
    const { data, loading } = usePremiumPricingPeriodList(tierType);

    const currentPeriod = pricing?.current_subscription_period;

    useEffect(() => {
      sendEvent({
        category: 'premium pricing',
        action: 'open billing period dialog',
        label: 'new workspace pricing',
        propertyBag: {
          currentPeriod,
          currentPlan: tierType,
        },
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <BillingPeriodDialog
        actionType={actionType}
        close={close}
        pricing={pricing}
        tierType={tierType}
        currency={currency}
        isCurrentTierSelected={isCurrentTierSelected}
        loading={loading}
        onUpdate={onUpdate}
        pricePeriodList={data}
        initialPeriod={initialPeriod}
        isYearPrices
      />
    );
  };
