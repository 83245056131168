import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { TierType } from '@globals';
import { TierCardBadgeProps } from '../types';
import { Flex } from '@ui/Flex';
import css from './TierCardBadge.css';

export const TierCardBadge: React.FC<TierCardBadgeProps> = ({
  type,
  isCurrentTierSelected,
  checkAvailableFreeMonth,
  discount,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex className={css.container} gapFactor={1}>
      {!isCurrentTierSelected && type === TierType.startup && (
        <Type
          size="12px"
          color="white"
          weight="medium"
          className={cn(css.badge, css.mostPopular)}
          data-testid="plans-page__tier-card__tier-badge"
        >
          {discount
            ? t('pages.Pricing.popular')
            : t('pages.Pricing.mostPopular')}
        </Type>
      )}
      {checkAvailableFreeMonth?.(type) && (
        <Type
          size="12px"
          color="white"
          weight="medium"
          className={cn(css.badge, css.freeMonth)}
          data-testid="plans-page__tier-card__tier-badge"
        >
          {discount
            ? t('pages.Pricing.monthDiscount', { discount })
            : t('pages.Pricing.freeMonth')}
        </Type>
      )}
    </Flex>
  );
};
