import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { path } from 'ramda';
import { log } from 'cf-common/src/logger';
import { InitAuthProps } from '@utils/AuthFlow';
import { AuthFlowPage } from '@ui/Page';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { locationRedirectToBot } from '@utils/WhiteLabelUtils';
import { getCurrentDomain } from '@utils/UrlUtils';
import { SET_AUTH_MUTATION } from '@utils/AuthFlow/queries';
import { Messages, toaster } from '../../../services/MessageService';
import {
  LoginWhiteLabelMutation,
  LoginWhiteLabelMutationVariables,
} from './@types/LoginWhiteLabelMutation';
import {
  LoginErrors,
  LoginFormMode,
  LoginFormWhiteLabel,
} from './LoginFormLoginWhiteLabel';

const LOGIN_WHITE_LABEL_MUTATION = gql`
  mutation LoginWhiteLabelMutation(
    $login: String!
    $password: String!
    $domain: String!
  ) {
    loginWhiteLabel(login: $login, password: $password, domain: $domain) {
      token
    }
  }
`;

export const LoginWhiteLabel: React.FC<InitAuthProps> = ({ client, match }) => {
  const login = path<string | undefined>(['params', 'login'], match);
  const [loginError, setLoginError] = useState<LoginErrors | undefined>();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  return (
    <Mutation<LoginWhiteLabelMutation, LoginWhiteLabelMutationVariables>
      mutation={LOGIN_WHITE_LABEL_MUTATION}
      onError={(error) => {
        log.warn({
          error,
          msg: 'Error while generating token on white label login page',
        });

        const status = path<number | undefined>(
          ['graphQLErrors', 0, 'extensions', 'response', 'status'],
          error,
        );

        if (status === 404) {
          setLoginError(LoginErrors.login);
        } else if (status === 401) {
          setLoginError(LoginErrors.password);
        } else {
          log.warn({
            msg: 'Unexpected error while generating token on white label login page',
          });
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: Messages.somethingWentWrong,
            },
          });
        }
      }}
      onCompleted={async (data) => {
        const { token } = data && data.loginWhiteLabel;
        try {
          setRedirecting(true);
          await client.mutate({
            mutation: SET_AUTH_MUTATION,
            variables: { token },
          });
          locationRedirectToBot();
        } catch (error) {
          log.warn({
            error,
            msg: 'Error while setting token on white label login page',
          });
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: Messages.somethingWentWrong,
            },
          });
        }
      }}
    >
      {(authWhiteLabel, { loading }) => {
        return (
          <AuthFlowPage
            logoSize={LogoSize.big}
            render={() => (
              <LoginFormWhiteLabel
                login={login}
                disabled={loading || redirecting}
                error={loginError}
                mode={LoginFormMode.login}
                onLoginRequest={(variables) => {
                  authWhiteLabel({
                    variables: {
                      ...variables,
                      domain: getCurrentDomain(),
                    },
                  });
                }}
              />
            )}
          />
        );
      }}
    </Mutation>
  );
};
