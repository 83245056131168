import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useShopifyOnboardingState } from './useShopifyOnboardingState';

export const useShopifyIntroQuestionsOverrides = () => {
  const { getState } = useShopifyOnboardingState();
  const history = useHistory();
  const location = useLocation();

  const handleIntroQuestionsDone = useCallback(
    (defaultHandler?: VoidFunction) => {
      const action = {
        done: defaultHandler,
        started: () =>
          history.push({
            ...location,
            pathname: '/oauth/shopify/choose-bot',
          }),
      }[getState()];

      return action?.();
    },
    [getState, history, location],
  );

  const getShopifyIntroQuestionsSchemaType = (defaultSchema?: string) => {
    return {
      done: defaultSchema,
      started: 'shopify-onboarding',
    }[getState()];
  };

  return { handleIntroQuestionsDone, getShopifyIntroQuestionsSchemaType };
};
