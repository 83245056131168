import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { TierCardPrice } from '../TierCardPrice';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TierCardBadge } from '../TierCardBadge';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { Tier } from '@utils/Data/Pricing';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { formatPriceEx } from '@utils/Format/priceFormatter';
import { TierType } from '@globals';
import * as css from './PaywallTierCard.css';

interface PaywallTierCardProps {
  tier: Tier;
  currency: string;
  onStartFreeTrial(): void;
  loading?: boolean;
  checkAvailableFreeMonth?: (tierType: TierType) => boolean;
}

export const PaywallTierCard: React.FC<PaywallTierCardProps> = ({
  tier,
  currency,
  onStartFreeTrial,
  loading,
  checkAvailableFreeMonth,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize, getValueDueToSize } = useDeviceMedia();
  const padding = getValueDueToSize(16, 20, 24);

  return (
    <Flex
      style={{ padding, paddingTop: 20 }}
      className={css.root}
      flexDirection="column"
      alignItems="center"
    >
      <Type
        size="18px"
        weight="semibold"
        align="center"
        data-testid="plans-page__tier-card__tier-name"
      >
        {getTierNameByType(tier.type)}
      </Type>
      <Spacer factor={2} />
      <TierCardBadge
        type={tier.type}
        isCurrentTierSelected={false}
        checkAvailableFreeMonth={checkAvailableFreeMonth}
      />
      <Spacer factor={3} />
      <Type size="15px">
        {t('pages.FirstSession.PlansPaywall.tierCard.from')}
      </Type>
      <TierCardPrice currency={currency} price={tier.tier_price} />
      {!isSmallScreenSize && <Spacer factor={3} />}
      <Type size="15px" className={css.tierDialogs}>
        {t('pages.FirstSession.PlansPaywall.tierCard.dialogsCount', {
          count: tier.tier_dialogs,
        })}
      </Type>
      <Type size="15px" color="greyDark" align="center">
        {t('pages.FirstSession.PlansPaywall.tierCard.pricePerDialog', {
          price: formatPriceEx(tier.price_per_dialog, currency),
        })}
      </Type>
      <Button
        style={{ bottom: padding }}
        className={css.actionButton}
        onClick={onStartFreeTrial}
        loading={loading}
        disabled={loading}
        data-testid="plans-page__tier-card__start-trial-btn"
      >
        {t('pages.FirstSession.PlansPaywall.tierCard.startFreeTrial')}
      </Button>
    </Flex>
  );
};
