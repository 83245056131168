import React, { useContext, useMemo } from 'react';

interface TierPriceSizeConfig {
  fontSize: PriceFontSizeKey;
  lineHeight: string;
}

type PriceFontSizeKey = keyof typeof PriceFontSize;

enum PriceFontSize {
  '28px' = '28px',
  '34px' = '34px',
  '44px' = '44px',
}

const getSizeByPrice = (price: number): TierPriceSizeConfig => {
  const count = parseInt(price.toString(), 10).toString().length;
  if (count < 4) {
    return { fontSize: '44px', lineHeight: '58px' };
  }
  if (count < 6) {
    return { fontSize: '34px', lineHeight: '44px' };
  }
  return { fontSize: '28px', lineHeight: '36px' };
};

const DEFAULT_CONFIG = getSizeByPrice(0);

const TierPriceSizeContext =
  React.createContext<TierPriceSizeConfig>(DEFAULT_CONFIG);

export const TierPriceSizeProvider: React.FC<{ maxPrice: number }> = ({
  maxPrice,
  children,
}) => {
  const config = useMemo(() => getSizeByPrice(maxPrice), [maxPrice]);

  return (
    <TierPriceSizeContext.Provider value={config}>
      {children}
    </TierPriceSizeContext.Provider>
  );
};

export const useTierPriceSize = () => useContext(TierPriceSizeContext);
