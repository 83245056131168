import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  BotListIcon,
  BotListIconProps,
  BotListSubtitle,
  BotListSubtitleBot,
} from '@components/BotList/BotList';
import * as css from './ShopifyChooseBotList.css';

export interface ShopifyBot
  extends Omit<BotListIconProps, 'picture'>,
    BotListSubtitleBot {
  id: string;
}

export interface ShopifyChooseBotListProps {
  bots: ShopifyBot[];
  isConnecting: boolean;
  onConnectClick: (bot: ShopifyBot) => void;
}

export const ShopifyChooseBotList: React.FC<ShopifyChooseBotListProps> = ({
  bots,
  isConnecting,
  onConnectClick,
}) => {
  const { t } = useSafeTranslation();

  const isOnlyOneBot = bots.length === 1;

  return (
    <ul className={css.ul}>
      {bots.map((bot) => (
        <li key={bot.id} className={css.li}>
          <Flex justifyContent="space-between">
            <Flex>
              <BotListIcon picture={bot.pageInfo?.picture} title={bot.title} />
              <Flex
                flexDirection="column"
                justifyContent="center"
                className={css.informationWrapper}
              >
                <Type weight="medium">{bot.title}</Type>
                <Spacer factor={1} />
                <Type color="baseTertiary" className={css.subtitle}>
                  <BotListSubtitle {...bot} color="baseTertiary" />
                </Type>
              </Flex>
            </Flex>
            <Button
              data-testid="shopify-choose-bot__connect"
              intent={isOnlyOneBot ? 'primary' : 'secondary'}
              className={css.button}
              loading={isConnecting}
              disabled={isConnecting}
              onClick={() => onConnectClick(bot)}
            >
              {t('ShopifyOnboarding.chooseBot.connect')}
            </Button>
          </Flex>
        </li>
      ))}
    </ul>
  );
};
