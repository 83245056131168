import React from 'react';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './PaywallTierCard.css';

export const PaywallTierCardLoader = React.memo(() => {
  const { getValueDueToSize } = useDeviceMedia();
  const padding = getValueDueToSize(16, 20, 24);

  return (
    <LoadingPlaceholder
      color="white"
      className={css.root}
      style={{ padding, paddingTop: 20 }}
    />
  );
});
