import React, { useEffect } from 'react';
import { Type } from '@ui/Type';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { Button } from '../../modern-ui/Button';
import { Flex } from '../../modern-ui/Flex';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/zero-state/exclamation-mark.svg';
import * as css from './Restricted.css';

export const Resctricted = () => {
  const { t } = useSafeTranslation();

  useEffect(() => {
    sendEvent({
      category: 'restricted-page',
      action: 'visited',
    });
    removeGlobalLoader();
  }, []);

  return (
    <Flex
      className={css.container}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <ExclamationMarkIcon />

      <Type as="div" className={css.message} size="24px" weight="semibold">
        {t('Restricted.header')}
      </Type>

      <Type as="div" className={css.message} size="24px" weight="regular">
        {t('Restricted.contact-support')}{' '}
        <a className={css.link} href="mailto:team@chatfuel.com">
          team@chatfuel.com
        </a>{' '}
        {t('Restricted.more-details')}
      </Type>

      <Button
        intent="primary"
        onClick={() => {
          sendEvent({
            category: 'restricted-page',
            action: 'support-click',
          });
          window.location.assign('mailto:team@chatfuel.com');
        }}
      >
        {t('Restricted.contact')}
      </Button>
    </Flex>
  );
};
