import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from '@apollo/react-components';
import { path, pathOr, propEq } from 'ramda';
import { useAdminFeatures } from '@utils/Data/Admin';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { showIntercom } from '@utils/Intercom';
import { getDefaultTab } from '@utils/Routing';
import { log } from 'cf-common/src/logger';
import { Page } from '../../../modern-ui/Page';
import { InitAuthProps } from '../../../utils/AuthFlow';
import { PageHeader } from '../../../modern-components/PageHeader';
import { PageContent } from '../../../modern-ui/PageContent';
import { Flex } from '../../../modern-ui/Flex';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import * as css from './AgencyWhiteLabel.css';
import {
  AgencyWhiteLabelInfoQuery,
  AgencyWhiteLabelInfoQuery_bots as Bot,
  AgencyWhiteLabelInfoQuery_whiteLabelAgency as WhiteLabelAgency,
  AgencyWhiteLabelInfoQuery_whiteLabelAgency_bots as WhiteLabelAgencyBot,
} from './@types/AgencyWhiteLabelInfoQuery';
import { plurals } from '../../../utils/Plurals';
import {
  DeleteWhiteLabelBotMutation,
  DeleteWhiteLabelBotMutationVariables,
} from './@types/DeleteWhiteLabelBotMutation';
import { Messages, toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { RemoveEntityDialog } from '../../../modern-components/RemoveEntityDialog';
import { Modal } from '../../../modern-ui/Modal';
import { isWhiteLabelDomain } from '../../../utils/WhiteLabelUtils';
import { getCurrentDomain } from '../../../utils/UrlUtils';
import { Oops } from '../../Oops';
import { CenteredLoader } from '../../../modern-ui/Loader';

const ADMINS_SHOW_LIMIT = 2;

const AGENCY_WHITE_LABEL_INFO_QUERY = gql`
  query AgencyWhiteLabelInfoQuery($domain: String) {
    bots {
      id
    }
    whiteLabelAgency(domain: $domain) {
      id
      bot_ids
      bots {
        id
        title
        logins
        subscribers
      }
    }
  }
`;

const DELETE_WHITE_LABEL_BOT_MUTATION = gql`
  mutation DeleteWhiteLabelBotMutation($botId: String!) {
    deleteWhiteLabelBot(botId: $botId)
  }
`;

const isCurrentUserBotAdmin = (bots: Bot[], { id }: WhiteLabelAgencyBot) =>
  bots.some(propEq('id', id));

export const AgencyWhiteLabel: React.FC<InitAuthProps> = () => {
  const { adminFeatures, adminFeaturesError } = useAdminFeatures();
  return (
    <Page>
      <PageHeader />
      <Query<AgencyWhiteLabelInfoQuery>
        query={AGENCY_WHITE_LABEL_INFO_QUERY}
        variables={{
          domain: isWhiteLabelDomain() ? getCurrentDomain() : undefined,
        }}
        onCompleted={() => {
          removeGlobalLoader();
        }}
        onError={() => {
          removeGlobalLoader();
        }}
      >
        {({ data, error, loading }) => {
          if (error || adminFeaturesError) {
            return (
              <Oops
                reason="Error loading agency data"
                errorText={error ? error.message : ''}
              />
            );
          }

          if (loading) {
            return <CenteredLoader />;
          }

          const whiteLabelAgency = path<WhiteLabelAgency | undefined>(
            ['whiteLabelAgency'],
            data,
          );
          const bots = pathOr<Bot[]>([], ['bots'], data);
          if (!whiteLabelAgency) {
            return null;
          }
          const whiteLabelAgencyBots = pathOr<WhiteLabelAgencyBot[]>(
            [] as WhiteLabelAgencyBot[],
            ['bots'],
            whiteLabelAgency,
          );
          let totalSubscribersQty = 0;
          return (
            <PageContent className={css.content}>
              <Flex justifyContent="space-between" className={css.head}>
                <div className={css.title}>
                  {window.i18next.t(
                    'AgencyWhiteLabel-JSXText--133-white-label-plan',
                  )}
                </div>
                <Button
                  intent={ButtonIntent.primary}
                  colorWay={ButtonColorWay.blue}
                  onClick={() => showIntercom()}
                >
                  {window.i18next.t(
                    'AgencyWhiteLabel-JSXText--139-contact-us-to-upgrade',
                  )}
                </Button>
              </Flex>
              <table className={css.table}>
                <thead>
                  <tr>
                    <th>
                      {window.i18next.t(
                        'AgencyWhiteLabel-JSXText-1463-bots-title',
                      )}
                    </th>
                    <th>
                      {window.i18next.t(
                        'AgencyWhiteLabel-JSXText--178-subscribers',
                      )}
                    </th>
                    <th>
                      {window.i18next.t(
                        'AgencyWhiteLabel-JSXText-1087-client-logins',
                      )}
                    </th>
                    <th>
                      {window.i18next.t(
                        'AgencyWhiteLabel-JSXText-5028-actions',
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {whiteLabelAgencyBots.map((bot: WhiteLabelAgencyBot) => {
                    totalSubscribersQty += bot.subscribers;
                    const overLimitBotAdminsQty =
                      bot.logins.length - ADMINS_SHOW_LIMIT;
                    return (
                      <tr key={bot.id}>
                        <td>
                          {isCurrentUserBotAdmin(bots, bot) ? (
                            <a
                              href={getDefaultTab(bot.id)}
                              title={bot.title}
                              className={css.botLink}
                            >
                              {bot.title}
                            </a>
                          ) : (
                            <span className={css.botName}>{bot.title}</span>
                          )}
                        </td>
                        <td>{bot.subscribers.toLocaleString()}</td>
                        <td>
                          {bot.logins
                            .slice(0, ADMINS_SHOW_LIMIT)
                            .map((login) => (
                              <div key={login} className={css.userLogin}>
                                {login}
                              </div>
                            ))}
                          {overLimitBotAdminsQty > 0 && (
                            <div className={css.overLimitBotAdminsQty}>
                              {window.i18next.t(
                                'AgencyWhiteLabel-JSXText-5491-and',
                              )}
                              {plurals(
                                overLimitBotAdminsQty,
                                window.i18next.t(
                                  'AgencyWhiteLabel-string--142-more-login',
                                ),
                                window.i18next.t(
                                  'AgencyWhiteLabel-string--136-more-logins',
                                ),
                              )}
                            </div>
                          )}
                        </td>
                        <td>
                          {isCurrentUserBotAdmin(bots, bot) && (
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            <DeleteWhiteLabelBotButton bot={bot} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {whiteLabelAgencyBots.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        {window.i18next.t(
                          'AgencyWhiteLabel-JSXText-1155-you-dont-have-white-label-bot-yet-use-configure-tab-to-invite-client',
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <span>
                        {window.i18next.t(
                          'AgencyWhiteLabel-JSXText-4725-bots-used',
                        )}
                      </span>
                      <span className={css.black}>
                        {` ${whiteLabelAgencyBots.length}${window.i18next.t(
                          'AgencyWhiteLabel-Template-1013-from',
                        )}${
                          adminFeatures
                            ? adminFeatures.max_bots_count
                            : window.i18next.t(
                                'AgencyWhiteLabel-string-4081-loading',
                              )
                        }`}
                      </span>
                    </td>
                    <td colSpan={3}>
                      <span>
                        {window.i18next.t(
                          'AgencyWhiteLabel-JSXText--178-subscribers',
                        )}
                      </span>
                      <span className={css.black}>
                        &nbsp;
                        {totalSubscribersQty}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </PageContent>
          );
        }}
      </Query>
    </Page>
  );
};

const DeleteWhiteLabelBotButton: React.FC<{ bot: WhiteLabelAgencyBot }> = ({
  bot,
}) => {
  const [showDeleteBotDialog, setShowDeleteBotDialog] =
    useState<boolean>(false);
  return (
    <Mutation<DeleteWhiteLabelBotMutation, DeleteWhiteLabelBotMutationVariables>
      mutation={DELETE_WHITE_LABEL_BOT_MUTATION}
      variables={{
        botId: bot.id,
      }}
      onError={(error) => {
        log.warn({ error, msg: 'Error delete white label bot' });
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: Messages.somethingWentWrong,
          },
        });
      }}
      optimisticResponse={{
        deleteWhiteLabelBot: bot.id,
      }}
      update={(cache) => {
        const data = cache.readQuery<AgencyWhiteLabelInfoQuery>({
          query: AGENCY_WHITE_LABEL_INFO_QUERY,
        });
        const agencyBots = path<WhiteLabelAgencyBot[] | undefined>(
          ['whiteLabelAgency', 'bots'],
          data,
        );
        if (agencyBots) {
          data!.whiteLabelAgency!.bots = agencyBots.filter(
            (_bot) => _bot.id !== bot.id,
          );
        }
        cache.writeQuery({
          data,
          query: AGENCY_WHITE_LABEL_INFO_QUERY,
        });
      }}
    >
      {(deleteBot, { loading }) => (
        <>
          <Button
            colorWay={ButtonColorWay.white}
            size={ButtonSize.s}
            intent={ButtonIntent.primary}
            disabled={loading}
            onClick={() => setShowDeleteBotDialog(true)}
          >
            {window.i18next.t('AgencyWhiteLabel-JSXText--750-delete-from-plan')}
          </Button>
          {showDeleteBotDialog && (
            <Modal onDismiss={() => setShowDeleteBotDialog(false)}>
              <RemoveEntityDialog
                renderHeading={() =>
                  `${window.i18next.t(
                    'AgencyWhiteLabel-Template--207-delete-bot',
                  )}${bot.title}${window.i18next.t(
                    'AgencyWhiteLabel-Template-1357-from-plan',
                  )}`
                }
                renderActionText={() =>
                  `${window.i18next.t('AgencyWhiteLabel-Template-2043-delete')}`
                }
                renderNoteText={() =>
                  `${window.i18next.t(
                    'AgencyWhiteLabel-Template-6541-when-you-delete-the-bot-from-the-plan-you-remove-all-assigned-white-label-users',
                  )}`
                }
                onSubmit={() => {
                  setShowDeleteBotDialog(false);
                  deleteBot();
                }}
                onDismiss={() => setShowDeleteBotDialog(false)}
              />
            </Modal>
          )}
        </>
      )}
    </Mutation>
  );
};
