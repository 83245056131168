import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsNeedRedirectToFirstSession } from '@utils/AuthFlow/hooks/useIsNeedRedirectToFirstSession';
import { useShopifyOnboardingState } from './useShopifyOnboardingState';

export const useShopifyChooseBotRedirect = () => {
  const location = useLocation();
  const history = useHistory();
  const { redirectTo, needRedirect, loading } =
    useIsNeedRedirectToFirstSession();
  const { startOnboarding } = useShopifyOnboardingState();

  useEffect(() => {
    if (!needRedirect) {
      return;
    }

    /**
     * В данном случае, нельзя делать редирект на `redirectTo` который вернет хук {@link useIsNeedRedirectToFirstSession}
     * так как роут интеграции шопифая /oauth/shopify/choose-bot, хук {@link useCurrentBotId} матчит `choose-bot`
     * как botId, и хук не вернет `redirectTo` который содержит не валидный урл. Поэтому надо делать редирект на
     * /redirect-to-bot. Так как у юзера должна начаться первая сессия, следовательно юзер новый, и бот у него один,
     * поэтому редирект произойдет в единственного бота на табу home, из которой, юзер попадет в first-session.
     */
    history.push({
      ...location,
      search: startOnboarding(location.search),
      pathname: '/redirect-to-bot',
    });
  }, [redirectTo, history, needRedirect, location, startOnboarding]);

  return { loading };
};
