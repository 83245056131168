import React from 'react';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { PricingPlanType } from './types';
import { ReactComponent as ChartIcon } from './assets/Chart.svg';
import { ReactComponent as KnowledgeIcon } from './assets/Knowledge.svg';
import { ReactComponent as PricingIcon } from './assets/Pricing.svg';
import { ReactComponent as SupportIcon } from './assets/Support.svg';
import { TierSubtype } from '@globals';

export const PRICING_PLAN_QUERY_PARAM = 'plan';

export const getPlanTabs = memoize(() => [
  {
    id: PricingPlanType.business,
    title: i18next.t('pages.Pricing.plan.business'),
  },
  {
    id: PricingPlanType.enterprise,
    title: i18next.t('pages.Pricing.plan.enterprise'),
  },
]);

export const getPremiumPlanTabs = memoize(() => [
  {
    id: TierSubtype.premium,
    title: i18next.t('pages.PremiumPricing.plan.premium'),
  },
  {
    id: TierSubtype.agency,
    title: i18next.t('pages.PremiumPricing.plan.agency'),
  },
]);

const dimensions = {
  width: 98,
  height: 92,
};

export const getEnterpriseCards = memoize(() => [
  {
    title: i18next.t('pages.Pricing.enterpriseCards.customizedSolution.title'),
    description: i18next.t(
      'pages.Pricing.enterpriseCards.customizedSolution.description',
    ),
    icon: <ChartIcon style={{ ...dimensions, paddingLeft: 14 }} />,
  },
  {
    title: i18next.t('pages.Pricing.enterpriseCards.expertGuidance.title'),
    description: i18next.t(
      'pages.Pricing.enterpriseCards.expertGuidance.description',
    ),
    icon: <KnowledgeIcon style={dimensions} />,
  },
  {
    title: i18next.t('pages.Pricing.enterpriseCards.personalPricing.title'),
    description: i18next.t(
      'pages.Pricing.enterpriseCards.personalPricing.description',
    ),
    icon: <PricingIcon style={dimensions} />,
  },
  {
    title: i18next.t('pages.Pricing.enterpriseCards.prioritySupport.title'),
    description: i18next.t(
      'pages.Pricing.enterpriseCards.prioritySupport.description',
    ),
    icon: <SupportIcon style={dimensions} />,
  },
  {
    title: i18next.t('pages.Pricing.enterpriseCards.allBenefits.title'),
    description: i18next.t(
      'pages.Pricing.enterpriseCards.allBenefits.description',
    ),
    icon: <ChartIcon style={{ ...dimensions, paddingLeft: 14 }} />,
  },
]);

export const CHATFUEL_DEMO_URL = 'https://tiny.cc/chatfueldemo';
