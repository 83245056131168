import React from 'react';
import { NavLink } from 'react-router-dom';

import { WhiteLabelUserData } from '@utils/WhiteLabelUtils';

import { LinkComponentProps } from '@components/PageHeader/PageHeaderNav';

import * as css from '../../../../modern-components/PageHeader/PageHeaderNav/PageHeaderNav.css';

interface AgencyLinkWhiteLabelProps extends LinkComponentProps {
  noUseRouter?: boolean;
}

export const AgencyLinkWhiteLabel: React.FC<AgencyLinkWhiteLabelProps> = ({
  title,
  url,
  noUseRouter,
  sendEvent,
}) => (
  <WhiteLabelUserData>
    {({ isWhiteLabelAdmin }) =>
      isWhiteLabelAdmin === true ? (
        noUseRouter ? (
          <a href={url} title={title} className={css.link} onClick={sendEvent}>
            {title}
          </a>
        ) : (
          <NavLink
            to={url}
            className={css.link}
            activeClassName={css.active}
            onClick={sendEvent}
          >
            {title}
          </NavLink>
        )
      ) : null
    }
  </WhiteLabelUserData>
);
