import React, { useCallback } from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { WhatsappTierCard } from './components/WhatsappTiersList/components/WhatsappTierCard';
import { TierCardPrice } from './components/TierCard/TierCardPrice';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './PlansShopifyPage.css';
import { formatPrice } from '@utils/Format';
import { useShopifyPricingPlan } from './hooks/useShopifyPricingPlan';
import { CenteredLoader } from '@ui/Loader';
import { Type } from '@ui/Type';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { ShopifySubscriptionStatus } from '@globals';
import { useCreateAndConfirmShopifySubscription } from '@utils/Integrations/Shopify/useCreateAndConfirmShopifySubscription';
import { sendEvent } from '@utils/Analytics';

const SHOPIFY_TAB = '_shopify_tab';

export const PlansShopifyPage: React.FC = () => {
  const { t } = useSafeTranslation();
  const { shopifyPricingPlan: pricing, loading: shopifyPricingPlanLoading } =
    useShopifyPricingPlan();
  const { account, loading: shopifyAccountLoading } = useShopifyAccount();
  const { createAndConfirmShopifySubscription, loading: creating } =
    useCreateAndConfirmShopifySubscription();

  const shopifyUserDomain = account?.myshopify_domain.split('.')[0];
  const status = account?.subscription?.status;
  const isNeedActivateSubscription =
    status !== ShopifySubscriptionStatus.active;
  const isNeedShowTrialInfo =
    account?.eligible_for_trial &&
    (isNeedActivateSubscription ||
      (account.subscription?.trialEndsOn || 0) > Date.now());

  const buttonClickHandler = useCallback(() => {
    sendEvent({
      category: 'payment',
      action: 'Open Shopify billing click',
    });

    if (isNeedActivateSubscription) {
      createAndConfirmShopifySubscription();
      return;
    }
    if (shopifyUserDomain) {
      window.open(
        `https://admin.shopify.com/store/${shopifyUserDomain}/settings/billing/subscriptions`,
        SHOPIFY_TAB,
      );
    }
  }, [
    createAndConfirmShopifySubscription,
    isNeedActivateSubscription,
    shopifyUserDomain,
  ]);

  if (!pricing || shopifyPricingPlanLoading || shopifyAccountLoading) {
    return <CenteredLoader className={css.box} />;
  }

  return (
    <Flex justifyContent="center" className={css.box}>
      <div>
        <Spacer factor={20} />
        <WhatsappTierCard
          name={pricing.subscriptionName}
          price={
            <TierCardPrice
              currency={pricing.subscriptionCurrency}
              price={parseFloat(pricing.subscriptionPrice)}
              decimals={0}
            />
          }
          conversations={t('pages.Pricing.whatsapp.convercationsMO', {
            count: pricing.includedConversations,
          })}
          extraConversations={formatPrice(
            parseFloat(pricing.extraDialogPrice),
            {
              currency: pricing.subscriptionCurrency,
              decimals: 2,
            },
          )}
          button={
            <Button
              intent="primary"
              onClick={buttonClickHandler}
              loading={creating}
            >
              {t('pages.Pricing.shopify.button')}
            </Button>
          }
          description={
            isNeedShowTrialInfo ? (
              <>
                <Type className={css.badge} color="purpleText" as="div">
                  {t('pages.Pricing.shopify.freeTrial', {
                    count: pricing.subscriptionTrialDays,
                  })}
                </Type>
                <Spacer factor={4} />
              </>
            ) : undefined
          }
          hideFrom
        />
      </div>
    </Flex>
  );
};
