import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { PremiumTierCard, PremiumTierCardLoader } from '../TierCard';
import { SwiperSliderWrapper } from '@ui/SwiperSliderWrapper/SwiperSliderWrapper';
import { PremiumTier } from '@utils/Data/Pricing';
import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { usePremiumPricingInfo } from '@utils/Data/Pricing/hooks';
import { sendEvent } from '@utils/Analytics';
import { usePremiumPaymentFlow } from '../../hooks/usePremiumPaymentFlow';
import { TierPriceSizeProvider } from '../../hooks/useTierPriceSize';
import * as css from './PremiumTierList.css';

interface PremiumTierList {
  loading: boolean;
  tiers: PremiumTier[];
  currency: string | null | undefined;
}

const MAX_TIERS_TO_DISPLAY = 4;

export const PremiumTierList: React.FC<PremiumTierList> = ({
  loading,
  currency,
  tiers,
}) => {
  const { data: pricing, loading: pricingLoading } = usePremiumPricingInfo();
  const currentTierIndex = tiers.findIndex(
    (tier) => tier.type === pricing?.current_tier,
  );
  const currentTier =
    currentTierIndex !== -1 ? tiers[currentTierIndex] : undefined;
  const nextTier = tiers.find((tier) => tier.type === pricing?.next_tier);

  const { onDowngradeTier, onUpgradeTier, switchingTier } =
    usePremiumPaymentFlow({ currentTier, tiers, pricing });

  const { isSmallScreenSize } = useDeviceMedia();
  const swiperRef = useRef<SwiperClass>();
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const setBoundaries = () => {
    const { isBeginning = false, isEnd = false } = swiperRef.current ?? {};
    setIsBeginning(isBeginning);
    setIsEnd(isEnd);
  };

  const onRefChange = (value: SwiperClass) => {
    swiperRef.current = value;
    setBoundaries();
  };

  const getTiersList = () => {
    if (loading || pricingLoading || !pricing || !currency) {
      return Array.from({ length: MAX_TIERS_TO_DISPLAY }).map(
        (_, index, arr) => (
          <React.Fragment
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {(index !== 0 || isSmallScreenSize) && (
              <Spacer factor={0} horizontalFactor={5} />
            )}
            <PremiumTierCardLoader />
            {index === arr.length - 1 && isSmallScreenSize && (
              <Spacer factor={0} horizontalFactor={5} />
            )}
          </React.Fragment>
        ),
      );
    }

    const Tiers = tiers.map((tier, index, arr) => (
      <React.Fragment key={tier.type}>
        {(index !== 0 || isSmallScreenSize) && (
          <Spacer factor={0} horizontalFactor={5} />
        )}
        <PremiumTierCard
          tier={tier}
          nextTier={nextTier}
          selectedTier={currentTier}
          currency={currency}
          pricing={pricing}
          onUpgrade={(tier, period) => {
            sendEvent({
              category: 'premium pricing',
              action: 'click upgrade plan',
              label: 'new workspace pricing',
              propertyBag: {
                currentPlan: currentTier?.type,
                nextPlan: tier,
                currentPeriod: pricing?.current_subscription_period,
                nextPriod: period,
              },
            });
            onUpgradeTier(tier, period);
          }}
          onDowngrade={(tier, period) => {
            sendEvent({
              category: 'premium pricing',
              action: 'click downgrade plan',
              label: 'new workspace pricing',
              propertyBag: {
                currentPlan: currentTier?.type,
                nextPlan: tier,
                currentPeriod: pricing?.current_subscription_period,
                nextPriod: period,
              },
            });
            onDowngradeTier(tier, period);
          }}
          disabledBySwitching={switchingTier}
        />
        {index === arr.length - 1 && isSmallScreenSize && (
          <Spacer factor={0} horizontalFactor={5} />
        )}
      </React.Fragment>
    ));
    if (tiers.length > MAX_TIERS_TO_DISPLAY && !isSmallScreenSize) {
      return (
        <div className={css.sliderContainer}>
          <Button
            className={cn(css.slideButton, css.slideLeftButton)}
            intent="secondary"
            icon={<Icon icon="arrow_right" />}
            onClick={() => {
              swiperRef.current?.slidePrev();
              setBoundaries();
            }}
            disabled={isBeginning}
            data-testid="workspace_plans__slide_left_button"
          />
          <Button
            className={cn(css.slideButton, css.slideRightButton)}
            intent="secondary"
            icon={<Icon icon="arrow_right" />}
            onClick={() => {
              swiperRef.current?.slideNext();
              setBoundaries();
            }}
            disabled={isEnd}
            data-testid="workspace_plans__slide_right_button"
          />
          <SwiperSliderWrapper
            className={css.swiperSliderContainer}
            slidesPerView={4}
            allowTouchMove={isSmallScreenSize}
            spaceBetween={12}
            onSwiper={onRefChange}
            data-testid="workspace_plans__premium_tiers_slider"
          >
            {Tiers.map((v, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={index} style={{ width: 240 }}>
                {v}
              </SwiperSlide>
            ))}
          </SwiperSliderWrapper>
        </div>
      );
    }

    return Tiers;
  };

  return (
    <Flex
      className={cn(css.tiersContainer, {
        [css.narrowTiersContainer]: isSmallScreenSize,
      })}
    >
      <TierPriceSizeProvider maxPrice={Math.max(...tiers.map((v) => +v.price))}>
        {getTiersList()}
      </TierPriceSizeProvider>
    </Flex>
  );
};
