import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { formatPrice } from '@utils/Format';

interface BillingPeriodBadgeProps {
  isYourPlan: boolean;
  isMonthly: boolean;
  save: number;
  currency: string;
  extra: number;
  isNextPlan: boolean;
  nextPrepaymentDate: string;
  isAdditionalDiscount: boolean;
  isAvailableFreeMonth?: boolean;
  discount?: string | null;
}

export const BillingPeriodBadge: React.FC<BillingPeriodBadgeProps> = ({
  isYourPlan,
  isNextPlan,
  nextPrepaymentDate,
  isMonthly,
  currency,
  save,
  extra,
  isAdditionalDiscount,
  isAvailableFreeMonth,
  discount,
}) => {
  const { t } = useSafeTranslation();
  const dataTestId = 'billing-period-dialog__list-item__period-badge';

  if (isAvailableFreeMonth)
    return (
      <Type
        size="15px"
        weight="semibold"
        color="violet"
        data-testid={dataTestId}
      >
        {discount
          ? t('pages.Billing.periodDialog.firstMonthDiscount', { discount })
          : t('pages.Billing.periodDialog.firstMonthForFree')}
      </Type>
    );

  if (isNextPlan && nextPrepaymentDate) {
    return (
      <Flex alignItems="center" data-testid={dataTestId}>
        <Type weight="medium" size="15px">
          {`${t('pages.Billing.periodDialog.starts')} ${nextPrepaymentDate}`}
        </Type>
        <Icon icon="check" size="24px" />
      </Flex>
    );
  }
  if (isYourPlan)
    return (
      <Flex alignItems="center" data-testid={dataTestId}>
        <Type weight="medium" size="15px">
          {t('pages.Billing.periodDialog.yourPlan')}
        </Type>
        <Icon icon="check" size="24px" />
      </Flex>
    );
  if (extra > 0 && !isAdditionalDiscount)
    return (
      <Type size="15px" weight="medium" color="red" data-testid={dataTestId}>
        {t('pages.Billing.periodDialog.extraYear', {
          value: formatPrice(extra, { currency, decimals: 2 }),
        })}
      </Type>
    );
  if (!isMonthly && save > 0 && !isAdditionalDiscount)
    return (
      <Type
        size="15px"
        weight="semibold"
        color="violet"
        data-testid={dataTestId}
      >
        {t('pages.Billing.periodDialog.hotSave', {
          save: formatPrice(save, { currency, decimals: 2 }),
        })}
      </Type>
    );
  return null;
};
