import React from 'react';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import * as css from './TierCard.css';

export const TierCardLoader: React.FC<{}> = () => {
  return (
    <LoadingPlaceholder
      className={css.root}
      color="white"
      width={240}
      height={414}
      style={{ borderRadius: 12 }}
    />
  );
};
